import dayjs from 'dayjs';
import { useState, FC, useEffect } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Button,
  InputDatePicker,
  Panel,
  Select,
  Input,
  Notification,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks/redux';
import { useRawDataSetups } from 'pages/RawData/hooks';
import {
  CreateCpTransactionPayload,
  TransactionOperationType,
  postCpTransaction,
} from 'services/recon';
import {
  modeNames,
  newCounterPartyNames,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils/getSelectOptions';

export interface Props {
  reloadData: VoidFunction;
  operationTypes: TransactionOperationType[];
}

export const ManualAddTransaction: FC<Props> = ({
  reloadData,
  operationTypes,
}) => {
  const { register, handleSubmit, watch, getValues } =
    useForm<CreateCpTransactionPayload>();
  const cpNames = useAppSelector(newCounterPartyNames);
  const leNames = useAppSelector(newLegalEntityNames);
  const modeNameList = useAppSelector(modeNames);
  const ccyList = useAppSelector((state) => state.currencies);
  const operationTypesOptions = getSelectOptions(
    operationTypes,
    'name',
    'name',
  );
  const ccyOptions = getSelectOptions(ccyList);
  const cpOptions = getSelectOptions(cpNames);
  const leOptions = getSelectOptions(leNames);
  const modeOptions = getSelectOptions(modeNameList);

  const [dateField, setDateField] = useState<Date | null>(null);
  const handleChangeDate = (date: Date | null) => {
    if (date) {
      setDateField(date);
    }
  };

  const [valueDateField, setValueDateField] = useState<Date | null>(null);
  const handleChangeValueDate = (date: Date | null) => {
    if (date) {
      setValueDateField(date);
    }
  };

  const {
    getSetupIds,
    postReportRowData,
    getSetupError,
    foundSetup,
    linkToRawData,
  } = useRawDataSetups();

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'counterparty' || name === 'legal_entity') {
        const cp = getValues('counterparty');
        const le = getValues('legal_entity');
        if (cp && le) {
          getSetupIds({ le, cp, module: 'manual_transactions' });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit: SubmitHandler<CreateCpTransactionPayload> = async (data) => {
    const cpTransactionPayload = {
      counterparty: data.counterparty,
      legal_entity: data.legal_entity,
      mode: data.mode,
      operation_type: data.operation_type,
      report_date: dateField ? dayjs(dateField).format(DATE_FORMAT) : '',
      value_date: valueDateField
        ? dayjs(valueDateField).format(DATE_FORMAT)
        : '',
      asset: data.asset,
      sum: data.sum,
      symbol_type: data.symbol_type,
      symbol: data.symbol,
      comment: '',
      source_file: '',
    };
    try {
      const rawMetaDataId = await postReportRowData({
        data: cpTransactionPayload,
        le: data.legal_entity,
        module: 'manual_transactions',
        reportDate: dateField ? dayjs(dateField).format(DATE_FORMAT) : '',
        cp: data.counterparty,
      });
      if (rawMetaDataId) {
        await postCpTransaction({
          ...cpTransactionPayload,
          raw_metadata_id: rawMetaDataId,
        });
        Notification.success({ title: 'Transaction was added' });
        reloadData();
      }
    } catch (e) {
      Notification.error({
        title: 'Add transaction error',
      });
    }
  };

  const saveActions = (
    <div className="d-flex align-items-center">
      <Button
        disabled={!foundSetup}
        className="mr-2"
        onClick={handleSubmit(onSubmit)}
      >
        Create
      </Button>
    </div>
  );

  const rawDataSetupMessage =
    !getValues('counterparty') || !getValues('legal_entity')
      ? 'Need to choose "Cp" or "Legal Entity"'
      : getSetupError;

  return (
    <Panel title="Add transaction" action={saveActions}>
      <div className="mt-4 text-left">
        <p>
          Raw data setup:{' '}
          {!foundSetup?.id ? (
            <b>{rawDataSetupMessage}</b>
          ) : (
            <Link target="_blank" to={linkToRawData}>
              {foundSetup?.name}
            </Link>
          )}
        </p>
        <Select
          label="Cp"
          className="mb-3 w-100"
          options={cpOptions}
          {...register('counterparty')}
        />
        <Select
          label="Legal Entity"
          className="mb-3 w-100"
          options={leOptions}
          {...register('legal_entity')}
        />
        <Select
          label="Mode"
          className="mb-3 w-100"
          options={modeOptions}
          {...register('mode')}
        />
        <Select
          label="Operation Type"
          className="mb-3 w-100"
          options={operationTypesOptions}
          {...register('operation_type')}
        />
        <Select
          label="Ccy"
          className="mb-3 w-100"
          options={ccyOptions}
          {...register('asset')}
        />

        <InputDatePicker
          locale={defaultLocale}
          onChange={handleChangeDate}
          inputProps={{
            label: 'Date',
            fullWidth: true,
          }}
          selected={dateField}
        />
        <div className="mb-3" />
        <InputDatePicker
          locale={defaultLocale}
          onChange={handleChangeValueDate}
          inputProps={{
            label: 'Value date',
            fullWidth: true,
          }}
          selected={valueDateField}
        />
        <div className="mb-3" />
        <Input label="Amount" className="mb-3" fullWidth {...register('sum')} />
        <Input
          label="Symbol Type"
          className="mb-3"
          fullWidth
          {...register('symbol_type')}
        />
        <Input
          label="Symbol ID"
          className="mb-3"
          fullWidth
          {...register('symbol')}
        />
      </div>
    </Panel>
  );
};
