import { Link } from 'react-router-dom';

export const Navbar = () => (
  <nav className="navbar navbar-expand-sm bg-dark navbar-dark bg-nva-black">
    <ul className="navbar-nav">
      <li className="nav-item">
        <Link to="/" className="nav-link">
          Scripts status
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/run-info" className="nav-link">
          Run info
        </Link>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Monitors
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/cp-monitor" className="dropdown-item">
            CP Monitor
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Run script
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/run/trade-recon" className="dropdown-item">
            Trade Recon
          </Link>
          <Link to="/run/load-trades" className="dropdown-item">
            Load Trades
          </Link>
          <Link to="/run/views" className="dropdown-item">
            SQL Scripts
          </Link>
          <Link to="/run/misc" className="dropdown-item">
            Misc
          </Link>
          <Link to="/run/reporting" className="dropdown-item">
            Reporting
          </Link>
          <Link to="/order-status" className="dropdown-item">
            Order Status
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Cash recon
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/transactions-reconcile-union" className="dropdown-item">
            Transactions recon
          </Link>
          <Link to="/transactions-reconciled" className="dropdown-item">
            Reconciled transactions
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Trade reconciliation
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/edit/tables" className="dropdown-item">
            Edit table (ctrades)
          </Link>
          <Link to="/edit/cptrades" className="dropdown-item">
            Edit table (cptrades)
          </Link>
          <Link to="/edit/mapping" className="dropdown-item">
            Simple Mapping
          </Link>
          <Link to="/mapping/find-symbol-mapping" className="dropdown-item">
            Find symbol mapping
          </Link>
          <Link to="/trades-reconciled" className="dropdown-item">
            Manual Unreconcile
          </Link>
          <Link to="/manual-reconcile-union" className="dropdown-item">
            Manual Reconcile
          </Link>
          <Link to="/trades-reconciled" className="dropdown-item">
            Reconciled Trades
          </Link>
          <Link to="/auto-reconcile" className="dropdown-item">
            Auto Reconcile
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Positions
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/edit/positionrec" className="dropdown-item">
            Counterparty positions
          </Link>
          <Link to="/position-mapping" className="dropdown-item">
            Position Mapping
          </Link>
          <Link to="/position-recon" className="dropdown-item">
            Reconciliation
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Manual Add
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/row-balance/add" className="dropdown-item">
            Counterparty balances
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Settings
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/symbol-cot" className="dropdown-item">
            COT by Symbol
          </Link>
        </div>
      </li>
      <li className="nav-item dropdown">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */}
        <a
          className="nav-link dropdown-toggle"
          href="#"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Mapping
        </a>
        <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
          <Link to="/mapping/value-mapping-rules" className="dropdown-item">
            Value Mapping Rules
          </Link>
          <Link to="/mapping/new-report" className="dropdown-item">
            New Report
          </Link>
          <Link to="/mapping/reports" className="dropdown-item">
            Reports
          </Link>
        </div>
      </li>
      <li className="nav-item">
        <Link to="/raw-data" className="nav-link">
          Raw data
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/cp-data-monitor" className="nav-link">
          Cp Data Monitor
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/setup-etl" className="nav-link">
          Setup ETL
        </Link>
      </li>
      <li className="nav-item">
        <Link to="/break-dashboard" className="nav-link">
          Break Dashboard
        </Link>
      </li>
      <li>
        <p className="nav-link">
          Recon version:
          <b> {process.env.REACT_APP_VERSION}</b>
        </p>
      </li>
    </ul>
  </nav>
);
