import { CellValue } from 'react-table';

import { BulkActionsProps } from '../types';

export const prepareSelectedRow = (
  bulkActions: BulkActionsProps,
  rows: any[],
) => {
  const selectedRows = bulkActions?.selectedRows;

  if (bulkActions && rows) {
    return rows.reduce((acc: CellValue, curr) => {
      const isChecked = selectedRows.some((item) => {
        return item?.values?.id === curr?.values?.id;
      });

      return {
        ...acc,
        [curr.id]: bulkActions.isSelectAll ? !isChecked : isChecked,
      };
    }, {});
  }

  return {};
};
