import dayjs from 'dayjs';
import React, {
  FC,
  useMemo,
  useState,
  useCallback,
  useEffect,
  SyntheticEvent,
} from 'react';
import {
  Table,
  IOnFetchArguments,
  useTableData,
  ISortBy,
  ICellValue,
  IconButton,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { FILTERS_DATE_FORMAT } from 'constants/date';
import { defaultOrdering, defaultPageSize, pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import {
  fetchPositions,
  getUrlForFetchPositions,
  PositionItem,
} from 'services/recon';
import { ReconApiService } from 'services/recon/api';
import { calculateCountOfPages, getWorkYesterday } from 'utils';

import { usePositionRecon } from './hooks';
import { PositionReconTable } from './types';
import { constantColumnsList, getColumns } from './utils/getColumns';
import { getRowProps } from './utils/getTableRow';
import { Head } from './сomponents';

const modeNames = { client: 'Client-CP', nostro: 'Nostro-CP' };
export const PositionRecon: FC = () => {
  const legalEntities = useAppSelector((state) => state.new_legal_entity_list);
  const modeList = useAppSelector((state) => state.modeList);

  const [clientModeId, nostroModeId] = [
    modeList?.find((mode) => mode.name === modeNames.client)?.id ?? 0,
    modeList?.find((mode) => mode.name === modeNames.nostro)?.id ?? 0,
  ];
  const [modeId, setModeId] = useState<number>(clientModeId);
  const [startDate, setStartDate] = useState<Date | null>(
    new Date(getWorkYesterday()),
  );
  const [endDate, setEndDate] = useState<Date | null>(null);
  const isNostro = useMemo(() => modeId === nostroModeId, [modeId]);
  const { formattedStartDate, formattedEndDate } = useMemo(
    () => ({
      formattedStartDate: dayjs(startDate).format(FILTERS_DATE_FORMAT),
      formattedEndDate: dayjs(endDate).isValid()
        ? dayjs(endDate).format(FILTERS_DATE_FORMAT)
        : null,
    }),
    [startDate, endDate],
  );
  const [leId, setLeId] = useState(0);
  const [cpId, setCpId] = useState<number>();
  const [isShowAllPositions, setIsShowAllPositions] = useState(false);
  const [isShowAllBreaks, setIsShowAllBreaks] = useState(false);

  const leName = useMemo(
    () => legalEntities.find((item) => item.id === leId)?.name,
    [leId, legalEntities],
  );

  const getPositions = useCallback(
    (props: IOnFetchArguments) =>
      fetchPositions({
        reportDate: [formattedStartDate, formattedEndDate],
        leId,
        modeId,
        cpId,
        isShowAllPositions,
        isShowAllBreaks,
        ...props,
      }),
    [
      formattedStartDate,
      formattedEndDate,
      isShowAllPositions,
      isShowAllBreaks,
      modeId,
      cpId,
      leId,
    ],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getPositions },
      pagination: {
        getDefaultPagination: () => ({ limit: defaultPageSize, skip: 0 }),
      },
    }),
    [getPositions],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    params,
    sorting,
    isLoading,
    setSorting,
    resetFilters,
    setFilter,
    removeFilter,
    fetchData,
    filters,
  } = useTableData<PositionReconTable>(tableData);

  const {
    handleChangeCp,
    breakComment,
    cp,
    breakCategory,
    handleChangeComment,
    handleChangeBreakCategory,
    selectedItems,
    onCleanSelectedItems,
    handleCheckItem,
    handleCheckAllOnPage,
    handleUnCheckAllOnPage,
    handleConfirmBreaks,
    confirmIsAvailable,
    handleMatchBreaks,
    matchIsAvailable,
    handleUpdateBreaks,
    updateIsAvailable,
    handleCleanFields,
    cpOptions,
    leOptions,
    breakOptions,
    handleChangeLeId,
  } = usePositionRecon({
    items: data?.positions || [],
    fetchData,
    formattedDates: [formattedStartDate, formattedEndDate],
    leId,
    setLeId,
    modeId,
    isNostro,
  });

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters, setFilter, removeFilter],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const isSymbolFilterExist = useMemo(() => !!filters?.symbol_id, [filters]);
  useEffect(() => {
    if (!filters?.symbol_id) {
      setEndDate(null);
    }
  }, [filters?.symbol_id]);

  const fetchExcelPayload = useMemo(
    () => ({
      reportDate: [formattedStartDate, formattedEndDate],
      leId,
      cpId,
      modeId,
      isShowAllPositions,
      isShowAllBreaks,
      params,
      filtersParams: { ...filters, generate_report: true },
      sortingParams: sorting,
      paginationParams: {
        page: params.page,
        skip: params.skip,
        limit: params.limit,
      },
    }),
    [
      formattedStartDate,
      formattedEndDate,
      isShowAllPositions,
      isShowAllBreaks,
      params,
      filters,
      sorting,
      modeId,
      leId,
      cpId,
    ],
  );

  const fetchExcelFile = useCallback(async () => {
    const url = getUrlForFetchPositions(fetchExcelPayload);
    const response = await fetch(ReconApiService._apiBase + url, {
      headers: ReconApiService.getBaseHeaders(),
    });
    const result = await response.blob();
    const aElement = document.createElement('a');
    aElement.setAttribute(
      'download',
      `${leName}_position_report_${formattedStartDate}_${formattedEndDate}`,
    );
    const href = URL.createObjectURL(result);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
    document.body.removeChild(aElement);
  }, [fetchExcelPayload]);

  const handleChangeCpId = (_: SyntheticEvent, newValue: any) => {
    setCpId(newValue?.value ? Number(newValue?.value) : undefined);
  };

  useEffect(() => {
    handleCleanFields();
    onCleanSelectedItems();
    setPage(0);
    setCpId(undefined);
  }, [leId, modeId]);

  useEffect(() => {
    onCleanSelectedItems();
  }, [formattedStartDate]);

  const columns = useMemo(
    () =>
      getColumns({
        columnKeys: data?.positions[0]
          ? Object.keys(data?.positions[0])
          : constantColumnsList,
        breakOptions,
        onFilter: setFilter,
        onRemove: removeFilter,
        items: data?.positions ?? [],
        selectedItems,
        handleCheckItem,
        handleCheckAllOnPage,
        handleUnCheckAllOnPage,
        hasAbsDiff: filters.abs_diff !== undefined,
        hasDiff: filters.diff !== undefined,
        isNostro,
      }),
    [
      filters,
      data,
      setFilter,
      removeFilter,
      breakOptions,
      selectedItems,
      handleCheckItem,
      handleCheckAllOnPage,
      handleUnCheckAllOnPage,
      isNostro,
    ],
  );

  const tableId = useMemo(
    () => `PositionReconTable${columns.map((item) => item.accessor).join('')}`,
    [columns],
  );

  const modifiedGetRow = useCallback(getRowProps(selectedItems), [
    selectedItems,
  ]);

  return (
    <div className="container-fluid text-left">
      <Table
        title="Position Reconciliation"
        customHeadComponent={
          <Head
            leId={leId}
            onChangeLeId={handleChangeLeId}
            onChangeCpId={handleChangeCpId}
            leOptions={leOptions}
            dateFields={[startDate, endDate]}
            onChangeStartDate={setStartDate}
            onChangeEndDate={setEndDate}
            matchIsAvailable={matchIsAvailable}
            onUpdateBreaks={handleUpdateBreaks}
            breakCategory={breakCategory}
            breakCategoryOptions={breakOptions}
            breakComment={breakComment}
            confirmIsAvailable={confirmIsAvailable}
            isShowAllBreaks={isShowAllBreaks}
            isShowAllPositions={isShowAllPositions}
            cp={cp}
            onChangeCp={handleChangeCp}
            cpOptions={cpOptions}
            onChangeBreakCategory={handleChangeBreakCategory}
            onChangeComment={handleChangeComment}
            onConfirmBreaks={handleConfirmBreaks}
            onMatchBreaks={handleMatchBreaks}
            setIsShowAllBreaks={setIsShowAllBreaks}
            setIsShowAllPositions={setIsShowAllPositions}
            updateIsAvailable={updateIsAvailable}
            modeId={modeId}
            setModeId={setModeId}
            modeIds={{ client: clientModeId, nostro: nostroModeId }}
            isNostro={isNostro}
            isSymbolFilterExist={isSymbolFilterExist}
          />
        }
        tableId={tableId}
        data={data?.positions || []}
        columns={columns}
        isLoading={isLoading}
        additionalActions={[
          {
            title: 'Load Excel',
            component: (
              <IconButton
                iconColor="action"
                iconName="ExportIcon"
                label="Download Excel"
                onClick={fetchExcelFile}
              />
            ),
          },
        ]}
        saveColumnOrder
        hasFilters
        showScrollbar
        filteringProps={filteringProps}
        manualSortBy
        defaultSortBy={defaultOrdering}
        onSort={handleSorting}
        locale={defaultLocale}
        showTableInfo
        isFlexLayout
        getRowProps={modifiedGetRow}
        getCellProps={(cell: ICellValue<PositionItem>) =>
          modifiedGetRow(cell.row)
        }
        hasPagination
        pageSizes={pageSizes}
        serverPaginationProps={{
          pageIndex: page,
          pageCount,
          pageSize: limit,
          total: data?.pagination.total || 0,
          setPage,
          setPageSize: setLimit,
        }}
      />
    </div>
  );
};
