import dayjs from 'dayjs';
import { ICellValue, IColumn, Tooltip } from 'react-ui-kit-exante';

import { DATE_TIME_FORMAT } from 'constants/date';
// TODO: Replace to RUI icon
import { ReactComponent as TreePalmIcon } from 'icons/TreePalm.svg';
import {
  CpDataMonitorItemStatuses,
  Process,
  StatsRecord,
  SubRow,
  Types,
} from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';

import { typeLabels } from '../constants';

import { getIconByStatus } from './getIconByStatus';

const indicateShowingCondition = (
  cell: ICellValue<SubRow>,
  process: Process,
) => {
  const { isExpanded, depth } = cell.row as unknown as Record<string, number>;
  const date = cell.row.original[`${process}_updated_at`];
  return (
    <div className="w-100 d-flex align-items-center" style={{ minHeight: 22 }}>
      {isExpanded && depth <= 1
        ? null
        : getIconByStatus(
            cell.row.original[process] as CpDataMonitorItemStatuses,
          )}
      {!!date && (
        <Tooltip title={dayjs(date).format(DATE_TIME_FORMAT)}>
          <span className="blur ml-1">{dayjs(date).format('HH:mm')}</span>
        </Tooltip>
      )}
    </div>
  );
};

const getNameCell = (cell: ICellValue<SubRow & { isHoliday?: boolean }>) => {
  if ((cell.row as unknown as Record<string, number>).depth === 2) {
    return (
      <div>
        <p className="m-0">{typeLabels[cell.row.original.subName as Types]}</p>
        <p className="m-0 blur">{cell.row.original.name}</p>
      </div>
    );
  }

  if (
    [0, 1].includes((cell.row as unknown as Record<string, number>).depth) &&
    cell.row.original.isHoliday
  ) {
    return (
      <div className="d-flex align-items-center">
        <p className="m-0 mr-1">{cell.row.original.name}</p>
        <TreePalmIcon />
      </div>
    );
  }

  return cell.row.original.name;
};
export const getColumns = (
  isHideNormalizedColumn: boolean,
): IColumn<StatsRecord>[] => {
  const columns = [
    {
      Header: '',
      accessor: 'name',
      minWidth: 120,
      Cell: getNameCell,
      disableFilters: true,
      disableSortBy: true,
      className: 'pr-2 d-block text-left',
    },
    {
      Header: 'Ftp',
      accessor: Process.Ftp,
      minWidth: 40,
      tooltip: 'off',
      Cell: (cell: ICellValue<StatsRecord>) =>
        indicateShowingCondition(cell, Process.Ftp),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Raw',
      accessor: Process.Raw,
      minWidth: 40,
      tooltip: 'off',
      Cell: (cell: ICellValue<StatsRecord>) =>
        indicateShowingCondition(cell, Process.Raw),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Normalized',
      accessor: Process.Normalized,
      minWidth: 40,
      tooltip: 'off',
      Cell: (cell: ICellValue<StatsRecord>) =>
        indicateShowingCondition(cell, Process.Normalized),
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: '',
      accessor: 'flex',
      minWidth: 300,
      Cell: () => '',
      disableFilters: true,
      disableSortBy: true,
    },
  ];

  return isHideNormalizedColumn
    ? columns.filter((item) => item.accessor !== Process.Normalized)
    : columns;
};

export const getAdditionalFilters = ({
  onFilter,
  onRemove,
  leTypeOptions,
}: ColumnsWithFilters & { leTypeOptions: SelectOption[] }) => {
  const columns: IColumn<Record<string, unknown>>[] = [
    {
      Header: 'Legal entity type',
      accessor: 'legal_entity_type',
      type: 'multiSelect',
      onFilter,
      onRemove,
      filterOptions: leTypeOptions,
    },
  ];
  return columns;
};
