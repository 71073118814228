import dayjs, { Dayjs } from 'dayjs';
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Notification,
  Tab,
  Table,
  Tabs,
  Panel,
  Button,
  OnSaveEditableRow,
  ArrowRightSmallIcon,
  ArrowLeftSmallIcon,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { FILTERS_DATE_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks/redux';
import {
  BreakTypes,
  RightUser,
  addRightUser,
  getRightUsers,
} from 'services/recon';
import {
  currentUserSelector,
  userNamesSelector,
} from 'store/reducers/commonReducer';
import { getMondayOfCurrentWeek } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { AddForm } from './components/AddForm';
import { GetPayloadForUpdateUsers, ScheduleItem } from './types';
import { getColumns } from './utils/getColumns';
import { getDiffBetweenObjects } from './utils/getDiffBetweenObjects';

const typeTabsValues = [
  BreakTypes.TRADE,
  BreakTypes.TRANSACTION,
  BreakTypes.POSITION,
  BreakTypes.BALANCE,
];
const NO_SET = '-';
export const AssignmentSchedule: FC = () => {
  const [addIsOpened, setAddIsOpened] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const currentUser = useAppSelector(currentUserSelector);
  const entities = useAppSelector((state) => state.new_legal_entity_list);
  const userNames = useAppSelector(userNamesSelector);
  const userOptions = getSelectOptions(userNames);

  const [isLoading, setIsLoading] = useState(false);
  const [users, setUsers] = useState<RightUser[]>([]);
  const [currentMonday, setCurrentMonday] = useState<Dayjs>(
    dayjs(getMondayOfCurrentWeek()),
  );
  const editIsAvailable = useMemo(
    () => currentUser?.rank === 'senior',
    [currentUser],
  );
  const datesObject = useMemo((): Omit<ScheduleItem, 'le'> => {
    const secondDay = currentMonday.add(1, 'day');
    const thirdDay = secondDay.add(1, 'day');
    const fourthDay = thirdDay.add(1, 'day');
    const fifthDay = fourthDay.add(1, 'day');
    const sixthDay = fifthDay.add(1, 'day');
    const seventhDay = sixthDay.add(1, 'day');
    return {
      firstDay: currentMonday.format(FILTERS_DATE_FORMAT),
      secondDay: secondDay.format(FILTERS_DATE_FORMAT),
      thirdDay: thirdDay.format(FILTERS_DATE_FORMAT),
      fourthDay: fourthDay.format(FILTERS_DATE_FORMAT),
      fifthDay: fifthDay.format(FILTERS_DATE_FORMAT),
      sixthDay: sixthDay.format(FILTERS_DATE_FORMAT),
      seventhDay: seventhDay.format(FILTERS_DATE_FORMAT),
    };
  }, [currentMonday]);

  const uploadData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getRightUsers({
        start_date: currentMonday.format(FILTERS_DATE_FORMAT),
        end_date: currentMonday.add(6, 'day').format(FILTERS_DATE_FORMAT),
      });
      setUsers(response);
    } catch (e) {
      Notification.error({ title: 'Counterparties load error' });
    } finally {
      setIsLoading(false);
    }
  }, [datesObject]);

  useEffect(() => {
    uploadData();
  }, [datesObject]);

  const columns = useMemo(
    () => getColumns({ dates: datesObject, userOptions }),
    [datesObject, userOptions],
  );

  const typeFilter = useMemo(() => typeTabsValues[selectedTab], [selectedTab]);
  const getHandlerForSearch = (date: string) => (user: RightUser) =>
    dayjs(date).isSame(user.report_date);
  const items = useMemo(
    () =>
      entities.map((item) => {
        const usersByLeAndType = users.filter(
          (user) =>
            user.legal_entity === item.name && user.data_type === typeFilter,
        );
        return {
          le: item.name,
          firstDay:
            usersByLeAndType.find(getHandlerForSearch(datesObject.firstDay))
              ?.username ?? NO_SET,
          secondDay:
            usersByLeAndType.find(getHandlerForSearch(datesObject.secondDay))
              ?.username ?? NO_SET,
          thirdDay:
            usersByLeAndType.find(getHandlerForSearch(datesObject.thirdDay))
              ?.username ?? NO_SET,
          fourthDay:
            usersByLeAndType.find(getHandlerForSearch(datesObject.fourthDay))
              ?.username ?? NO_SET,
          fifthDay:
            usersByLeAndType.find(getHandlerForSearch(datesObject.fifthDay))
              ?.username ?? NO_SET,
          sixthDay:
            usersByLeAndType.find(getHandlerForSearch(datesObject.sixthDay))
              ?.username ?? NO_SET,
          seventhDay:
            usersByLeAndType.find(getHandlerForSearch(datesObject.seventhDay))
              ?.username ?? NO_SET,
        };
      }),
    [entities, users, typeFilter],
  );

  const handleChangeTab = (event: ChangeEvent<unknown>, newValue: number) => {
    setSelectedTab(newValue);
  };
  const handleSetNextWeek = useCallback(() => {
    setCurrentMonday(currentMonday.add(1, 'week'));
  }, [setCurrentMonday, currentMonday]);
  const handleSetPrevWeek = useCallback(() => {
    setCurrentMonday(currentMonday.subtract(1, 'week'));
  }, [currentMonday, setCurrentMonday]);

  const handleAddRightUser = async ({
    le,
    newDate,
    newUser,
  }: GetPayloadForUpdateUsers) => {
    try {
      await addRightUser({
        legal_entity: le,
        role: 'recon',
        start_date: newDate,
        end_date: newDate,
        username: newUser,
        data_type: typeFilter,
      });
      Notification.success({
        title: `${newUser} was assigned on ${le} on ${newDate}`,
      });
    } catch (e) {
      Notification.error({
        title: `set assignee on ${le} on ${newDate} error`,
      });
    }
  };
  const handleEditRow: OnSaveEditableRow<ScheduleItem> = async (
    previousValues,
    updatedValues,
  ) => {
    try {
      const diff = getDiffBetweenObjects(previousValues, updatedValues);
      const values = Object.keys(diff).map((key) => ({
        date: datesObject[key as keyof Omit<ScheduleItem, 'le'>],
        newUser: diff[key],
      }));
      await Promise.all(
        values.map((item) =>
          handleAddRightUser({
            le: updatedValues.le,
            newUser: item.newUser,
            newDate: item.date,
          }),
        ),
      );
      uploadData();
    } catch (e) {
      Notification.error({ title: 'Update schedule error' });
    }
  };

  const panelAction = (
    <div className="d-flex align-items-center justify-content-between">
      <ArrowLeftSmallIcon
        size={24}
        className="pointer"
        onClick={handleSetPrevWeek}
      />
      <p className="m-0">
        {datesObject.firstDay} - {datesObject.seventhDay}
      </p>
      <ArrowRightSmallIcon
        size={24}
        className="pointer"
        onClick={handleSetNextWeek}
      />
      {editIsAvailable && (
        <Button
          className="ml-3"
          size="small"
          onClick={() => setAddIsOpened(true)}
        >
          Set Schedule
        </Button>
      )}
    </div>
  );

  return (
    <>
      <div className="mui-container-fluid">
        <div className="mui-row">
          <div className="mui-col-md-12">
            <Panel
              title="Assignment Schedule"
              className="pb-0"
              action={panelAction}
            >
              <Tabs
                className="mt-3"
                value={selectedTab}
                onChange={handleChangeTab}
              >
                <Tab label="Trades" />
                <Tab label="Transactions" />
                <Tab disabled label="Positions" />
                <Tab disabled label="Balances" />
              </Tabs>
              <Table
                tableId="assignmentScheduleTable"
                data={items}
                isLoading={isLoading}
                columns={columns}
                isFlexLayout
                locale={defaultLocale}
                rowActions={{
                  show: editIsAvailable,
                  onSave: handleEditRow,
                }}
              />
            </Panel>
          </div>
        </div>
      </div>
      <AddForm
        isOpened={addIsOpened}
        setIsOpened={setAddIsOpened}
        reloadData={uploadData}
      />
    </>
  );
};
