import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { ISortBy, Notification, Table } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { FILTERS_DATE_FORMAT } from 'constants/date';
import { DEFAULT_FILTER_VALUES, pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import { Counterparty, getCpByEntity, getCpTypes } from 'services/recon';
import { runByCPAndType, RunByCPAndTypePayload } from 'services/reconRunner';
import { modeNames, newLegalEntityNames } from 'store/reducers/commonReducer';
import { SimpleListObject } from 'types';
import { getDateForRequest, getWorkYesterday } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { ModifyCounterparty } from './types';
import { getColumns } from './utils/getColumns';

const DEFAULT_SORTING = {
  id: 'name',
  desc: true,
};

export function CPMonitorPage() {
  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const modes = useAppSelector(modeNames);
  const counterPartyList = useAppSelector(
    (state) => state.new_counterparty_list,
  );
  const [cpTypes, setCpTypes] = useState<SimpleListObject[]>([]);
  const [cpByEntity, setCpByEntity] = useState<Record<
    string,
    Counterparty[]
  > | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [filters, setFilters] = useState<Record<string, string>>({});
  const [sortValue, setSortValue] = useState<ISortBy>(DEFAULT_SORTING);
  const [runIsDisabled, setRunIsDisabled] = useState(false);

  const entityOptions = useMemo(
    () => getSelectOptions(legalEntityNames),
    [legalEntityNames],
  );
  const modeOptions = useMemo(
    () => getSelectOptions(modes),
    [legalEntityNames],
  );
  const typeOptions = useMemo(
    () => getSelectOptions(cpTypes.map((i) => i.name)),
    [cpTypes],
  );

  const getData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await getCpByEntity();
      setCpByEntity(response);
    } catch (e) {
      Notification.error({ title: 'Counterparties load error' });
    } finally {
      setIsLoading(false);
    }
    try {
      setIsLoading(true);
      const response = await getCpTypes();
      setCpTypes(response.results);
    } catch (e) {
      Notification.error({ title: 'Counterparties types load error' });
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (legalEntityNames.length) {
      setFilters({ ...filters, legal_entity: legalEntityNames[0] });
    }
  }, [legalEntityNames]);

  useEffect(() => {
    getData();
  }, []);

  const handleRunScripts = async (payload: RunByCPAndTypePayload) => {
    setRunIsDisabled(true);
    await runByCPAndType(payload);
    setTimeout(() => {
      setRunIsDisabled(false);
    }, 2000);
  };

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: (col: string, value: string) => {
          setFilters({ ...filters, [col]: value });
        },
        onRemove: (col: string) => {
          if (col !== 'legal_entity') {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            const { [col]: _, ...payload } = filters;
            setFilters(payload);
          }
        },
        entityOptions,
        modeOptions,
        typeOptions,
        onAction: handleRunScripts,
        runIsDisabled,
      }),
    [
      filters,
      setFilters,
      entityOptions,
      modeOptions,
      typeOptions,
      runIsDisabled,
    ],
  );

  const items = useMemo(() => {
    if (!cpByEntity) {
      return [];
    }
    const buff = Object.keys(cpByEntity)
      .reduce((acc: ModifyCounterparty[], entity) => {
        const cps: ModifyCounterparty[] = (
          cpByEntity[entity]
            .map((cpName) =>
              counterPartyList.find((cp) => cp.name === cpName.name),
            )
            .filter((i) => i) as Counterparty[]
        ).map((cp) => ({
          ...cp,
          legal_entity: entity,
          mode: filters.mode ?? DEFAULT_FILTER_VALUES.mode,
          report_date:
            getDateForRequest(filters.report_date, FILTERS_DATE_FORMAT) ??
            getWorkYesterday(FILTERS_DATE_FORMAT),
        }));
        return [...acc, ...cps];
      }, [])
      .filter((item) => {
        const filterKeys = Object.keys(filters);
        if (!filterKeys.length) {
          return true;
        }
        return filterKeys.reduce((acc: boolean, key) => {
          if (!acc) {
            return acc;
          }
          if (dayjs(filters[key]).isValid()) {
            return dayjs(filters[key]).isSame(dayjs(filters[key]));
          }
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          return item[key] === filters[key];
        }, true);
      });

    if (sortValue.id) {
      buff.sort((a, b) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const [first, second] = [a[sortValue.id], b[sortValue.id]];
        const [upperFirst, upperSecond] = [
          first?.toUpperCase(),
          second?.toUpperCase(),
        ];
        if (upperFirst < upperSecond) {
          return sortValue.desc ? -1 : 1;
        }
        if (upperFirst > upperSecond) {
          return sortValue.desc ? 1 : -1;
        }
        return 0;
      });
    }

    return buff;
  }, [cpByEntity, counterPartyList, filters, sortValue]);

  const filteringProps = useMemo(
    () => ({
      filters,
      manualFilters: true,
      removeAllFilters: () => setFilters({}),
    }),
    [filters, setFilters],
  );

  const handleSorting = useCallback(
    ([firstSort]: ISortBy[]) => {
      setSortValue(firstSort ?? {});
    },
    [setSortValue],
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Table
            tableId="cpMonitorTable"
            title="CP monitor"
            data={items}
            pageSizes={pageSizes}
            pageSize={10}
            isLoading={isLoading}
            filteringProps={filteringProps}
            hasFilters
            locale={defaultLocale}
            manualSortBy
            onSort={handleSorting}
            defaultSortBy={[DEFAULT_SORTING]}
            columns={columns}
            showTableInfo
            isFlexLayout
            hasPagination
          />
        </div>
      </div>
    </div>
  );
}
