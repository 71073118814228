import React from 'react';

interface GetFilterParams {
  filterKeys: string[];
  applyObject: Record<string, any>;
  isApply: React.MutableRefObject<boolean>;
  shouldApply?: boolean;
}

export const getDefaultFilterParams =
  ({ filterKeys, applyObject, isApply, shouldApply = true }: GetFilterParams) =>
  (params: Record<string, unknown>): Record<string, unknown> => {
    const tempParams = params;
    if (!isApply.current && shouldApply) {
      Object.keys(applyObject).forEach((key) => {
        if (!tempParams[key]) {
          tempParams[key] = applyObject[key];
        }
      });
      // eslint-disable-next-line no-param-reassign
      isApply.current = true;
    }
    return Object.keys(tempParams).reduce(
      (acc, param) =>
        filterKeys.includes(param)
          ? { ...acc, [param]: tempParams[param] }
          : acc,
      {},
    );
  };
