import cn from 'classnames';
import dayjs from 'dayjs';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import {
  IconButton,
  IOnFetchArguments,
  ISortBy,
  Notification,
  OnSaveEditableRow,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { DEFAULT_FILTER_VALUES, pageSizes } from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import {
  RowBalance as RowBalanceType,
  getRowBalanceList,
  patchRowBalance,
} from 'services/recon';
import { cpListByEntitySelector } from 'store/reducers/commonReducer';
import { calculateCountOfPages } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { ManualAddBalance } from './ManualAddBalance/ManualAddBalance';
import { manualSourceFile, types } from './consts';
import { getColumns } from './utils/getColumns';

export const RowBalance: FC = () => {
  const [addIsVisible, setAddIsVisible] = useState(false);
  const entities = useAppSelector((state) => state.new_legal_entity_list);

  const entityOptions = getSelectOptions(entities, 'name', 'id');
  const typeOptions = getSelectOptions(types);

  const getMappingSymbols = useCallback(
    (payload: IOnFetchArguments) => getRowBalanceList(payload),
    [],
  );
  const tableData = useMemo(
    () => ({
      data: { onFetch: getMappingSymbols },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
      filters: {
        getDefaultFilters: () => ({
          legal_entity_id: entities[0].id?.toString(),
        }),
      },
    }),
    [getMappingSymbols],
  );
  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setSorting,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    fetchData,
  } = useTableData<{ items: RowBalanceType[]; pagination: { total?: number } }>(
    tableData,
  );
  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );
  const filterLeName = useMemo(
    () =>
      entities?.find((item) => String(item?.id) === filters.legal_entity_id)
        ?.name,
    [entities, filters],
  );
  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filterLeName as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity, 'name', 'id') ?? [],
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty_id) {
      setFilter('counterparty_id', cpOptions[0].value);
    }
  }, [cpOptions]);
  useEffect(() => {
    if (entities.length && !filters.legal_entity_id) {
      setFilter('legal_entity_id', entities[0].id?.toString());
    }
  }, [entities]);

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        typeOptions,
        cpOptions,
        entityOptions,
      }),
    [setFilter, removeFilter, typeOptions, cpOptions, entityOptions],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );
  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const handleChangeIsVisible = () => {
    setAddIsVisible(!addIsVisible);
  };
  const reloadData = () => {
    setAddIsVisible(false);
    fetchData();
  };

  const handleSaveEditableRow: OnSaveEditableRow<RowBalanceType> = async (
    previousValues,
    updatedValues,
  ) => {
    const { report_date: reportDate, ...payload } = updatedValues;
    try {
      await patchRowBalance(previousValues.full_id, {
        ...payload,
        report_date: dayjs(reportDate).format(DATE_FORMAT),
      });
      fetchData();
      Notification.success({
        title: 'Row balance was updated',
      });
    } catch (e) {
      Notification.error({ title: `Update row balance error: ${e}` });
    }
  };
  return (
    <div className="mui-container-fluid">
      <div className="mui-row">
        <div
          className={cn({
            'mui-col-md-12': !addIsVisible,
            'mui-col-md-8': addIsVisible,
          })}
        >
          <Table
            tableId="RowBalanceTable"
            title="Counterparty balances"
            showTableInfo
            hasPagination
            hasFilters
            locale={defaultLocale}
            manualSortBy
            isFlexLayout
            showScrollbar
            additionalActions={[
              {
                title: 'Add',
                component: (
                  <IconButton
                    iconColor="action"
                    iconName="AddIcon"
                    label="Add balance"
                    onClick={handleChangeIsVisible}
                  />
                ),
              },
            ]}
            data={data?.items ?? []}
            columns={columns}
            isLoading={isLoading}
            pageSizes={pageSizes}
            filteringProps={filteringProps}
            onSort={handleSorting}
            defaultSortBy={[]}
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination?.total || 0,
              setPage,
              setPageSize: setLimit,
            }}
            rowActions={{
              show: true,
              isEditedRow: (row) => row.source_file === manualSourceFile,
              onSave: handleSaveEditableRow,
            }}
          />
        </div>
        <div
          className={cn({
            'd-none': !addIsVisible,
            'mui-col-md-4': addIsVisible,
          })}
        >
          {addIsVisible && (
            <ManualAddBalance
              reloadData={reloadData}
              onClose={() => setAddIsVisible(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};
