import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  IOnFetchArguments,
  ISortBy,
  Table,
  useTableData,
  IRow,
  ICellValue,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import { CpAndCTrade, getCpAndCTrades } from 'services/recon';
import { getCounterPartyNames } from 'store/reducers/commonReducer';
import { calculateCountOfPages } from 'utils';

import { getTradesTableColumns } from '../utils';

interface Props {
  filters: IOnFetchArguments['filtersParams'];
  reloadUnreconciledTable: VoidFunction;
  reconciledUpdateCount: number;
  getRowProps: (val: IRow<CpAndCTrade>) => Record<string, unknown>;
  getCellProps: (val: ICellValue<CpAndCTrade>) => Record<string, unknown>;
}
export const ReconciledTable: FC<Props> = ({
  filters,
  reloadUnreconciledTable,
  reconciledUpdateCount,
  getRowProps,
  getCellProps,
}) => {
  const counterPartyNames = useAppSelector(getCounterPartyNames);
  const getReconciledTrades = useCallback(
    (props: IOnFetchArguments) =>
      getCpAndCTrades(
        {
          ...props,
          filtersParams: { ...filters, type_of_recon: 'MANUAL' },
        },
        true,
      ),
    [filters],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getReconciledTrades },
      pagination: { getDefaultPagination: () => ({ limit: 5, skip: 0 }) },
      filters: {
        getDefaultFilters: () => filters,
      },
    }),
    [getReconciledTrades],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    fetchData,
  } = useTableData<{
    trades: CpAndCTrade[];
    pagination: { total: number };
  }>(tableData);

  useEffect(() => {
    fetchData();
  }, [reconciledUpdateCount]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const reloadData = async () => {
    await fetchData();
    await reloadUnreconciledTable();
  };

  const tradesTableColumns = useMemo(
    () =>
      getTradesTableColumns(
        {
          trades: data?.trades,
          cpList: counterPartyNames,
          onFilter: setFilter,
          onRemove: removeFilter,
          reloadData,
        },
        true,
      ),
    [data, setFilter, removeFilter, filters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <Table
      tableId="ReconciledTrades"
      title="Reconciled trades"
      showTableInfo
      hasPagination
      manualSortBy
      isFlexLayout
      showScrollbar
      saveColumnOrder
      saveViewParamsAfterLeave
      data={data?.trades ?? []}
      columns={tradesTableColumns}
      isLoading={isLoadingCpBo}
      pageSizes={pageSizes}
      locale={defaultLocale}
      filteringProps={filteringProps}
      onSort={handleSorting}
      getRowProps={getRowProps}
      getCellProps={getCellProps}
      defaultSortBy={[{ id: 'id', desc: true }]}
      serverPaginationProps={{
        pageIndex: page,
        setPage,
        pageSize: limit,
        setPageSize: setLimit,
        total: data?.pagination?.total ?? 0,
        pageCount,
      }}
    />
  );
};
