import { ICellValue, IColumn, Button } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth } from 'constants/tables';
import {
  FetchSearchMappingsForPositionPayload,
  PositionMappingCandidate,
  PositionReconciliation,
} from 'services/recon';

interface IPayload {
  searchMapping: (
    payload: Omit<FetchSearchMappingsForPositionPayload, 'mty'>,
  ) => void;
}
export const getPositionsColumns = ({
  searchMapping,
}: IPayload): IColumn<PositionReconciliation>[] => [
  {
    Header: 'Report Date',
    accessor: 'report_date',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'CP',
    accessor: 'counterparty',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'CP Symbol',
    accessor: 'cp_symbol',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'ISIN',
    accessor: 'isin',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Price',
    accessor: 'price',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Ccy',
    accessor: 'ccy',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Value',
    accessor: 'value',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Action',
    accessor: 'action',
    minWidth: 100,
    Cell: ({
      row: {
        original: { id },
      },
    }: ICellValue<PositionReconciliation>) => {
      const handleSearchMapping = () => {
        searchMapping({ id });
      };
      return (
        <div className="d-flex">
          <Button size="small" onClick={handleSearchMapping}>
            Find mapping
          </Button>
        </div>
      );
    },
    disableFilters: true,
    disableSortBy: true,
  },
];

export const getCandidatesColumns = (
  onAccept: (value: PositionMappingCandidate) => void,
): IColumn<PositionMappingCandidate>[] => [
  {
    Header: 'Symbol',
    accessor: 'symbol',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Qty diff',
    accessor: 'bo_qty',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Ccy',
    accessor: 'ccy',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Isin',
    accessor: 'isin',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type',
    accessor: 'symbol_type',
    minWidth,
    width: minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Action',
    accessor: 'action',
    minWidth,
    width: 120,
    Cell: (cell: ICellValue<PositionMappingCandidate>) => (
      <Button
        size="small"
        color="primary"
        onClick={() => onAccept(cell.row.original)}
      >
        Accept Mapping
      </Button>
    ),
    disableFilters: true,
    disableSortBy: true,
  },
];
