import dayjs from 'dayjs';
import { useCallback, useMemo, useRef, FC, useEffect } from 'react';
import {
  Table,
  IOnFetchArguments,
  useTableData,
  ISortBy,
  OnSaveEditableRow,
  Notification,
  IRow,
  ICellValue,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import {
  DEFAULT_FILTER_VALUES,
  defaultOrdering,
  pageSizes,
} from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import {
  CpAndCTrade,
  PatchCpAndCTradePayload,
  SIDE,
  patchCpAndCTrade,
  getCpAndCTrades,
} from 'services/recon';
import {
  cpListByEntitySelector,
  modeNames,
  newCounterPartyNames,
  newLegalEntityNames,
  userNamesSelector,
} from 'store/reducers/commonReducer';
import { theme } from 'theme';
import { calculateCountOfPages } from 'utils';
import { getChangedValues } from 'utils/getChangedValues';
import { getSelectOptions } from 'utils/getSelectOptions';

import { ActionsBlock } from './ActionsBlock/ActionsBlock';
import { ReconciledTable } from './ReconciledTable/ReconciledTable';
import { patchTradeError } from './consts';
import { useManualReconcileUnion } from './hooks';
import { TradesTable } from './types';
import { getAdditionalColumns, getTradesTableColumns } from './utils';

export const UnionManualReconcilePage: FC<{ isReconciledPage: boolean }> = ({
  isReconciledPage,
}) => {
  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const ccyList = useAppSelector((state) => state.currencies);
  const counterPartyNames = useAppSelector(newCounterPartyNames);
  const modeNamesList = useAppSelector(modeNames);
  const userNames = useAppSelector(userNamesSelector);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const ccyOptions = useMemo(() => getSelectOptions(ccyList), [ccyList]);

  const {
    selectedTrades,
    setSelectedTrades,
    handleCheckTrade,
    prepareFiltersForParams,
    reconciledUpdateCount,
    reloadReconciledTable,
    breakCategoryObject,
    breakCategoryOptions,
    tableId,
  } = useManualReconcileUnion({ isReconciledPage });
  const getTrades = useCallback((props: IOnFetchArguments) => {
    const { break_report_category: breakReportCategory } = props.filtersParams;
    return getCpAndCTrades(
      {
        ...props,
        filtersParams: {
          ...props.filtersParams,
          break_report_category:
            (breakReportCategory as string[])?.map(
              (name) => breakCategoryObject[name],
            ) ?? undefined,
        },
      },
      isReconciledPage,
    );
  }, []);

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTrades },
      filters: {
        prepareFiltersForParams,
      },
      saveViewParamsAfterLeave: true,
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getTrades],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    fetchData,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<TradesTable>(tableData);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.le as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);
  useEffect(() => {
    if (legalEntityNames.length && !filters.le) {
      setFilter('le', legalEntityNames[0]);
    }
  }, [legalEntityNames]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions: getSelectOptions(legalEntityNames),
        cpOptions,
        isReconciled: isReconciledPage,
      }),
    [setFilter, removeFilter, cpOptions, legalEntityNames, isReconciledPage],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const handleCheckAllOnPage = () => {
    const notSelectedOnPage = data?.trades.filter(
      (i) => !selectedTrades.find((j) => j.id === i.id),
    );
    setSelectedTrades([...selectedTrades, ...(notSelectedOnPage || [])]);
  };
  const handleUnCheckAllOnPage = () => {
    const withoutTradesOnPage = selectedTrades.filter((item) => {
      const foundTrade = data?.trades.find((i) => i.id === item.id);
      return !foundTrade;
    });
    setSelectedTrades(withoutTradesOnPage);
  };

  const columns = useMemo(
    () =>
      getTradesTableColumns(
        {
          trades: data?.trades,
          selectedTradesIds: selectedTrades.map((i) => i.id),
          onCheckTrade: handleCheckTrade,
          onCheckAllTradesOnPage: handleCheckAllOnPage,
          onUnCheckAllTradesOnPage: handleUnCheckAllOnPage,
          breakCategoryOptions,
          cpOptions,
          ccyOptions,
          userNames,
          modeNames: modeNamesList,
          onFilter: setFilter,
          onRemove: removeFilter,
          reloadData: fetchData,
        },
        isReconciledPage,
      ),
    [
      data,
      fetchData,
      selectedTrades,
      breakCategoryOptions,
      modeNamesList,
      userNames,
      counterPartyNames,
      ccyOptions,
      cpOptions,
    ],
  );

  const handleSaveEditableRow: OnSaveEditableRow<CpAndCTrade> = async (
    previousValues,
    updatedValues,
  ) => {
    if (previousValues.side === SIDE.THEIR) {
      Notification.error({ title: 'You can`t edit "their" trades' });
      return;
    }
    try {
      const changedValues = getChangedValues(previousValues, updatedValues);
      const payload: PatchCpAndCTradePayload = {
        id:
          selectedTrades.length > 1
            ? selectedTrades.map((item) => item.id).join(',')
            : previousValues.id,
        side: previousValues.side,
      };
      if (changedValues.date) {
        payload.date = dayjs(changedValues.date).format(DATE_FORMAT);
      }
      if (changedValues.cpname) {
        payload.cp = changedValues.cpname;
      }
      await patchCpAndCTrade(payload);
      Notification.success({ title: 'Trade updated success' });
      setSelectedTrades([]);
      await fetchData();
    } catch (e) {
      if (String(e).includes(patchTradeError.sourceFileIsNotRecon)) {
        Notification.error({
          title:
            'You cannot change any field for this record (field "source" not equal "UI")',
        });
      } else {
        Notification.error({ title: 'Update trade error' });
      }
    }
  };
  const getRowProps = useCallback(
    (row: IRow<CpAndCTrade>) => {
      let background = theme?.color.table.bg.basic2;
      const isSelectElement = !!selectedTrades.find(
        (i) => i.id === row.original.id,
      );
      if (isSelectElement) {
        background = theme?.color.table.bg.source;
      } else if (row.original.side === SIDE.OUR) {
        background = theme?.color.bg.secondary;
      }
      return {
        style: {
          background,
        },
      };
    },
    [selectedTrades, data],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<CpAndCTrade>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );

  const tableTitle = isReconciledPage ? 'Reconciled Trades' : 'Trades';
  return (
    <div className="container-fluid" ref={containerRef}>
      {!isReconciledPage && (
        <ActionsBlock
          reloadReconciledTable={reloadReconciledTable}
          reloadData={fetchData}
          trades={data?.trades || []}
          selectedTrades={selectedTrades}
          onUpdateSelectedTrades={setSelectedTrades}
          containerRef={containerRef}
        />
      )}
      <div className="row mt-1">
        <div className="col-12">
          <Table
            tableId={tableId}
            title={tableTitle}
            showTableInfo
            hasPagination
            hasFilters
            locale={defaultLocale}
            manualSortBy
            isFlexLayout
            showScrollbar
            saveColumnOrder
            saveViewParamsAfterLeave
            data={data?.trades ?? []}
            columns={columns}
            isLoading={isLoadingCpBo}
            pageSizes={pageSizes}
            filteringProps={filteringProps}
            onSort={handleSorting}
            getRowProps={getRowProps}
            getCellProps={getCellProps}
            defaultSortBy={
              isReconciledPage ? [{ id: 'id', desc: true }] : defaultOrdering
            }
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination.total || 0,
              setPage,
              setPageSize: setLimit,
            }}
            rowActions={{
              show: !isReconciledPage,
              onSave: handleSaveEditableRow,
              isEditedRow: (row) => row.side === SIDE.OUR,
            }}
          />
        </div>
      </div>
      {!isReconciledPage && (
        <div className="row">
          <div className="col-12">
            <ReconciledTable
              getCellProps={getCellProps}
              getRowProps={getRowProps}
              filters={filters}
              reloadUnreconciledTable={fetchData}
              reconciledUpdateCount={reconciledUpdateCount}
            />
          </div>
        </div>
      )}
    </div>
  );
};
