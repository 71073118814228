import { Button, ICellValue, IColumn, Notification } from 'react-ui-kit-exante';

import { minColumnWidth as minWidth, unknownCpOption } from 'constants/tables';
import {
  CpAndCTrade,
  SIDE,
  Transaction,
  unReconcileTrades,
} from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';
import { getSelectOptions, getCellCheckbox, getGeneralCheckbox } from 'utils';

import { GetColumnsPayload } from './types';

const minWidths = {
  id: 80,
  recon_group_id: 160,
  side: 70,
  qty: 80,
  price: 75,
  legal_entity: 115,
  date: 140,
  mode: 120,
  value: 90,
  value_date: 115,
  type: 70,
  cp: 120,
  symbol: 120,
  ccy: 85,
  isin: 70,
  account: 110,
  break_report_category: 135,
  break_comment: 120,
};

const symbolOptions = getSelectOptions(['empty']);

export const getTradesTableColumns = (
  {
    trades,
    selectedTradesIds,
    onCheckTrade,
    onCheckAllTradesOnPage,
    onUnCheckAllTradesOnPage,
    cpOptions,
    modeNames,
    userNames,
    breakCategoryOptions,
    onRemove,
    onFilter,
    reloadData,
    ccyOptions,
  }: (GetColumnsPayload & ColumnsWithFilters) | Record<string, any>,
  isReconciled = false,
) => {
  const cpOptionsCompleted = [unknownCpOption, ...(cpOptions ?? [])];
  const modeOptions = getSelectOptions(modeNames);
  const userOptions = getSelectOptions(userNames);
  const sideOptions = [
    { label: 'Our', value: 'our' },
    { label: 'Their', value: 'their' },
  ];
  const columns: IColumn<CpAndCTrade>[] = [
    {
      accessor: 'id',
      minWidth: minWidths.id,
      width: minWidths.id,
      Header: () =>
        Array.isArray(trades)
          ? getGeneralCheckbox<CpAndCTrade>(
              trades,
              selectedTradesIds,
              onCheckAllTradesOnPage,
              onUnCheckAllTradesOnPage,
              (trade) => trade.id,
            )
          : '',

      Cell: ({ row }: ICellValue<CpAndCTrade>) =>
        Array.isArray(trades)
          ? getCellCheckbox<CpAndCTrade>(
              row.values.id,
              trades,
              selectedTradesIds,
              onCheckTrade,
              (trade) => trade.id,
            )
          : '',
      disableSortBy: true,
      disableFilters: true,
      className: 'd-block',
    },
    {
      Header: 'Recon group',
      accessor: 'recon_group_id',
      minWidth: minWidths.recon_group_id,
      Cell: ({ row }: ICellValue<Transaction>) => {
        const groupId = row.original.recon_group_id;
        if (!groupId) {
          return null;
        }
        const handleUngroup = async () => {
          try {
            await unReconcileTrades({ group_id: groupId });
            Notification.success({
              title: `Group:${groupId} success unreconciled`,
            });
            reloadData();
          } catch (error) {
            Notification.error({ title: 'Unreconcile error' });
          }
        };
        return (
          <div>
            <div>
              <span>{groupId}</span>
            </div>
            <Button color="red" size="small" onClick={handleUngroup}>
              Unrecon
            </Button>
          </div>
        );
      },
      disableSortBy: true,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Exchange order id',
      accessor: 'exchange_order_id',
      minWidth: minWidths.recon_group_id,
      type: 'textInput',
      onFilter,
      onRemove,
      disableSortBy: true,
    },
    {
      Header: 'Order id',
      accessor: 'order_id',
      minWidth: minWidths.id,
      type: 'textInput',
      onFilter,
      onRemove,
      disableSortBy: true,
    },
    {
      Header: 'Chain ID',
      accessor: 'chain_id',
      minWidth: minWidths.id,
      disableFilters: true,
    },
    {
      Header: 'Reconciliation Type',
      accessor: 'type_of_recon',
      minWidth: minWidths.recon_group_id,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Side',
      accessor: 'side',
      minWidth: minWidths.side,
      width: minWidths.side,
      type: 'select',
      filterOptions: sideOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Date',
      accessor: 'date',
      minWidth: minWidths.date,
      width: minWidths.date,
      editParams: {
        editable: (cell: ICellValue<CpAndCTrade>) =>
          cell.row.original.side === SIDE.OUR,
        inputType: 'date',
      },
      useLocale: false,
      type: 'dateRange',
      onFilter,
      onRemove,
    },
    {
      Header: 'Legal entity',
      accessor: 'legal_entity_name',
      minWidth: minWidths.legal_entity,
      width: minWidths.legal_entity,
      disableFilters: true,
    },
    {
      Header: 'Assigned',
      accessor: 'assignee',
      minWidth,
      type: 'select',
      filterOptions: userOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Qty',
      accessor: 'qty',
      minWidth: minWidths.qty,
      width: minWidths.qty,
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Price',
      accessor: 'price',
      minWidth: minWidths.price,
      width: minWidths.price,
      formatting: 'number',
      type: 'textInput',
      onFilter,
      onRemove,
    },
    {
      Header: 'Value',
      accessor: 'value',
      minWidth: minWidths.value,
      width: minWidths.value,
      formatting: 'number',
      disableFilters: true,
    },
    {
      Header: 'Value date',
      accessor: 'value_date',
      minWidth: minWidths.value_date,
      width: minWidths.value_date,
      disableFilters: true,
    },
    {
      Header: 'Type',
      accessor: 'type',
      minWidth: minWidths.type,
      width: minWidths.type,
      disableFilters: true,
    },
    {
      Header: 'Mode',
      accessor: 'mode',
      minWidth: minWidths.mode,
      width: minWidths.mode,
      type: 'select',
      filterOptions: modeOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'CP',
      accessor: 'cpname',
      minWidth: minWidths.cp,
      width: minWidths.cp,
      editParams: {
        editable: (cell: ICellValue<CpAndCTrade>) =>
          cell.row.original.side === SIDE.OUR,
        inputType: 'select',
        options: cpOptionsCompleted,
      },
      disableFilters: true,
    },
    {
      Header: 'Bo symbol',
      accessor: 'bo_symbol',
      Cell: ({ row }: ICellValue<CpAndCTrade>) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.bo_symbol}</span>
      ),
      minWidth: minWidths.symbol,
      width: minWidths.symbol,
      type: 'multiSelect',
      freeSolo: true,
      filterOptions: symbolOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Cp symbol',
      accessor: 'cp_symbol',
      Cell: ({ row }: ICellValue<CpAndCTrade>) => (
        <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.cp_symbol}</span>
      ),
      minWidth: minWidths.symbol,
      width: minWidths.symbol,
      type: 'multiSelect',
      freeSolo: true,
      filterOptions: symbolOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'CCY',
      accessor: 'ccy',
      minWidth: minWidths.ccy,
      width: minWidths.ccy,
      type: 'select',
      filterOptions: ccyOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'ISIN',
      accessor: 'isin',
      minWidth: minWidths.isin,
      width: minWidths.isin,
      disableFilters: true,
    },
    {
      Header: 'Account',
      accessor: 'account',
      minWidth: minWidths.account,
      width: minWidths.account,
      disableFilters: true,
    },
    {
      Header: 'internal comment',
      accessor: 'internal_comment',
      minWidth: minWidths.break_report_category,
      width: minWidths.break_report_category,
      disableFilters: true,
      disableSortBy: true,
    },
    {
      Header: 'Break category',
      accessor: 'break_report_category',
      minWidth: minWidths.break_report_category,
      width: minWidths.break_report_category,
      type: 'multiSelect',
      filterOptions: breakCategoryOptions,
      onFilter,
      onRemove,
    },
    {
      Header: 'Comment',
      accessor: 'break_comment',
      minWidth: minWidths.break_comment,
      width: minWidths.break_comment,
      disableFilters: true,
    },
  ];
  const unReconciledFields = ['break_report_category', 'break_comment', 'id'];
  const reconciledFields = [
    'recon_group_id',
    'type_of_recon',
    'exchange_order_id',
  ];
  const forReconciled = columns.filter(
    (item) => !unReconciledFields.includes(item.accessor as string),
  );
  const forUnreconciled = columns.filter(
    (item) => !reconciledFields.includes(item.accessor as string),
  );
  return isReconciled ? forReconciled : forUnreconciled;
};

export const getAdditionalColumns = ({
  onFilter,
  onRemove,
  entityOptions,
  cpOptions,
  isReconciled,
}: ColumnsWithFilters & {
  entityOptions: SelectOption[];
  cpOptions: SelectOption[];
  isReconciled: boolean;
}) => {
  const columns: IColumn<Record<string, unknown>>[] = [
    {
      Header: 'Legal entity',
      accessor: 'le',
      type: 'select',
      onFilter,
      onRemove,
      filterOptions: entityOptions,
    },
    {
      Header: 'Cp',
      accessor: 'counterparty',
      type: 'select',
      onFilter,
      onRemove,
      filterOptions: [unknownCpOption, ...cpOptions],
    },
    {
      Header: 'Break report filter',
      accessor: 'break_report_filter',
      type: 'checkbox',
      onFilter,
      onRemove,
    },
  ];
  return isReconciled
    ? columns
    : [
        ...columns,
        {
          Header: 'Break report filter',
          accessor: 'break_report_filter',
          type: 'checkbox',
          onFilter,
          onRemove,
        },
      ];
};
