import { styled } from 'theme';

export const Message = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  color: theme?.color?.typo?.inverse,

  '& a': {
    color: theme?.color?.typo?.inverse,
    textDecoration: 'underline',
  },
}));

export const Title = styled('div')(({ theme }) => ({
  fontSize: theme?.size?.text?.lg,
  lineHeight: '24px',
}));

export const Description = styled('div')(({ theme }) => ({
  fontSize: theme?.size?.text?.md,
}));
