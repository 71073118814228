import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import {
  FetchArrayResponse,
  FetchArrayResponseWithoutNext,
  ResponseWithPagination,
} from '../../types';
import { getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';
import { FieldMapping } from '../types';

import {
  FetchReportPayload,
  CreateMappingReportWithFieldsPayload,
  Report,
  ReportWithFields,
  ValueMappingRule,
  ValueMappingRuleWithConditionsPayload,
  MappingListResponse,
  IUpdateBoSymbolPayload,
} from './types';

export async function getListMappingReports(payload: FetchReportPayload) {
  return ReconApiService.getResource<ResponseWithPagination<Report>>(
    getUrlFromPayload('mapping/report/?', payload),
  );
}

// TODO: Add types
export async function getListAutoMappingRuleWithFields(payload: any) {
  return ReconApiService.getResource<any>(
    getUrlFromPayload('mapping/auto-mapping-rule-with-fields/list/?', payload),
  );
}

// TODO: Add type
export async function getListEntityDistinct() {
  const url = 'mapping/entitylegalname_distinct/';
  return ReconApiService.getResource<any>(url);
}

export async function getListComparisonType() {
  const url = 'mapping/comparison-type/';
  return ReconApiService.getResource<FetchArrayResponse<{ type: string }>>(url);
}

export async function getListReplaceType() {
  const url = 'mapping/mapping-replace-type/';
  return ReconApiService.getResource<FetchArrayResponse<{ type: string }>>(url);
}

export async function getListReportState() {
  const url = 'mapping/report-state/';
  return ReconApiService.getResource<
    FetchArrayResponse<{ id: number; state: string }>
  >(url);
}

export async function getListMappingCpTradesFields() {
  const url = 'mapping/cptrades-fields/list/';
  return ReconApiService.getResource<MappingListResponse>(url);
}

export async function getListMappingRowBalanceFields() {
  const url = 'mapping/row-balance-fields/list/';
  return ReconApiService.getResource<MappingListResponse>(url);
}

export async function getListCpTransactionFields() {
  const url = 'mapping/cp-transaction-fields/list/';
  return ReconApiService.getResource<MappingListResponse>(url);
}

export async function getCouponFields() {
  const url = 'mapping/coupon-fields/list/';
  return ReconApiService.getResource<MappingListResponse>(url);
}

export async function getDividendFields() {
  const url = 'mapping/dvd-records-fields/list/';
  return ReconApiService.getResource<MappingListResponse>(url);
}

export async function getListMappingPositionReconciliationFields() {
  const url = 'mapping/position-reconciliation-fields/list/';
  return ReconApiService.getResource<MappingListResponse>(url);
}

export async function getListMappingReportType() {
  const url = 'mapping/report-type/';
  return ReconApiService.getResource<FetchArrayResponse<{ type: string }>>(url);
}

export async function getMappingReportWithFields(reportID: string) {
  const url = `mapping/report-with-fields/${reportID}/`;
  return ReconApiService.getResource<ReportWithFields>(url);
}

export async function getListMappingReportWithFields() {
  const url = `mapping/report-with-fields/`;
  return ReconApiService.getResource<ReportWithFields[]>(url);
}

export async function getListValueMappingRuleWithConditions({
  paginationParams,
  filtersParams: { report_name: reportId, ...filtersParams },
}: IOnFetchArguments) {
  try {
    const payload: Record<string, string | number> = {
      ...paginationParams,
      ...filtersParams,
      report_id: reportId as string,
    };
    const response = await ReconApiService.getResource<
      FetchArrayResponseWithoutNext<ValueMappingRule>
    >(
      getUrlFromPayload(
        'mapping/value-mapping-rule-with-conditions/list/?',
        payload,
      ),
    );
    return {
      rules: response.results,
      pagination: { total: response.total } as any,
    };
  } catch (error) {
    Notification.error({ title: `Load value mapping rules error: ${error}` });
  }
  return {
    rules: [],
    pagination: { total: 0 } as any,
  };
}

export async function updatePositionReconciliationFromMapping(
  mappingID: number,
) {
  const url =
    'position-recon/action/update-position-reconciliation-from-mapping/';
  const payload = { id: mappingID };
  return ReconApiService.postResource<{ id: number }, { message: string }>(
    url,
    payload,
  );
}

export async function patchReport(id: number, payload: any) {
  try {
    await ReconApiService.patch(`mapping/report/${id}/`, payload);
    Notification.success({
      title: `Success update field ${payload} for report`,
    });
  } catch (e) {
    Notification.error({ title: 'Update report error' });
  }
}

export async function postMappingReportWithFields(
  payload: CreateMappingReportWithFieldsPayload,
) {
  const url = 'mapping/create-report-with-fields/';
  return ReconApiService.postResource<
    CreateMappingReportWithFieldsPayload,
    { report_id: number }
  >(url, payload);
}

export async function postMappingFields(payload: {
  report_id: string;
  field_mappings: Omit<FieldMapping, 'mapping_type'>[];
}) {
  return ReconApiService.postResource<any, any>(
    'mapping/create-fields/',
    payload,
  );
}

export async function postUpdateBoSymbol(payload: IUpdateBoSymbolPayload) {
  return ReconApiService.postResource<IUpdateBoSymbolPayload, unknown>(
    'mapping/update-bo-symbol/',
    payload,
  );
}

// TODO: Fix type
export async function postMappingUpdateReport(payload: any) {
  const url = `mapping/update-report/`;
  return ReconApiService.postResource<any, any>(url, payload);
}

// TODO: Fix type
export async function postCloneReportWithFields(reportID: number) {
  const url = `mapping/clone-report-with-fields/${reportID}`;
  return ReconApiService.postResource<any, any>(url);
}

// TODO: Fix type
export async function postDeleteMappingField(fieldID: number | string) {
  const url = `mapping/delete-field/${fieldID}/`;
  return ReconApiService.postResource<any, any>(url);
}

export async function postValueMappingRuleWithConditions(
  payload: ValueMappingRuleWithConditionsPayload,
) {
  const url = 'mapping/value-mapping-rule-with-conditions/add/';
  return ReconApiService.postResource<
    ValueMappingRuleWithConditionsPayload,
    { message: string }
  >(url, payload);
}
