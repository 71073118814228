import {
  ForwardedRef,
  forwardRef,
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { Checkbox } from '../../../Inputs/Checkbox';
import { useBulkActions } from '../../contexts/BulkActionsContext';
import { IndeterminateInputProps } from '../../types';

const IndeterminateCheckboxInner = <Data extends object>(
  { indeterminate, row, ...rest }: IndeterminateInputProps<Data>,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const defaultRef = useRef();
  const resolvedRef: any = ref || defaultRef;
  const bulkActions = useBulkActions();

  const handleOnClick = useCallback(
    (e: MouseEvent) => {
      if (bulkActions?.setSelectedRows) {
        bulkActions.setSelectedRows([row]);
      }
      row?.toggleRowSelected();
      e.stopPropagation();
    },
    [bulkActions, row],
  );

  useEffect(() => {
    const selectedRows = bulkActions?.selectedRows;

    if (selectedRows?.some((selectedRow) => selectedRow?.id === row?.id)) {
      row?.toggleRowSelected();
    }
  }, [row, bulkActions]);

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return <Checkbox {...rest} ref={resolvedRef} onClick={handleOnClick} />;
};

IndeterminateCheckboxInner.displayName = 'IndeterminateCheckbox';

export const IndeterminateCheckbox = forwardRef(IndeterminateCheckboxInner) as <
  Data extends object,
>(
  props: IndeterminateInputProps<Data> & { ref?: ForwardedRef<HTMLDivElement> },
) => ReturnType<typeof IndeterminateCheckboxInner<Data>>;
