import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  IOnFetchArguments,
  Notification,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import {
  DEFAULT_FILTER_VALUES,
  defaultOrdering,
  pageSizes,
} from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import {
  Report,
  getListMappingReports,
  getListValueMappingRuleWithConditions,
} from 'services/recon';
import {
  cpListByEntitySelector,
  modeNames,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { calculateCountOfPages } from 'utils';
import { getDefaultFilterParams } from 'utils/getDefaultFilterParams';
import { getSelectOptions } from 'utils/getSelectOptions';

import { RulesTable } from './types';
import { getColumns } from './utils/getColumns';

export const ValueMappingRules: FC = () => {
  const filtersIsFirstMounted = useRef(false);
  const history = useHistory();
  const [reports, setReports] = useState<Report[]>([]);
  const leNames = useAppSelector(newLegalEntityNames);
  const modeNamesList = useAppSelector(modeNames);

  const leOptions = useMemo(() => getSelectOptions(leNames), [leNames]);
  const modeOptions = useMemo(
    () => getSelectOptions(modeNamesList),
    [modeNamesList],
  );
  const reportOptions = useMemo(
    () => getSelectOptions(reports, 'name', 'id'),
    [reports],
  );

  const loadReports = async (
    counterparty: string,
    legalEntity: string,
    mode: string,
  ) => {
    try {
      const reportsRes = await getListMappingReports({
        counterparty,
        legal_entity: legalEntity,
        mode,
      });
      setReports(reportsRes.content);
    } catch (error) {
      Notification.error({ title: `Load report error: ${error}` });
    }
  };
  const applyObject = {
    legal_entity: DEFAULT_FILTER_VALUES.le,
    counterparty: DEFAULT_FILTER_VALUES.cp,
    mode: DEFAULT_FILTER_VALUES.mode,
  };
  const filterKeys = getColumns({}).map((column) => column.accessor);
  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !window.location.search,
    }),
    [],
  );

  const getRules = useCallback(
    (props: IOnFetchArguments) => getListValueMappingRuleWithConditions(props),
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getRules },
      filters: {
        prepareFiltersForParams,
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getRules],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
  } = useTableData<RulesTable>(tableData);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.legal_entity as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);

  useEffect(() => {
    loadReports(
      filters.counterparty as string,
      filters.legal_entity as string,
      filters.mode as string,
    );
  }, [filters]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );
  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
    }),
    [filters, resetFilters],
  );

  const columns = useMemo(
    () =>
      getColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        cpOptions,
        leOptions,
        modeOptions,
        reportOptions,
      }),
    [cpOptions, leOptions, modeOptions, reportOptions],
  );

  const handleClickAdd = () => {
    history.push('/mapping/add-value-mapping-rule');
  };
  return (
    <Table
      tableId="valueMappingRulesTable"
      title="Value mapping rules"
      showTableInfo
      hasPagination
      hasFilters
      locale={defaultLocale}
      manualSortBy
      isFlexLayout
      showScrollbar
      data={data?.rules ?? []}
      onAdd={handleClickAdd}
      columns={columns}
      isLoading={isLoadingCpBo}
      pageSizes={pageSizes}
      filteringProps={filteringProps}
      defaultSortBy={defaultOrdering}
      serverPaginationProps={{
        pageIndex: page,
        pageCount,
        pageSize: limit,
        total: data?.pagination.total || 0,
        setPage,
        setPageSize: setLimit,
      }}
    />
  );
};
