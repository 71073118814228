import dayjs from 'dayjs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  Panel,
  Input,
  Button,
  Select,
  Autocomplete,
  Notification,
  InputDatePicker,
  styled,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks';
import {
  getListReplaceType,
  postValueMappingRuleWithConditions,
  ValueMappingRuleWithConditionsPayload,
} from 'services/recon';
import {
  modeNames,
  newCounterPartyNames,
  newLegalEntityNames,
  reportTypesSelector,
} from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils/getSelectOptions';

import { ConditionItem } from './components';
import { valueTypeOptions } from './consts';
import { useMappingConditions, useValueMappingCreateForm } from './hooks';
import { FormPayload } from './types';

const InputsBlock = styled('div')(() => ({
  width: '50%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
}));

const InputItem = styled('div')(() => ({
  width: '50%',
  padding: '0 4px',
}));

export const AddValueMappingRule: FC = () => {
  const counterpartyNames = useSelector(newCounterPartyNames);
  const legalEntityNames = useSelector(newLegalEntityNames);
  const reportTypes = useAppSelector(reportTypesSelector);
  const modes = useSelector(modeNames);
  const counterpartyOptions = useMemo(
    () => getSelectOptions(counterpartyNames),
    [counterpartyNames],
  );
  const legalEntityOptions = useMemo(
    () => getSelectOptions(legalEntityNames),
    [legalEntityNames],
  );
  const typeOptions = useMemo(
    () => getSelectOptions(reportTypes, 'type', 'type'),
    [reportTypes],
  );
  const [replaceTypes, setReplaceTypes] = useState<{ type: string }[]>([]);
  const modeOptions = useMemo(() => getSelectOptions(modes), [modes]);
  const {
    conditions,
    handleAddCondition,
    handleRemoveCondition,
    handleUpdateCondition,
  } = useMappingConditions();
  const {
    type,
    handleSetType,
    counterparty,
    handleSetCounterparty,
    legalEntity,
    handleSetLegalEntity,
    mode,
    handleSetMode,
    reportOptions,
    report,
    fieldOptions,
    resultOptions,
    handleSetReport,
    startDate,
    handleChangeStartDate,
    endDate,
    handleChangeEndDate,
  } = useValueMappingCreateForm();
  const { register, handleSubmit } = useForm<FormPayload>();
  const [standardValueType, setStandardValueType] = useState('');

  const onAdd: SubmitHandler<FormPayload> = async (payload) => {
    try {
      const convertedPayload: ValueMappingRuleWithConditionsPayload = {
        name: payload.name,
        report: report?.id ?? 0,
        cp_update_field: payload.cp_update_field,
        cp_source_field: payload.cp_source_field,
        replace_type: payload.replace_type,
        standard_value: payload.standard_value,
        standard_value_type: standardValueType,
        order_index: payload.order_index,
        active: true,
        start_date: startDate ? dayjs(startDate).format(DATE_FORMAT) : '',
        end_date: endDate ? dayjs(endDate).format(DATE_FORMAT) : '',
        conditions: conditions.map(({ uuid, ...item }) => item),
      };
      if (payload.replace_value) {
        convertedPayload.replace_value = payload.replace_value;
      }
      await postValueMappingRuleWithConditions(convertedPayload);
      Notification.success({ title: 'Value mapping was created' });
    } catch (error) {
      Notification.error({
        title: `Create value mapping error`,
        description: JSON.stringify(error),
      });
    }
  };

  const handleChangeStandardValueType = (
    event: React.SyntheticEvent,
    option: any,
  ) => {
    if (option?.value) {
      setStandardValueType(option.value);
    } else {
      setStandardValueType('');
    }
  };

  const loadReplaceTypes = async () => {
    try {
      const response = await getListReplaceType();
      setReplaceTypes(response.results);
    } catch (error) {
      Notification.error({ title: 'Load replace types error' });
    }
  };
  useEffect(() => {
    loadReplaceTypes();
  }, []);

  const replaceTypeOptions = useMemo(
    () => getSelectOptions(replaceTypes.map((item) => item.type)),
    [replaceTypes],
  );

  return (
    <Panel title="Add value mapping rule">
      <p className="text-left">
        <b>Filters for report</b>
      </p>
      <InputsBlock>
        <InputItem>
          <Autocomplete
            placeholder="Counterparty"
            className="mb-3"
            fullWidth
            defaultValue={[counterparty]}
            options={counterpartyOptions}
            onChange={handleSetCounterparty}
          />
        </InputItem>
        <InputItem>
          <Select
            label="Legal Entity"
            className="mb-3"
            fullWidth
            value={legalEntity}
            onChange={handleSetLegalEntity}
            options={legalEntityOptions}
          />
        </InputItem>
        <InputItem>
          <Select
            label="Mode"
            className="mb-3"
            fullWidth
            value={mode}
            onChange={handleSetMode}
            options={modeOptions}
          />
        </InputItem>
        <InputItem>
          <Select
            label="Report type"
            className="mb-3"
            fullWidth
            value={type}
            onChange={handleSetType}
            options={typeOptions}
          />
        </InputItem>

        <Select
          label="Report"
          className="mb-3 mt-3"
          fullWidth
          value={report?.id}
          onChange={handleSetReport}
          options={reportOptions}
        />
        <Input className="mb-3" fullWidth label="Name" {...register('name')} />
        <InputItem>
          <Select
            label="Replace Type"
            className="mb-3"
            fullWidth
            disabled={!report}
            options={replaceTypeOptions}
            {...register('replace_type')}
          />
        </InputItem>
        <InputItem>
          <Autocomplete
            title="Standard Value Type"
            placeholder="Standard Value Type"
            className="mb-3"
            fullWidth
            disabled={!report}
            options={valueTypeOptions}
            onChange={handleChangeStandardValueType}
          />
        </InputItem>
        <InputItem>
          <Select
            label="Update Field"
            className="mb-3"
            fullWidth
            disabled={!report}
            options={fieldOptions}
            {...register('cp_update_field')}
          />
        </InputItem>
        <InputItem>
          <Select
            label="Source Field"
            className="mb-3"
            fullWidth
            disabled={!report}
            options={fieldOptions}
            {...register('cp_source_field')}
          />
        </InputItem>
        <InputItem>
          <InputDatePicker
            className="mb-3"
            locale={defaultLocale}
            onChange={handleChangeStartDate}
            disabled={!report}
            inputProps={{
              fullWidth: true,
              label: 'Start date',
            }}
            selected={startDate}
          />
        </InputItem>
        <InputItem>
          <InputDatePicker
            className="mb-3"
            locale={defaultLocale}
            onChange={handleChangeEndDate}
            disabled={!report}
            inputProps={{
              fullWidth: true,
              label: 'End date',
            }}
            selected={endDate}
          />
        </InputItem>
        <InputItem>
          <Input
            fullWidth
            className="mb-3"
            label="Replace Value"
            disabled={!report}
            {...register('replace_value')}
          />
        </InputItem>
        <InputItem>
          <Input
            fullWidth
            className="mb-3"
            label="Standard Value"
            disabled={!report}
            {...register('standard_value')}
          />
        </InputItem>
        <InputItem>
          <Input
            fullWidth
            className="mb-3"
            label="Order Index"
            disabled={!report}
            {...register('order_index')}
          />
        </InputItem>
      </InputsBlock>
      <div className="div">
        {conditions.map((item) => (
          <ConditionItem
            key={item.uuid}
            condition={item}
            updateCondition={handleUpdateCondition}
            removeCondition={handleRemoveCondition}
            report={report}
            fieldOptions={fieldOptions}
            resultOptions={resultOptions}
          />
        ))}
      </div>
      <div className="d-flex w-50">
        <Button
          className="mr-3 w-50"
          color="secondary"
          disabled={!report}
          onClick={handleAddCondition}
        >
          Add condition
        </Button>
        <Button className="w-50" onClick={handleSubmit(onAdd)}>
          Create
        </Button>
      </div>
    </Panel>
  );
};
