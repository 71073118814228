import { IRow } from 'react-ui-kit-exante';

import { PositionItem } from 'services/recon';
import { theme } from 'theme';

export const getRowProps =
  (selectedItems: PositionItem[]) => (row: IRow<PositionItem>) => {
    let background = theme?.color.button.radical.typo.focus;
    if (selectedItems.find((i) => i.id === row.original.id)) {
      background = theme?.color.table.bg.source;
    } else if (row.original.actual_break || row.original?.diff === 0) {
      background = theme?.color.table.bg.basic2;
    }
    return {
      style: {
        background,
      },
    };
  };
