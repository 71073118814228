import { ComponentType, ReactNode } from 'react';

import { styled } from 'theme';

import { blockNonNativeProps } from '../../helpers';

interface IIndicatorStyledProps {
  backgroundColor?: string;
  children: ReactNode;
}

export const IndicatorLine = styled('div', {
  shouldForwardProp: blockNonNativeProps(['backgroundColor', 'theme']),
})<IIndicatorStyledProps>(({ backgroundColor, theme }) => ({
  overflow: 'hidden',
  position: 'relative',
  display: 'flex',
  width: '100%',
  height: '8px',
  backgroundColor: backgroundColor || theme?.color.indicator.bg.basic,
})) as ComponentType<IIndicatorStyledProps>;

interface ILineProps {
  width: string;
  backgroundColor: string;
  disableAnimation?: boolean;
}

export const Line = styled('div', {
  shouldForwardProp: blockNonNativeProps([
    'backgroundColor',
    'disableAnimation',
    'width',
  ]),
})(({ width, backgroundColor, disableAnimation }: ILineProps) => {
  const baseStyles = {
    width,
    backgroundColor,
    height: '100%',
  };
  const animations = {
    transition: 'width 1s',
    transitionTimingFunction: 'ease-out',
  };

  if (!disableAnimation) {
    return { ...baseStyles, ...animations };
  }

  return baseStyles;
});
