import { FC, useCallback, useEffect, useMemo } from 'react';
import {
  ICellValue,
  IOnFetchArguments,
  IRow,
  ISortBy,
  Table,
  useTableData,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { pageSizes } from 'constants/tables';
import { useAppSelector } from 'hooks/redux';
import {
  Transaction,
  TransactionOperationType,
  getCpBoTransactions,
} from 'services/recon';
import {
  newCounterPartyNames,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { calculateCountOfPages } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { TransactionsTable } from '../types';
import { getAdditionalColumns, getTransactionsTableColumns } from '../utils';

interface Props {
  operationTypes: TransactionOperationType[];
  filters: IOnFetchArguments['filtersParams'];
  reloadUnreconciledTable: VoidFunction;
  reconciledUpdateCount: number;
  getRowProps: (val: IRow<Transaction>) => Record<string, unknown>;
  getCellProps: (val: ICellValue<Transaction>) => Record<string, unknown>;
}
export const ReconciledTable: FC<Props> = ({
  operationTypes,
  filters,
  reloadUnreconciledTable,
  reconciledUpdateCount,
  getRowProps,
  getCellProps,
}) => {
  const counterPartyNames = useAppSelector(newCounterPartyNames);
  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const ccyList = useAppSelector((state) => state.currencies);
  const breakCategories = useAppSelector((state) => state.breakCategories);

  const entityOptions = useMemo(
    () => getSelectOptions(legalEntityNames),
    [legalEntityNames],
  );
  const counterPartyOptions = useMemo(
    () => getSelectOptions(counterPartyNames),
    [counterPartyNames],
  );

  const getReconciledTransactions = useCallback(
    (props: IOnFetchArguments) =>
      getCpBoTransactions(
        {
          ...props,
          filtersParams: { ...filters, type_of_recon: 'MANUAL' },
        },
        true,
      ),
    [filters],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getReconciledTransactions },
      pagination: { getDefaultPagination: () => ({ limit: 5, skip: 0 }) },
      filters: {
        getDefaultFilters: () => filters,
      },
    }),
    [getReconciledTransactions],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    fetchData,
  } = useTableData<TransactionsTable>(tableData);

  useEffect(() => {
    fetchData();
  }, [reconciledUpdateCount]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );
  const transactionsTableAdditionalColumns = useMemo(
    () =>
      getAdditionalColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions,
        counterPartyOptions,
        isReconciled: true,
      }),
    [setFilter, removeFilter, entityOptions, counterPartyOptions],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters: transactionsTableAdditionalColumns,
    }),
    [filters, resetFilters, transactionsTableAdditionalColumns],
  );

  const reloadData = async () => {
    await fetchData();
    await reloadUnreconciledTable();
  };

  const transactionsTableColumns = useMemo(
    () =>
      getTransactionsTableColumns(
        {
          transactions: data?.transactions,
          breakCategories,
          operationTypes,
          cpList: counterPartyNames,
          ccyList,
          onFilter: setFilter,
          onRemove: removeFilter,
          reloadData,
        },
        true,
      ),
    [
      data,
      breakCategories,
      setFilter,
      removeFilter,
      entityOptions,
      counterPartyNames,
      operationTypes,
      filters,
    ],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  return (
    <Table
      tableId="ReconciledTransactions"
      title="Reconciled transactions"
      showTableInfo
      hasPagination
      manualSortBy
      isFlexLayout
      showScrollbar
      saveColumnOrder
      saveViewParamsAfterLeave
      data={data?.transactions ?? []}
      columns={transactionsTableColumns}
      isLoading={isLoadingCpBo}
      pageSizes={pageSizes}
      locale={defaultLocale}
      filteringProps={filteringProps}
      onSort={handleSorting}
      getRowProps={getRowProps}
      getCellProps={getCellProps}
      defaultSortBy={[{ id: 'id', desc: true }]}
      serverPaginationProps={{
        pageIndex: page,
        setPage,
        pageSize: limit,
        setPageSize: setLimit,
        total: data?.pagination?.total ?? 0,
        pageCount,
      }}
    />
  );
};
