/* eslint no-param-reassign: 0 */
import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';

import {
  CommentCategory,
  Counterparty,
  CpMapping,
  LegalEntity,
  ReconUser,
  ReconUserItemList,
  ResolutionCategory,
} from 'services/recon';
import { SimpleListObject } from 'types';

import { StateObject } from '../models';

interface CommonState {
  new_counterparty_list: Counterparty[];
  new_legal_entity_list: LegalEntity[];
  cpList: CpMapping[];
  cpListsByEntities: Record<string, Counterparty[]>;
  cpTypes: SimpleListObject[];
  transactionCategoriesByOperationTypes: Record<string, string[]>;
  legalEntities: Record<string | number, string>;
  breakCategories: CommentCategory[];
  reportTypes: { type: string }[];
  reportStates: { id: number; state: string }[];
  currencies: string[];
  operationTypes: string[];
  categories: SimpleListObject[];
  modeList: SimpleListObject[];
  reconUsersList: ReconUserItemList[];
  personalReconUsersList: ReconUserItemList[];
  resolutionCategories: ResolutionCategory[];
  currentUser: StateObject<ReconUser | null>; // TODO names
}

const initialState: CommonState = {
  new_counterparty_list: [],
  new_legal_entity_list: [],
  transactionCategoriesByOperationTypes: {},
  cpList: [],
  cpListsByEntities: {},
  cpTypes: [],
  legalEntities: {},
  breakCategories: [],
  reportTypes: [],
  reportStates: [],
  categories: [],
  currencies: [],
  operationTypes: [],
  modeList: [],
  reconUsersList: [],
  personalReconUsersList: [],
  resolutionCategories: [],
  currentUser: {
    value: null,
    isLoading: false,
  },
};

export const commonSlice = createSlice({
  name: 'commonState',
  initialState,
  reducers: {
    setCpTypes(state, action: PayloadAction<SimpleListObject[]>) {
      state.cpTypes = action.payload;
    },
    setCurrentUser(
      state,
      action: PayloadAction<StateObject<ReconUser | null>>,
    ) {
      state.currentUser = action.payload;
    },
    setResolutionCategories(
      state,
      action: PayloadAction<ResolutionCategory[]>,
    ) {
      state.resolutionCategories = action.payload;
    },
    setReconUserList(state, action: PayloadAction<ReconUserItemList[]>) {
      state.reconUsersList = action.payload;
    },
    setPersonalReconUserList(
      state,
      action: PayloadAction<ReconUserItemList[]>,
    ) {
      state.personalReconUsersList = action.payload;
    },
    setCpList(state, action: PayloadAction<CpMapping[]>) {
      state.cpList = action.payload;
    },
    setCpListsByEntities(
      state,
      action: PayloadAction<Record<string, Counterparty[]>>,
    ) {
      state.cpListsByEntities = action.payload;
    },
    setNewCpList(state, action: PayloadAction<Counterparty[]>) {
      state.new_counterparty_list = action.payload;
    },
    setNewLEList(state, action: PayloadAction<LegalEntity[]>) {
      state.new_legal_entity_list = action.payload;
    },
    setCategoriesList(state, action: PayloadAction<SimpleListObject[]>) {
      state.categories = action.payload;
    },
    setLENames(state, action: PayloadAction<Record<string | number, string>>) {
      state.legalEntities = action.payload;
    },
    setCcyList(state, action: PayloadAction<string[]>) {
      state.currencies = action.payload;
    },
    setReportTypeList(state, action: PayloadAction<{ type: string }[]>) {
      state.reportTypes = action.payload;
    },
    setReportStateList(
      state,
      action: PayloadAction<{ id: number; state: string }[]>,
    ) {
      state.reportStates = action.payload;
    },
    setOperationTypeList(state, action: PayloadAction<string[]>) {
      state.operationTypes = action.payload;
    },
    setBreakCategoryList(state, action: PayloadAction<CommentCategory[]>) {
      state.breakCategories = action.payload;
    },
    setModeList(state, action: PayloadAction<SimpleListObject[]>) {
      state.modeList = action.payload;
    },
    setTransactionCategoriesByOperationTypes(
      state,
      action: PayloadAction<Record<string, string[]>>,
    ) {
      state.transactionCategoriesByOperationTypes = action.payload;
    },
  },
});

type State = ReturnType<typeof commonSlice.reducer>;

export const getCounterPartyNames = createSelector(
  (state: State) => state.cpList,
  (cpList) =>
    cpList
      .filter((i) => i.active)
      .reduce(
        (acc: string[], i) => (acc.includes(i.cp) ? acc : [i.cp, ...acc]),
        [],
      )
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase())),
);
export const modeNames = createSelector(
  (state: State) => state.modeList,
  (cpList) => cpList.map((mode) => mode.name),
);

export const userNamesSelector = createSelector(
  (state: State) => state.reconUsersList,
  (reconUsersList) => reconUsersList?.map((user) => user.username),
);
export const personalUserNamesSelector = createSelector(
  (state: State) => state.personalReconUsersList,
  (personalReconUsersList) =>
    personalReconUsersList?.map((user) => user.username),
);
export const currentUserSelector = createSelector(
  (state: State) => state.currentUser.value,
  (currentUser) => currentUser,
);

export const cpListByEntitySelector = createSelector(
  [
    (state: State) => ({
      cpListsByEntities: state.cpListsByEntities,
      new_counterparty_list: state.new_counterparty_list,
    }),
    (_: unknown, entity: string) => entity,
  ],
  ({ cpListsByEntities, new_counterparty_list }, entity) =>
    entity ? cpListsByEntities[entity] : new_counterparty_list,
);

export const reportTypesSelector = createSelector(
  (state: State) => state.reportTypes,
  (types) => types,
);

export const reportStatesSelector = createSelector(
  (state: State) => state.reportStates,
  (states) => states,
);

export const categoriesByTypesSelector = createSelector(
  (state: State) => state.transactionCategoriesByOperationTypes,
  (states) => states,
);

export const newCounterPartyNames = createSelector(
  (state: State) => state.new_counterparty_list,
  (cpList) => cpList.map((cp) => cp.name),
);

export const newLegalEntityNames = createSelector(
  [
    (state: State) => state.cpListsByEntities,
    (state: State) => state.new_legal_entity_list,
  ],
  (entityObject, entityList) =>
    entityList
      .filter((item) => !!entityObject[item.name])
      .map((item) => item.name),
);
export default commonSlice.reducer;
