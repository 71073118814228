import { uniqueId } from 'lodash';
import { FC, isValidElement, ReactNode } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';

import { defaultTheme as theme } from 'theme';

import { CloseIcon, NotificationsIcon } from '../../Icons';

import NotificationStyles from './Notification.module.css';
import {
  TOAST_CLOSE_HANDLERS,
  ToastContent,
} from './ToastContent/ToastContent';
import {
  DEFAULT_DURATION,
  DEFAULT_POSITION,
  NOTIFICATION_ANIMATION_ENTER,
  NOTIFICATION_ANIMATION_EXIT,
} from './const';
import {
  NotificationProviderProps,
  NotificationVariant,
  TNotificationMethodArgs,
} from './types';

export const NotificationProvider: FC<NotificationProviderProps> = ({
  duration = DEFAULT_DURATION,
  position = DEFAULT_POSITION,
}) => {
  return (
    <Toaster
      toastOptions={{
        duration,
        position,
        className: NotificationStyles.Notification,
        icon: (
          <NotificationsIcon
            applyStates={false}
            alt="notification icon"
            color={theme?.color?.icon?.inverse}
            size={24}
            style={{ marginTop: 4 }}
          />
        ),
      }}
    >
      {(t) => (
        <ToastBar
          toast={t}
          style={{
            animation: t.visible
              ? NOTIFICATION_ANIMATION_ENTER
              : NOTIFICATION_ANIMATION_EXIT,
            color: theme?.color?.typo?.inverse,
          }}
        >
          {({ icon, message }) => (
            <>
              {icon}
              {message}
              <CloseIcon
                applyStates={false}
                alt="clear"
                color={theme?.color?.icon?.inverse}
                onClick={() => {
                  TOAST_CLOSE_HANDLERS[t.id]?.();
                  toast.dismiss(t.id);
                }}
                size={24}
                style={{ alignSelf: 'top', cursor: 'pointer', marginTop: 5 }}
              />
            </>
          )}
        </ToastBar>
      )}
    </Toaster>
  );
};

/**
 * Pushes notification message inside of Toaster component mounted by
 * {@link NotificationProvider}
 *
 * This signature is deprecated, please use the one with destructuring argument.
 *
 * @deprecated
 *
 * @param variant     notification level / style
 * @param title       notification title text
 * @param description notification details text
 */
function emit(
  variant: NotificationVariant,
  titleOrProps: ReactNode | TNotificationMethodArgs,
  optionalDescription?: ReactNode,
): void {
  let title: ReactNode;
  let description: ReactNode;
  let isPermanent: boolean | undefined;
  let onClose: (() => void) | undefined;

  // Check if the first parameter is plain object (old signature)
  if (
    // XXX: The list of conditions is not complete as there are still
    // some cases when some non-null object which is not valid element
    // can still be a valid React node (i.e. an array).
    // To avoid redundant run-time checks, these cases wouldn't be checked as they are deprecated.
    // You can still use the solution from here to involve full check:
    // https://github.com/facebook/prop-types/blob/main/factoryWithTypeCheckers.js#L474
    typeof titleOrProps === 'object' &&
    titleOrProps != null &&
    !isValidElement(titleOrProps)
  ) {
    ({ title, description, isPermanent, onClose } =
      titleOrProps as TNotificationMethodArgs);
  } else {
    title = titleOrProps as ReactNode;
    description = optionalDescription;
  }

  // each notification should have a unique ID
  // defined before its creation
  const toastId = uniqueId('t-');

  toast.success(
    () => (
      <ToastContent
        title={title}
        description={description}
        toastId={toastId}
        onClose={onClose}
      />
    ),
    {
      id: toastId,
      style: {
        backgroundColor: theme?.color?.notifications[variant],
      },
      // if undefined, the value from provider will be used
      duration: isPermanent ? Infinity : undefined,
    },
  );
}

function getEmitFunctionByVariant(variant: NotificationVariant) {
  return emit.bind(null, variant);
}

export const NotificationMethods = {
  success: getEmitFunctionByVariant('success'),
  error: getEmitFunctionByVariant('error'),
  warning: getEmitFunctionByVariant('warning'),
};

window.UIKitNotification = window.UIKitNotification || NotificationMethods;

export const Notification = window.UIKitNotification;
