import { FC, useMemo, useState } from 'react';
import {
  Panel,
  Autocomplete,
  Notification,
  Select,
  Input,
} from 'react-ui-kit-exante';

import { useAppSelector } from 'hooks/redux';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as SaveIcon } from 'icons/save.svg';
import { bindMappingReport, createMappingReport } from 'services/recon';
import { modeNames } from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils';

interface IProps {
  reloadData: VoidFunction;
  onClose: VoidFunction;
  cp: string;
  cpId: number;
  le: string;
  leId: number;
  rawDataSetup: string;
  rawDataSetupId: number;
}

export const CreateMapping: FC<IProps> = ({
  reloadData,
  onClose,
  cp,
  le,
  cpId,
  leId,
  rawDataSetup,
  rawDataSetupId,
}) => {
  const modeNamesList = useAppSelector(modeNames);
  const reportTypes = useAppSelector((state) => state.reportTypes);
  const [name, setName] = useState('');
  const [mode, setMode] = useState('');
  const [type, setType] = useState('');

  const title = useMemo(
    () => `Add normalization for ${le} -> ${cp} -> ${rawDataSetup}`,
    [rawDataSetup, cp, le],
  );
  const modeOptions = useMemo(
    () => getSelectOptions(modeNamesList),
    [modeNamesList],
  );
  const reportTypesOptions = useMemo(
    () => getSelectOptions(reportTypes, 'type', 'type'),
    [reportTypes],
  );

  const handleCreateMapping = async () => {
    try {
      const response = await createMappingReport({
        counterparty_id: cpId,
        legal_entity_id: leId,
        mode,
        name,
        type,
      });
      await bindMappingReport({
        raw_data_report_setup_id: rawDataSetupId,
        mapping_report_id: response.id,
      });
      Notification.success({
        title: 'Mapping report was created',
      });
      reloadData();
      onClose();
    } catch (error) {
      Notification.error({
        title: 'Error in unbind process',
        description: JSON.stringify(error),
      });
    }
  };
  return (
    <Panel
      title={title}
      action={
        <div className="d-flex">
          <div className="pointer mr-1" onClick={handleCreateMapping}>
            <SaveIcon />
          </div>
          <div className="pointer" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
      }
    >
      <div className="text-left">
        <Input
          label="Name"
          className="mb-3"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <Autocomplete
          placeholder="Mode"
          className="w-100 mb-3"
          options={modeOptions}
          onChange={(event, newValue: any) => {
            setMode(newValue?.value);
          }}
        />
        <Select
          placeholder="Type"
          fullWidth
          options={reportTypesOptions}
          onChange={(event) => {
            setType(event.target.value);
          }}
        />
      </div>
    </Panel>
  );
};
