import dayjs from 'dayjs';
import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Notification } from 'react-ui-kit-exante';

import { unknownCpOption } from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks';
import {
  patchPositionBreak,
  postPositionBreak,
  PositionItem,
  postSymbolsMapping,
  postUpdateBoSymbol,
} from 'services/recon';
import { cpListByEntitySelector } from 'store/reducers/commonReducer';

import { getSelectOptions } from '../../utils';

interface Payload {
  items: PositionItem[];
  fetchData: VoidFunction;
  formattedDates: (string | null)[];
  leId: number;
  setLeId: (val: number) => void;
  modeId: number;
  isNostro: boolean;
}

const excludedForMappingKeysList = [
  'diff',
  'total_cp',
  'break_diff',
  'break_id',
];
export function usePositionRecon({
  items,
  fetchData,
  leId,
  setLeId,
  modeId,
  formattedDates,
  isNostro,
}: Payload) {
  const breakCategories = useAppSelector((state) => state.breakCategories);
  const counterparties = useAppSelector((state) => state.new_counterparty_list);
  const legalEntities = useAppSelector((state) => state.new_legal_entity_list);

  const [cp, setCp] = useState<string | undefined>();
  const [breakCategory, setBreakCategory] = useState<string | undefined>();
  const [breakComment, setBreakComment] = useState('');

  const handleChangeLeId = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setLeId(Number(value));
  };

  const handleChangeCp = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setCp(value);
  };

  const handleChangeBreakCategory = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value },
    } = event;
    setBreakCategory(value);
  };

  const handleChangeComment = (event: ChangeEvent<HTMLInputElement>) => {
    setBreakComment(event.target.value);
  };

  const handleCleanFields = () => {
    setCp(undefined);
    setBreakComment('');
  };

  const [selectedItems, setSelectedItems] = useState<PositionItem[]>([]);

  const onCleanSelectedItems = useCallback(
    () => setSelectedItems([]),
    [setSelectedItems],
  );
  const handleCheckItem = useCallback(
    (position: PositionItem) => {
      if (selectedItems.find((item) => item.id === position.id)) {
        setSelectedItems([
          ...selectedItems.filter((i) => i.id !== position.id),
        ]);
      } else {
        setSelectedItems([...selectedItems, position]);
      }
    },
    [selectedItems, setSelectedItems],
  );

  const handleCheckAllOnPage = useCallback(() => {
    if (items) {
      setSelectedItems([...selectedItems, ...items]);
    }
  }, [selectedItems, setSelectedItems, items]);

  const handleUnCheckAllOnPage = useCallback(() => {
    const withoutTransactionsOnPage = selectedItems.filter((item) => {
      const foundTransaction = items.find((i) => i.id === item.id);
      return !foundTransaction;
    });
    setSelectedItems(withoutTransactionsOnPage);
  }, [selectedItems, setSelectedItems, items]);

  const preparedCp = useMemo(
    () => (cp === unknownCpOption.value ? null : cp ?? null),
    [cp],
  );
  const mapBreak =
    (isForConfirming = false) =>
    (item: PositionItem) => {
      const foundCategory = breakCategories.find(
        (category) => category.name === item?.break_category,
      );
      const foundCounterparty = counterparties.find(
        (counterparty) =>
          counterparty.name ===
          (isNostro ? item?.counterparty : item?.break_counterparty),
      );

      return {
        report_date: item?.report_date,
        symbol: item?.symbol_id,
        diff_qty: item?.diff as unknown as number,
        legal_entity_id: leId,
        mode_id: modeId,
        counterparty_id:
          isForConfirming || isNostro
            ? String(foundCounterparty?.id) || ''
            : preparedCp,
        category: isForConfirming
          ? String(foundCategory?.id) || ''
          : breakCategory ?? '',
        comment: isForConfirming ? item?.break_comment ?? null : breakComment,
      };
    };

  const confirmIsAvailable = useMemo(
    () =>
      !!selectedItems.length &&
      selectedItems.every(
        (item) =>
          !item.actual_break &&
          !!item.break_category &&
          !!item.break_counterparty,
      ),
    [selectedItems],
  );

  const handleConfirmBreaks = async () => {
    try {
      await postPositionBreak(selectedItems.map(mapBreak(true)));
      Notification.success({
        title: 'Breaks were confirmed',
      });
      fetchData();
      setSelectedItems([]);
    } catch (error) {
      Notification.error({
        title: 'Confirming break error',
        description: JSON.stringify(error),
      });
    }
  };

  const boPosition = useMemo(
    () => selectedItems.find((item) => item.BO !== 0),
    [selectedItems],
  );
  const cpPosition = useMemo(
    () => selectedItems.find((item) => item.BO === 0),
    [selectedItems],
  );
  const counterpartyForMapping = useMemo(
    () =>
      Object.keys(cpPosition ?? {}).find((key) => {
        const value = (cpPosition ?? ({} as PositionItem))[key] as unknown;
        return (
          !excludedForMappingKeysList.includes(key) &&
          typeof value === 'number' &&
          Number(value) !== 0
        );
      }),
    [cpPosition],
  );

  const selectedItemsHaveSameCp = useMemo(
    () =>
      selectedItems.reduce(
        (acc, item) => {
          if (acc.isFirstIteration) {
            return {
              currentCp: item.counterparty,
              isFirstIteration: false,
              isSame: true,
            };
          }
          return {
            currentCp: item.counterparty,
            isFirstIteration: false,
            isSame: item.counterparty === acc.currentCp,
          };
        },
        { currentCp: '', isFirstIteration: true, isSame: true },
      ),
    [selectedItems],
  );

  const matchIsAvailable = useMemo(
    () =>
      selectedItems.length === 2 &&
      !!boPosition &&
      !!cpPosition &&
      (isNostro ? selectedItemsHaveSameCp.isSame : true),
    [selectedItems],
  );

  const handleMatchBreaks = async () => {
    try {
      const payload = {
        cp: isNostro
          ? cpPosition?.counterparty ?? ''
          : counterpartyForMapping ?? '',
        exante_symbol: boPosition?.symbol_id ?? '',
        cp_symbol: cpPosition?.symbol_id ?? '',
        cp_type: boPosition?.symbol_type ?? '',
        start_date: dayjs(formattedDates[0]).toISOString(),
        account: null,
      };
      const postSymbolResponse = await postSymbolsMapping(payload);
      if (postSymbolResponse) {
        await postUpdateBoSymbol({
          target: 'positions',
          exante_symbol: payload.exante_symbol,
          cp_symbol: payload.cp_symbol,
          cp: payload.cp,
          date: formattedDates[0] ?? '',
        });
        Notification.success({
          title: 'Breaks were matched',
        });
      }
      fetchData();
      setSelectedItems([]);
    } catch (error) {
      Notification.error({
        title: 'Break matching error',
        description: JSON.stringify(error),
      });
    }
  };

  const updateIsAvailable = useMemo(
    () =>
      leId >= 0 &&
      (isNostro ? true : !!cp) &&
      !!breakCategory &&
      !!selectedItems.length,
    [cp, leId, breakCategory, selectedItems],
  );

  const handleUpdateBreaks = async () => {
    const actualBreaks = selectedItems.filter((item) => !item.actual_break);
    const notActualBreaks = selectedItems.filter((item) => item.actual_break);
    if (actualBreaks.length) {
      try {
        const response = await postPositionBreak(
          actualBreaks.map(mapBreak(false)),
        );
        Notification.success({
          title: 'Not actual breaks were updated',
          description: JSON.stringify(response),
        });
        fetchData();
        setSelectedItems([]);
      } catch (error) {
        Notification.error({ title: 'Update not actual breaks error' });
        setSelectedItems(actualBreaks);
      }
    }

    if (notActualBreaks.length) {
      const patchBreaksPayload = notActualBreaks.reduce((acc: any, item) => {
        const foundCounterparty = counterparties.find(
          (counterparty) => counterparty.name === item?.counterparty,
        );
        return {
          ids: acc.ids ? `${acc.ids},${item.break_id}` : `${item.break_id}`,
          category: breakCategory,
          counterparty_id: isNostro ? foundCounterparty?.id : preparedCp,
          legal_entity_id: leId,
          report_date: formattedDates,
          comment: breakComment || undefined,
        };
      }, {});
      try {
        await patchPositionBreak(patchBreaksPayload);
        Notification.success({ title: 'Actual breaks were updated' });
        setSelectedItems([]);
        fetchData();
      } catch (error) {
        Notification.error({
          title: 'Update actual breaks error',
          description: JSON.stringify(error),
        });
        setSelectedItems(notActualBreaks);
      }
    }
  };

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (legalEntities.find((item) => item.id === leId)?.name as string) ?? '',
  );

  const leOptions = useMemo(
    () => getSelectOptions(legalEntities, 'name', 'id'),
    [legalEntities],
  );

  const cpOptions = useMemo(
    () =>
      isNostro
        ? getSelectOptions(cpListByEntity, 'name', 'id')
        : [unknownCpOption, ...getSelectOptions(cpListByEntity, 'name', 'id')],
    [cpListByEntity, isNostro],
  );

  const breakOptions = useMemo(
    () => getSelectOptions(breakCategories, 'name', 'id'),
    [breakCategories],
  );

  return {
    cp,
    setCp,
    breakComment,
    setBreakComment,
    breakCategory,
    setBreakCategory,
    handleChangeLeId,
    handleChangeCp,
    handleChangeBreakCategory,
    handleChangeComment,
    handleCleanFields,
    selectedItems,
    onCleanSelectedItems,
    handleCheckItem,
    handleCheckAllOnPage,
    handleUnCheckAllOnPage,
    handleConfirmBreaks,
    handleUpdateBreaks,
    handleMatchBreaks,
    confirmIsAvailable,
    matchIsAvailable,
    updateIsAvailable,
    leOptions,
    cpOptions,
    breakOptions,
  };
}
