import dayjs from 'dayjs';
import { FC, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  Notification,
  Input,
  Select,
  Modal,
  TimePicker,
} from 'react-ui-kit-exante';

import { DATE_FORMAT, TIME_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks/redux';
import {
  UpdateFileRulePayload,
  createFileRule,
  createFileMonitorItem,
} from 'services/recon';
import { getSelectOptions } from 'utils/getSelectOptions';

const extensionOptions = getSelectOptions([
  '.csv',
  '.txt',
  '.xlsx',
  '.xls',
  '.pdf',
]);

interface Props {
  reloadData: VoidFunction;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
  reloadParentData?: VoidFunction;
  legalEntityId?: number;
  counterpartyId?: number;
  hideLeAndCp?: boolean;
}
export const AddForm: FC<Props> = ({
  reloadData,
  isOpen,
  setIsOpen,
  reloadParentData,
  legalEntityId,
  counterpartyId,
  hideLeAndCp = false,
}) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<UpdateFileRulePayload>({
    defaultValues: { location: '/EXT/Counterparty/%m-%B-%Y/%d-%B/', amount: 1 },
  });

  const entities = useAppSelector((state) => state.new_legal_entity_list);
  const cpList = useAppSelector((state) => state.new_counterparty_list);

  const entityOptions = useMemo(
    () => getSelectOptions(entities, 'name', 'id'),
    [entities],
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpList, 'name', 'id'),
    [cpList],
  );

  const [eta, setEta] = useState('');
  const handleChangeEta = (date: unknown) => {
    setEta(date as string);
  };

  const handleCreateFileRule: SubmitHandler<UpdateFileRulePayload> = async (
    data,
  ) => {
    try {
      const response = await createFileRule({
        counterparty_id: hideLeAndCp
          ? Number(counterpartyId)
          : data.counterparty_id,
        legal_entity_id: hideLeAndCp
          ? Number(legalEntityId)
          : data.legal_entity_id,
        eta: dayjs(`12-12-2012 ${eta}`).format(TIME_FORMAT),
        filename_contains: data.filename_contains,
        location: data.location,
        extension: data.extension,
        amount: data.amount,
      });
      if (response) {
        const today = dayjs();
        await createFileMonitorItem({
          date: today.format(DATE_FORMAT),
          last_check_time: today.toISOString(),
          file: response.id,
        });
        setIsOpen(false);
        Notification.success({
          title: 'File rule was created',
        });
        reloadData();
        if (reloadParentData) {
          reloadParentData();
        }
      }
    } catch (e) {
      Notification.error({ title: `Create file rule error: ${e}` });
    }
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      isOpened={isOpen}
      title="Create file rule"
      onClose={handleCloseModal}
      confirmButton={{
        confirmButtonName: 'Create',
        handleConfirm: handleSubmit(handleCreateFileRule),
      }}
    >
      <div className="mt-4">
        {!hideLeAndCp && (
          <>
            <Select
              label="Entity"
              className="mb-3 w-100"
              options={entityOptions}
              {...register('legal_entity_id')}
            />
            <Select
              label="Cp"
              className="mb-3 w-100"
              options={cpOptions}
              {...register('counterparty_id')}
            />
          </>
        )}
        <TimePicker
          label="ETA"
          controlProps={{ fullWidth: true }}
          value={eta}
          onChange={handleChangeEta}
        />
        <Input
          label="File name contains"
          className="my-3"
          fullWidth
          {...register('filename_contains')}
        />
        <Input
          label="Location"
          className="mb-3"
          fullWidth
          {...register('location')}
        />
        <Select
          label="Extension"
          className="mb-3 w-100"
          options={extensionOptions}
          {...register('extension')}
        />
        <Input
          type="number"
          label="Amount"
          className="mb-3"
          fullWidth
          error={Boolean(errors?.amount)}
          {...register('amount', { min: 1 })}
        />
      </div>
    </Modal>
  );
};
