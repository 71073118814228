import cn from 'classnames';
import { useMemo, useState, useCallback, FC, useRef, useEffect } from 'react';
import {
  Table,
  Notification,
  ISortBy,
  OnSaveEditableRow,
  IOnFetchArguments,
  useTableData,
  IRow,
  ICellValue,
  IconButton,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import {
  DEFAULT_FILTER_VALUES,
  defaultOrdering,
  pageSizes,
} from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import {
  Transaction,
  SIDE,
  bulkPatchBoFinancialTransactions,
  patchCpTransaction,
  patchBoFinancialTransaction,
  getCpBoTransactions,
} from 'services/recon';
import {
  categoriesByTypesSelector,
  cpListByEntitySelector,
  modeNames,
  newLegalEntityNames,
  userNamesSelector,
} from 'store/reducers/commonReducer';
import { theme } from 'theme';
import { calculateCountOfPages } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { ActionsBlock } from './ActionsBlock/ActionsBlock';
import { ManualAddTransaction } from './ManualAddTransaction/ManualAddTransaction';
import { ReconciledTable } from './ReconciledTable/ReconciledTable';
import { useUnionTransactionsReconcile } from './hooks';
import { TransactionsTable } from './types';
import {
  getDiffBetweenObjects,
  getTransactionsTableColumns,
  getAdditionalColumns,
} from './utils';

export const UnionTransactionsReconcilePage: FC<{
  isReconciledPage: boolean;
}> = ({ isReconciledPage }) => {
  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const modeNamesList = useAppSelector(modeNames);
  const userNames = useAppSelector(userNamesSelector);
  const categories = useAppSelector((state) => state.categories);
  const categoriesByTypes = useAppSelector(categoriesByTypesSelector);
  const ccyList = useAppSelector((state) => state.currencies);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const {
    selectedTransactions,
    setSelectedTransactions,
    handleCheckTransaction,
    operationTypes,
    prepareFiltersForParams,
    reconciledUpdateCount,
    reloadReconciledTable,
    breakCategoryObject,
    breakCategoryOptions,
    tableId,
  } = useUnionTransactionsReconcile({
    isReconciledPage,
  });
  const [addIsVisible, setAddIsVisible] = useState(false);
  const entityOptions = useMemo(
    () => getSelectOptions(legalEntityNames),
    [legalEntityNames],
  );

  const getTransactions = useCallback((props: IOnFetchArguments) => {
    const { break_report_category: breakReportCategory } = props.filtersParams;
    return getCpBoTransactions(
      {
        ...props,
        filtersParams: {
          ...props.filtersParams,
          break_report_category:
            (breakReportCategory as string[])?.map(
              (name) => breakCategoryObject[name],
            ) ?? undefined,
        },
      },
      isReconciledPage,
    );
  }, []);

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTransactions },
      saveViewParamsAfterLeave: true,
      filters: {
        prepareFiltersForParams,
      },
      pagination: { getDefaultPagination: () => ({ limit: 5, skip: 0 }) },
    }),
    [getTransactions],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    fetchData,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<TransactionsTable>(tableData);
  const [items, setItems] = useState(data?.transactions ?? []);
  useEffect(() => {
    setItems(data?.transactions ?? []);
  }, [data]);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.le as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);

  useEffect(() => {
    if (legalEntityNames.length && !filters.le) {
      setFilter('le', legalEntityNames[0]);
    }
  }, [legalEntityNames]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );
  const additionalFilters = useMemo(
    () =>
      getAdditionalColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions,
        counterPartyOptions: cpOptions,
        isReconciled: isReconciledPage,
      }),
    [setFilter, removeFilter, entityOptions, cpOptions, isReconciledPage],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const handleCheckAllOnPage = () => {
    if (data) {
      setSelectedTransactions([...selectedTransactions, ...items]);
    }
  };
  const handleUnCheckAllOnPage = () => {
    const withoutTransactionsOnPage = selectedTransactions.filter((item) => {
      const foundTransaction = items.find((i) => i.id === item.id);
      return !foundTransaction;
    });
    setSelectedTransactions(withoutTransactionsOnPage);
  };

  const updateData = useCallback(
    (key: keyof Transaction, value: unknown, values: Transaction) => {
      const comparedItems = [...items];
      const foundIndex = items.findIndex((item) => item.id === values.id);
      if (key === 'operationtype') {
        const firstCategoryElement = categoriesByTypes[value as string][0];
        comparedItems[foundIndex] = {
          ...items[foundIndex],
          operationtype: value as string,
          category: firstCategoryElement ?? null,
        };
      } else {
        comparedItems[foundIndex] = { ...items[foundIndex], [key]: value };
      }
      setItems(comparedItems);
    },
    [setItems, items, categoriesByTypes],
  );

  const transactionsTableColumns = useMemo(
    () =>
      getTransactionsTableColumns(
        {
          transactions: items,
          selectedTransactionsIds: selectedTransactions.map((i) => i.id),
          onCheckTransaction: handleCheckTransaction,
          onCheckAllTransactionOnPage: handleCheckAllOnPage,
          onUnCheckAllTransactionOnPage: handleUnCheckAllOnPage,
          breakCategoryOptions,
          operationTypes,
          cpOptions,
          modeNames: modeNamesList,
          ccyList,
          userNames,
          categories,
          categoriesByTypes,
          onFilter: setFilter,
          onRemove: removeFilter,
          reloadData: fetchData,
          updateData,
        },
        isReconciledPage,
      ),
    [
      items,
      categoriesByTypes,
      breakCategoryOptions,
      selectedTransactions,
      operationTypes,
      setFilter,
      userNames,
      removeFilter,
      entityOptions,
      cpOptions,
      modeNamesList,
      filters,
      updateData,
    ],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const handleUpdateOurTransaction = async (
    id: number,
    transaction: Record<string, any>,
  ) => {
    try {
      const {
        report_date: reportDate,
        cpname: counterparty,
        ...otherFields
      } = transaction;
      const selectedIds = selectedTransactions.map((item) => item.id);
      if (selectedIds.length) {
        await bulkPatchBoFinancialTransactions(selectedIds, {
          ...otherFields,
          counterparty,
          cp_report_date: reportDate,
        });
      } else {
        await patchBoFinancialTransaction(id, {
          ...otherFields,
          counterparty,
          cp_report_date: reportDate,
        });
      }

      Notification.success({
        title: 'Our transaction updated',
      });
      setSelectedTransactions([]);
      await fetchData();
      return true;
    } catch (_) {
      Notification.error({ title: 'Update our transaction error' });
      return false;
    }
  };

  const handleUpdateTheirTransaction = async (
    id: number,
    transaction: Record<string, any>,
  ) => {
    try {
      await patchCpTransaction(id, transaction);
      Notification.success({ title: 'Update their transaction' });
      await fetchData();
      return true;
    } catch (_) {
      Notification.error({ title: 'Update their transaction error' });
      return false;
    }
  };

  const handleSaveEditableRow: OnSaveEditableRow<Transaction> = async (
    original,
    _,
  ) => {
    const previousValues = data?.transactions?.find(
      (item) => item.id === original.id,
    );
    const updatedValues = items.find((item) => item.id === original.id);
    if (!previousValues || !updatedValues) {
      return;
    }
    const diff = getDiffBetweenObjects(previousValues, updatedValues);
    if (!Object.values(diff).length) {
      return;
    }
    if (previousValues.side === SIDE.THEIR) {
      await handleUpdateTheirTransaction(updatedValues.id, diff);
    } else {
      await handleUpdateOurTransaction(updatedValues.id, diff);
    }
  };

  const handleCancelEditableRow: OnSaveEditableRow<Transaction> = async (
    original,
    _,
  ) => {
    const previousValues = data?.transactions?.find(
      (item) => item.id === original.id,
    );
    const foundIndex = items.findIndex((item) => item.id === original.id);
    if (foundIndex >= 0 && previousValues) {
      const bufItems = [...items];
      bufItems[foundIndex] = previousValues;
      setItems(bufItems);
    }
  };

  const handleChangeIsVisible = () => {
    setAddIsVisible(!addIsVisible);
  };
  const reloadData = () => {
    setAddIsVisible(false);
    fetchData();
  };
  const getRowProps = useCallback(
    (row: IRow<Transaction>) => {
      let background = theme?.color.table.bg.basic2;
      const isSelectElement = !!selectedTransactions.find(
        (i) => i.id === row.original.id,
      );
      if (isSelectElement) {
        background = theme?.color.table.bg.source;
      } else if (row.original.side === SIDE.OUR) {
        background = theme?.color.bg.secondary;
      }
      return {
        style: {
          background,
        },
      };
    },
    [selectedTransactions, items],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<Transaction>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );

  const tableTitle = isReconciledPage
    ? 'Reconciled Transactions'
    : 'Transactions';
  return (
    <div className="mui-container-fluid" ref={containerRef}>
      {!isReconciledPage && (
        <ActionsBlock
          reloadReconciledTable={reloadReconciledTable}
          reloadData={fetchData}
          transactions={items || []}
          selectedTransactions={selectedTransactions}
          onUpdateSelectedTransactions={setSelectedTransactions}
          containerRef={containerRef}
        />
      )}
      <div className="mui-row mt-1">
        <div
          className={cn({
            'mui-col-md-12': !addIsVisible,
            'mui-col-md-8': addIsVisible,
          })}
        >
          <Table
            tableId={tableId}
            title={tableTitle}
            showTableInfo
            hasPagination
            hasFilters
            locale={defaultLocale}
            manualSortBy
            isFlexLayout
            showScrollbar
            saveColumnOrder
            saveViewParamsAfterLeave
            additionalActions={[
              {
                title: 'Add',
                component: !isReconciledPage ? (
                  <IconButton
                    iconColor="action"
                    iconName="AddIcon"
                    label="Add transaction"
                    onClick={handleChangeIsVisible}
                  />
                ) : undefined,
              },
            ]}
            data={items}
            columns={transactionsTableColumns}
            isLoading={isLoadingCpBo}
            pageSizes={pageSizes}
            filteringProps={filteringProps}
            onSort={handleSorting}
            getRowProps={getRowProps}
            getCellProps={getCellProps}
            defaultSortBy={
              isReconciledPage ? [{ id: 'id', desc: true }] : defaultOrdering
            }
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination.total || 0,
              setPage,
              setPageSize: setLimit,
            }}
            rowActions={{
              show: !isReconciledPage,
              onSave: handleSaveEditableRow,
              onCancel: handleCancelEditableRow,
            }}
          />
        </div>
        {!isReconciledPage && (
          <div
            className={cn({
              'd-none': !addIsVisible,
              'mui-col-md-4': addIsVisible,
            })}
          >
            <ManualAddTransaction
              operationTypes={operationTypes}
              reloadData={reloadData}
            />
          </div>
        )}
      </div>
      {!isReconciledPage && (
        <div className="row">
          <div className="col-12">
            <ReconciledTable
              getCellProps={getCellProps}
              getRowProps={getRowProps}
              operationTypes={operationTypes}
              filters={filters}
              reloadUnreconciledTable={fetchData}
              reconciledUpdateCount={reconciledUpdateCount}
            />
          </div>
        </div>
      )}
    </div>
  );
};
