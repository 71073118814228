import { ICellValue, IColumn } from 'react-ui-kit-exante';

import { PositionItem, Transaction } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';
import { getCellCheckbox, getGeneralCheckbox, getSelectOptions } from 'utils';

export const constantColumnsList = [
  'id',
  'symbol_id',
  'symbol_type',
  'isin',
  'ccy',
  'bo',
  'total_cp',
  'diff',
  'abs_diff',
  'break_category',
  'break_comment',
  'break_username',
  'last_break_report_date',
];
const hiddenColumnList = ['break_id', 'position_value', 'break_diff'];

const defaultColor = '#EEF1F1';
const colorByType: Record<string, string> = {
  STOCK: '#2196F3',
  FUND: '#673AB6',
  FUTURE: '#FF9600',
  BOND: '#3A4FCE',
  CFD: '#4759c4',
  FX_SPOT: '#c18025',
  OPTION: '#aa8ce2',
};

export const getColumns = ({
  columnKeys,
  breakOptions,
  onRemove,
  onFilter,
  items,
  selectedItems,
  handleCheckItem,
  handleCheckAllOnPage,
  handleUnCheckAllOnPage,
  hasAbsDiff,
  hasDiff,
  isNostro,
}: {
  columnKeys: string[];
  breakOptions: SelectOption[];
  items: PositionItem[];
  selectedItems: PositionItem[];
  handleCheckItem: (item: PositionItem) => void;
  handleCheckAllOnPage: VoidFunction;
  handleUnCheckAllOnPage: VoidFunction;
  hasAbsDiff: boolean;
  hasDiff: boolean;
  isNostro: boolean;
} & ColumnsWithFilters): IColumn<any>[] => [
  {
    accessor: 'id',
    width: 70,
    Header: () =>
      Array.isArray(items)
        ? getGeneralCheckbox(
            items,
            selectedItems.map((item) => item.id),
            handleCheckAllOnPage,
            handleUnCheckAllOnPage,
            (item) => item.id,
          )
        : '',
    Cell: ({ row }: ICellValue<Transaction>) =>
      Array.isArray(items)
        ? getCellCheckbox(
            row.values.id,
            items,
            selectedItems.map((item) => item.id),
            handleCheckItem,
            (item) => item.id,
          )
        : '',
    disableSortBy: true,
    disableFilters: true,
    className: 'd-block',
  },
  {
    Header: 'Symbol',
    accessor: 'symbol_id',
    cellStyle: {
      fontWeight: 'bold',
    },
    formatting: 'number',
    type: 'textInput',
    onFilter,
    onRemove,
  },
  {
    Header: 'Type',
    accessor: 'symbol_type',
    Cell: (cell: ICellValue<Record<string, string>>) => (
      <div className="d-flex align-items-center">
        <div
          style={{
            borderRadius: '50%',
            width: '8px',
            height: '8px',
            marginRight: '8px',
            backgroundColor:
              colorByType[cell.row.original.symbol_type?.toUpperCase()] ??
              defaultColor,
          }}
        />
        {cell.row.original.symbol_type}
      </div>
    ),
    type: 'select',
    filterOptions: getSelectOptions(Object.keys(colorByType)),
    onFilter,
    onRemove,
  },
  {
    Header: 'Сcy',
    accessor: 'ccy',
    width: 100,
    onFilter,
    onRemove,
  },
  {
    Header: 'Isin',
    accessor: 'isin',
    onFilter,
    onRemove,
  },
  {
    Header: 'Bo',
    accessor: 'BO',
    cellStyle: {
      fontWeight: 'bold',
    },
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Total cp',
    accessor: 'total_cp',
    cellStyle: {
      fontWeight: 'bold',
    },
    disableFilters: true,
  },
  {
    Header: 'Diff',
    accessor: 'diff',
    disableFilters: hasAbsDiff,
    onFilter,
    onRemove,
  },
  {
    Header: 'Abs diff',
    accessor: 'abs_diff',
    disableFilters: hasDiff,
    onFilter,
    onRemove,
  },
  {
    Header: 'Break category',
    accessor: 'break_category',
    type: 'select',
    filterOptions: breakOptions,
    onFilter,
    onRemove,
    disableSortBy: true,
  },
  {
    Header: 'Comment',
    accessor: 'break_comment',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'User',
    accessor: 'break_username',
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Comment Date',
    accessor: 'last_break_report_date',
    disableFilters: true,
    disableSortBy: true,
  },
  ...columnKeys
    .filter(
      (key) =>
        !constantColumnsList.includes(key.toLowerCase()) &&
        !(
          !isNostro
            ? ['counterparty', 'account', hiddenColumnList]
            : hiddenColumnList
        ).includes(key.toLowerCase()),
    )
    .map((key) => ({
      Header: key.replace(/_/gi, ' '),
      accessor: key,
      disableFilters: true,
      disableSortBy: true,
    })),
];
