import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { SimpleListObject } from 'types';
import { getDateForRequest } from 'utils';

import { defaultEntityTypes, defaultCpTypes } from '../../consts';
import { FetchArrayResponse, ResponseWithPagination } from '../../types';
import {
  getMultiSortingParams,
  getSortingParams,
  getUrlFromPayload,
} from '../../utils';
import { ReconApiService } from '../api';
import { Transaction } from '../types';

import {
  ChangeSymbolsMappingPayload,
  CommentCategory,
  Counterparty,
  CreateCpStatusPayload,
  CreateCpTransactionPayload,
  CreateRawDataReportPayload,
  CreateRowBalancePayload,
  CreateSymbolMappingPayload,
  FormDataCpProps,
  GetSetupIdsPayload,
  LegalEntity,
  ParsingModuleParam,
  PositionRec,
  RawDataReportSetup,
  ResolutionCategory,
  RowBalance,
  SymbolsMapping,
  TransactionOperationType,
  UpdateBreaksPayload,
  UpdateBreaksResponse,
  UpdateCpStatusPayload,
  UpdateOurTransactionPayload,
  UpdateRowBalancePayload,
  CpMapping,
  BoEntityLegalName,
  OperationType,
  Currency,
  FetchCategoryCommentPayload,
  ShortMapping,
  CutOffTimeBySymbol,
  CpTransaction,
  CutOffTimeBySymbolCreatePayload,
  UpdateRawDataReportPayload,
  UpdateMappingReportPayload,
  CreateMappingReportPayload,
  BindMappingReportRoRawDataPayload,
  CloneMappingReportPayload,
} from './types';

export async function getSetupIds(payload: GetSetupIdsPayload) {
  return ReconApiService.getResource<
    ResponseWithPagination<RawDataReportSetup>
  >(
    getUrlFromPayload('tables/raw-data-report-config/?', {
      counterparty: payload.cp,
      legal_entity: payload.le,
      module: payload.module,
    }),
  );
}

export async function getResolutionCategories() {
  return ReconApiService.getResource<FetchArrayResponse<ResolutionCategory>>(
    'tables/resolution-category/?limit=1000',
  );
}

export async function getModeList() {
  return ReconApiService.getResource<
    FetchArrayResponse<{ id: number; name: string }>
  >('tables/mode/');
}

export async function getTransactionCategoriesByOperationTypes() {
  return ReconApiService.getResource<Record<string, string[]>>(
    'tables/transaction-category/?by_operation_type=true',
  );
}

export async function postCpStatus(payload: CreateCpStatusPayload) {
  return ReconApiService.postResource<
    CreateCpStatusPayload,
    CreateCpStatusPayload
  >('tables/cp-status/', payload);
}

export async function patchCpStatus(
  reportId: number,
  payload: UpdateCpStatusPayload,
) {
  return ReconApiService.patch<UpdateCpStatusPayload>(
    `tables/cp-status/${reportId}/`,
    payload,
  );
}

export async function getAllRawDataSetups() {
  return ReconApiService.getResource<RawDataReportSetup[]>(
    `tables/raw-data-report-config/?all=true`,
  );
}

export async function getRawDataReportConfigs({ params }: IOnFetchArguments) {
  try {
    const { sorting, skip, ...filterParams } = params;
    const response = await ReconApiService.getResource<
      ResponseWithPagination<RawDataReportSetup>
    >(
      `${getUrlFromPayload(
        'tables/raw-data-report-config/?',
        filterParams,
      )}order_by=${getMultiSortingParams(sorting)}`,
    );
    return {
      data: response.content,
      pagination: response.pagination as any,
    };
  } catch (error) {
    Notification.error({ title: `Load raw data error: ${error}` });
  }
  return {
    data: [],
    pagination: {},
  };
}

export async function createRawDataReportConfig(
  payload: CreateRawDataReportPayload,
) {
  const url = 'tables/raw-data-report-config/';
  await ReconApiService.postResource<
    CreateRawDataReportPayload,
    RawDataReportSetup
  >(url, payload);
}

export async function updateRawDataReportConfig(
  payload: UpdateRawDataReportPayload,
  id: number,
) {
  return ReconApiService.patch<UpdateRawDataReportPayload>(
    `tables/raw-data-report-config/${id}`,
    payload,
  );
}

export async function createMappingReport(payload: CreateMappingReportPayload) {
  return ReconApiService.postResource<
    CreateMappingReportPayload,
    { id: number }
  >(`mapping/report/`, payload);
}

export async function cloneMappingReport(
  reportId: number,
  payload: CloneMappingReportPayload,
) {
  return ReconApiService.postResource<
    CloneMappingReportPayload,
    { report_id: number }
  >(`mapping/clone-report-with-fields/${reportId}`, payload);
}

export async function updateMappingReport(
  payload: UpdateMappingReportPayload,
  id: number,
) {
  return ReconApiService.patch<UpdateMappingReportPayload>(
    `mapping/report/${id}/`,
    payload,
  );
}

export async function bindMappingReport(
  payload: BindMappingReportRoRawDataPayload,
) {
  return ReconApiService.postResource<
    BindMappingReportRoRawDataPayload,
    { id: number }
  >('mapping/raw-data-mapping-report/', payload);
}

export async function unbindMappingReport(id: number, rawDataSetupId: number) {
  return ReconApiService.delete(`mapping/report/${id}/unbind/`, {
    raw_data_setup_id: rawDataSetupId,
  });
}

export async function getParsingModuleOptions() {
  const url = 'tables/parsing-module/';
  return ReconApiService.getResource<FetchArrayResponse<{ name: string }>>(url);
}

export async function getParsingModuleParams(params: {
  module: string;
  counterparty_name: string;
  legal_entity_name: string;
}) {
  try {
    const response = await ReconApiService.getResource<
      ResponseWithPagination<ParsingModuleParam>
    >(getUrlFromPayload('tables/parsing-module-param/?', params));
    const fileField = response.content.find(
      (item) => item.param_name === 'file',
    );
    return {
      fileField,
      fields: response.content.filter((item) => item.param_name !== 'file'),
    };
  } catch (error) {
    Notification.error({
      title: `Fetch parsing module params error: ${error}`,
    });
    return { fileField: undefined, fields: [] };
  }
}

export async function getListCPMapping() {
  const url = 'tables/cp-mapping/';
  const params = `?cp_types=${defaultCpTypes.join(',')}`;
  return ReconApiService.getResource<FetchArrayResponse<CpMapping>>(
    url + params,
  );
}

export async function getCounterpartyList() {
  return ReconApiService.getResource<FetchArrayResponse<Counterparty>>(
    'tables/counterparty/?limit=1000',
  );
}

export async function getListBOEntityLegalName() {
  const url = 'tables/bo-entity-legal-name/';
  const params = `?type_entities=${defaultEntityTypes.join(',')}`;
  return ReconApiService.getResource<FetchArrayResponse<BoEntityLegalName>>(
    url + params,
  );
}

export async function getLegalEntityList() {
  const url = 'tables/legal-entity/';
  const params = `?type_entities=${defaultEntityTypes.join(',')}`;
  return ReconApiService.getResource<FetchArrayResponse<LegalEntity>>(
    url + params,
  );
}

export async function getListOperationType() {
  const url = 'tables/operation-type/';
  return ReconApiService.getResource<FetchArrayResponse<OperationType>>(url);
}

export async function getTransactionOperationTypeListForPage(page: string) {
  const url = `tables/transaction-operation-types/?for_page=${page}`;
  return ReconApiService.getResource<
    FetchArrayResponse<TransactionOperationType>
  >(url);
}

export async function getListCurrency() {
  const url = 'tables/currency/';
  return ReconApiService.getResource<FetchArrayResponse<Currency>>(url);
}

export async function getCategoryList() {
  const url = 'tables/transaction-category/';
  return ReconApiService.getResource<
    FetchArrayResponse<{ id: number; name: string }>
  >(url);
}

export async function getRowBalanceList(payload: IOnFetchArguments) {
  try {
    const { sorting, skip, ...filterParams } = payload.params;
    const response = await ReconApiService.getResource<
      ResponseWithPagination<RowBalance>
    >(
      getUrlFromPayload('tables/row-balance/?', filterParams) +
        getSortingParams(sorting),
    );
    return {
      items: response.content,
      pagination: response.pagination,
    };
  } catch (error) {
    Notification.error({
      title: `Load row balance error: ${error}`,
    });
  }
  return {
    items: [],
    pagination: {},
  };
}

export async function getBreakCommentCategories(
  payload: FetchCategoryCommentPayload,
) {
  return ReconApiService.getResource<FetchArrayResponse<CommentCategory>>(
    getUrlFromPayload(`tables/comment-category/?`, payload),
  );
}

export async function updateBreaks({
  record_types_mapping,
  comment,
  category,
  assignee,
  cash_recon_affected,
  position_recon_affected,
}: UpdateBreaksPayload) {
  const tempPayload: UpdateBreaksPayload = {
    record_types_mapping,
    cash_recon_affected,
    position_recon_affected,
  };
  if (comment) {
    tempPayload.comment = comment;
  }
  if (category) {
    tempPayload.category = category;
  }
  if (assignee) {
    tempPayload.assignee = assignee;
  }
  return ReconApiService.postResource<
    UpdateBreaksPayload,
    UpdateBreaksResponse
  >('tables/break-report-comment-update/', tempPayload);
}

export async function getListSymbolsMapping({
  sortingParams,
  paginationParams: { skip, ...pagination },
  filtersParams: { date, ...filters },
}: IOnFetchArguments) {
  const payload = { ...pagination, ...filters } as Record<string, unknown>;
  if (date) {
    const [startDate, endDate] = date as string[];
    if (startDate) {
      payload.start_date = getDateForRequest(startDate);
    }
    if (endDate) {
      payload.end_date = getDateForRequest(endDate);
    }
  }
  try {
    const response = await ReconApiService.getResource<
      ResponseWithPagination<SymbolsMapping>
    >(
      getUrlFromPayload('tables/symbols-mapping/?', payload) +
        getSortingParams(sortingParams),
    );
    return {
      items: response.content,
      pagination: { total: response.pagination.total },
    };
  } catch (error) {
    Notification.error({ title: `Load mapped symbols error: ${error}` });
  }
  return { content: [], pagination: { total: 0 } };
}

export async function getPositionReconciliations({
  params,
  filtersParams,
}: IOnFetchArguments) {
  try {
    const { sorting, skip, ...otherParams } = params;
    const response = await ReconApiService.getResource<
      ResponseWithPagination<PositionRec>
    >(
      `${getUrlFromPayload('tables/position-reconciliation/?', {
        ...otherParams,
        ...filtersParams,
        report_date: getDateForRequest(filtersParams.report_date as string),
      })}order=${getMultiSortingParams(sorting)}`,
    );
    return {
      positions: response.content,
      pagination: response.pagination as any,
    };
  } catch (error) {
    Notification.error({ title: 'Load position reconciliations error' });
  }
  return {
    positions: [],
    pagination: {},
  };
}

export async function getListSymbolCutOffTime() {
  return ReconApiService.getResource<FetchArrayResponse<CutOffTimeBySymbol>>(
    'tables/cut-off-time-by-symbol/',
  );
}

export async function getCpByEntity(type?: 'broker') {
  return ReconApiService.getResource<Record<string, Counterparty[]>>(
    getUrlFromPayload('tables/counterparty/?by_entity=true&', { type }),
  );
}

export async function getCpTypes() {
  return ReconApiService.getResource<FetchArrayResponse<SimpleListObject>>(
    getUrlFromPayload('tables/counterparty-type/', {}),
  );
}

export async function postSymbolsMapping(payload: CreateSymbolMappingPayload) {
  const url = 'tables/symbols-mapping/';
  return ReconApiService.postResource<
    CreateSymbolMappingPayload,
    { id: number }
  >(url, payload);
}

export async function patchRowBalance(
  full_id: number,
  payload: UpdateRowBalancePayload,
) {
  const url = `tables/row-balance/${full_id}/`;
  return ReconApiService.patch<UpdateRowBalancePayload>(url, payload);
}

export async function patchCpTransaction(
  full_id: number,
  payload: Record<string, string> | Transaction,
) {
  const url = `tables/cp-transaction/${full_id}/`;
  return ReconApiService.patch<Record<string, string> | Transaction>(
    url,
    payload,
  );
}

export async function patchBoFinancialTransaction(
  full_id: number,
  payload: UpdateOurTransactionPayload,
) {
  const url = `tables/bo-financial-transactions/${full_id}/`;
  const tempPayload = payload;
  if (tempPayload.counterparty) {
    tempPayload.counterparty =
      tempPayload.counterparty === 'null' ? null : tempPayload.counterparty;
  }
  return ReconApiService.patch<UpdateOurTransactionPayload>(url, tempPayload);
}

export async function bulkPatchBoFinancialTransactions(
  ids: number[],
  payload: UpdateOurTransactionPayload,
) {
  const url = `tables/bo-financial-transactions/bulk_patch/`;
  const tempPayload = { ...payload, ids: ids.join(',') };
  if (tempPayload.counterparty) {
    tempPayload.counterparty =
      tempPayload.counterparty === 'null' ? null : tempPayload.counterparty;
  }
  return ReconApiService.patch<UpdateOurTransactionPayload & { ids: string }>(
    url,
    tempPayload,
  );
}

export async function patchSymbolsMapping(
  id: number,
  payload: ChangeSymbolsMappingPayload,
) {
  const url = `tables/symbols-mapping/${id}/`;
  return ReconApiService.patch<ChangeSymbolsMappingPayload>(url, payload);
}

export async function deleteSymbolsMapping(id: number) {
  return ReconApiService.delete(`tables/symbols-mapping/${id}/`);
}

export async function deleteShortMapping(id: number) {
  return ReconApiService.delete(`tables/short-mapping/${id}/`);
}

export async function postShortMapping(payload: ShortMapping) {
  const url = 'tables/short-mapping/';
  return ReconApiService.postResource<ShortMapping, ShortMapping>(url, payload);
}

export async function patchPositionReconciliation(
  id: number,
  payload: PositionRec,
) {
  const url = `tables/position-reconciliation/${id}/`;
  return ReconApiService.patch<PositionRec>(url, payload);
}

export async function postPositionReconciliation(payload: FormDataCpProps) {
  const url = 'tables/position-reconciliation/';
  return ReconApiService.postResource<FormDataCpProps, FormDataCpProps>(
    url,
    payload,
  );
}

export async function deleteSymbolCutOffTime(id: number) {
  return ReconApiService.delete(`tables/cut-off-time-by-symbol/${id}/`);
}

export async function patchSymbolCutOffTime(
  id: number,
  payload: CutOffTimeBySymbol,
) {
  return ReconApiService.patch<CutOffTimeBySymbol>(
    `tables/cut-off-time-by-symbol/${id}/`,
    payload,
  );
}

export async function postSymbolCutOffTime(
  payload: CutOffTimeBySymbolCreatePayload,
) {
  return ReconApiService.postResource<
    CutOffTimeBySymbolCreatePayload,
    CutOffTimeBySymbol
  >('tables/cut-off-time-by-symbol/', payload);
}

export async function postRowBalance(payload: CreateRowBalancePayload) {
  return ReconApiService.postResource<
    CreateRowBalancePayload,
    CreateRowBalancePayload
  >('tables/row-balance/', {
    ...payload,
    source_file: 'recon-ui',
    comment: 'added via interface',
  });
}

export async function postCpTransaction(payload: CreateCpTransactionPayload) {
  return ReconApiService.postResource<
    CreateCpTransactionPayload,
    CpTransaction
  >('tables/cp-transaction/', {
    ...payload,
    source_file: 'recon-ui',
    comment: 'added via interface',
  });
}
