import { Button as ButtonUI } from '@mui/material';

import { styled } from 'theme';

import { blockNonNativeProps } from '../../helpers';

import { StyledButtonProps } from './types';

export const StyledButton = styled(ButtonUI, {
  shouldForwardProp: blockNonNativeProps([
    'color',
    'size',
    'loading',
    'hasText',
    'textColor',
  ]),
})<StyledButtonProps>(
  ({ disabled, hasText, size, color, loading, theme, textColor }) => {
    const cornerSizes = {
      cornerWidth: size === 'small' ? '8px' : '13px',
      cornerHeight: size === 'small' ? '12px' : '19px',
      cornerRadius: size === 'small' ? '3px' : '4px',
    };

    const transparentColor =
      theme?.color.typo[textColor] || theme?.color.typo.secondary;

    return {
      position: 'relative',
      fontFamily: theme?.font.main,
      fontWeight: 500,
      textTransform: 'capitalize',
      color: theme?.color.button.primary.typo.default,
      '& .MuiButton-startIcon': {
        ...(!hasText && { margin: 0 }),
        ...(hasText && { marginRight: size === 'small' ? '4px' : '8px' }),
      },
      ...(size === 'small' && {
        height: '32px',
        minWidth: '32px',
        padding: '8px 12px',
        fontSize: '12px',
        lineHeight: '16px',
        borderRadius: '3px',
      }),
      ...(size === 'medium' && {
        height: '48px',
        minWidth: '48px',
        padding: hasText ? '8px 24px' : '12px',
        fontSize: '15px',
        lineHeight: '32px',
        borderRadius: '4px',
      }),
      ...(color === 'primary' && {
        background: theme?.color.button.primary.gr.default,
        backgroundColor: theme?.color.button.primary.corner.default,
        color: theme?.color.button.primary.typo.default,
        '&:hover': {
          color: theme?.color.button.primary.typo.hover,
          background: theme?.color.button.primary.gr.hover,
          backgroundColor: theme?.color.button.primary.corner.default,
        },
        '&:active, &:focus-visible': {
          background: theme?.color.button.primary.gr.focus,
          color: theme?.color.button.primary.typo.focus,
        },
        '&.Mui-disabled': {
          background: loading
            ? theme?.color.button.primary.gr.focus
            : theme?.color.button.primary.gr.default,
          opacity: disabled && !loading ? 0.4 : 1,
          color: loading
            ? theme?.color.button.primary.typo.focus
            : theme?.color.button.primary.typo.disabled,
        },
        '&::after': {
          content: '""',

          position: 'absolute',
          bottom: 0,
          right: 0,

          width: cornerSizes.cornerWidth,
          height: cornerSizes.cornerHeight,
          borderRadius: `0 0 ${cornerSizes.cornerRadius} 0`,
          transition: '0.3s',
          background: theme?.color.button.primary.corner.default,

          clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%)',
        },
      }),
      ...(color === 'secondary' && {
        background: theme?.color.button.secondary.gr.default,
        backgroundColor: theme?.color.button.secondary.corner.default,
        color: theme?.color.button.secondary.typo.default,
        '&:hover': {
          background: theme?.color.button.secondary.gr.hover,
          backgroundColor: theme?.color.button.secondary.corner.default,
          color: theme?.color.button.secondary.typo.hover,
        },
        '&:active, &:focus-visible': {
          background: theme?.color.button.secondary.gr.focus,
          color: theme?.color.button.secondary.typo.focus,
        },
        '&.Mui-disabled': {
          background: loading
            ? theme?.color.button.secondary.gr.focus
            : theme?.color.button.secondary.gr.hover,
          opacity: disabled && !loading ? 0.4 : 1,
          color: theme?.color.button.secondary.typo.disabled,
        },
        '&::after': {
          content: '""',

          position: 'absolute',
          bottom: 0,
          right: 0,

          width: cornerSizes.cornerWidth,
          height: cornerSizes.cornerHeight,
          borderRadius: `0 0 ${cornerSizes.cornerRadius} 0`,
          transition: '0.3s',
          background: theme?.color.button.secondary.corner.default,

          clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%)',
        },
      }),
      // @ts-expect-error -- intended color. Probably mistake in type IUIButtonProps
      ...(color === 'transparent' && {
        background: 'transparent',
        backgroundColor: 'transparent',
        padding: '0',
        color: transparentColor,
        fontSize: '13px',
        lineHeight: '24px',
        height: '24px',
        minWidth: 'unset',
        '&:hover': {
          background: 'transparent',
          backgroundColor: 'transparent',
          color: theme?.color.typo.promo,
        },
        '&:active, &:focus-visible': {
          background: 'transparent',
          color: theme?.color.typo.promo,
        },
        '&.Mui-disabled': {
          background: 'transparent',
          opacity: disabled && !loading ? 0.4 : 1,
          color: theme?.color.button.secondary.typo.disabled,
        },
        '&::after': {
          display: 'none',
        },
      }),
      // @ts-expect-error -- intended color. Probably mistake in type IUIButtonProps
      ...(color === 'red' && {
        background: theme?.color.button.radical.gr.default,
        backgroundColor: theme?.color.button.radical.corner.default,
        '&:hover': {
          background: theme?.color.button.radical.gr.hover,
          color: theme?.color.button.radical.typo.hover,
          backgroundColor: theme?.color.button.radical.corner.default,
        },
        '&:active, &:focus-visible': {
          background: theme?.color.button.radical.gr.focus,
          color: theme?.color.button.radical.typo.focus,
        },
        '&.Mui-disabled': {
          background: loading
            ? theme?.color.button.radical.gr.focus
            : theme?.color.button.radical.gr.default,
          opacity: disabled && !loading ? 0.4 : 1,
          color: loading
            ? theme?.color.button.radical.typo.focus
            : theme?.color.button.radical.typo.disabled,
        },
        '&::after': {
          content: '""',

          position: 'absolute',
          bottom: 0,
          right: 0,

          width: cornerSizes.cornerWidth,
          height: cornerSizes.cornerHeight,
          borderRadius: `0 0 ${cornerSizes.cornerRadius} 0`,
          transition: '0.3s',
          background: theme?.color.button.radical.corner.default,

          clipPath: 'polygon(100% 0%, 100% 0%, 100% 100%, 0% 100%)',
        },
      }),
    };
  },
) as React.ComponentType<StyledButtonProps>;
