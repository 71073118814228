import dayjs from 'dayjs';
import { useCallback, useMemo, useRef, useState } from 'react';

import { DEFAULT_FILTER_VALUES } from 'constants/tables';
import { CpAndCTrade } from 'services/recon';
import { getSelectOptions, getDefaultFilterParams } from 'utils';

import { useAppSelector } from '../../hooks';

import { getTradesTableColumns } from './utils';

const applyObject = {
  date: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
  counterparty: DEFAULT_FILTER_VALUES.cp,
  mode: DEFAULT_FILTER_VALUES.mode,
};
export const useManualReconcileUnion = ({
  isReconciledPage,
}: {
  isReconciledPage: boolean;
}) => {
  const filtersIsFirstMounted = useRef(false);
  const [selectedTrades, setSelectedTrades] = useState<CpAndCTrade[]>([]);
  const [reconciledUpdateCount, setReconciledUpdateCount] = useState(0);
  const breakCategories = useAppSelector((state) => state.breakCategories);

  const handleCheckTrade = useCallback(
    (trade: CpAndCTrade) => {
      if (selectedTrades.find((i) => i.id === trade.id)) {
        setSelectedTrades([...selectedTrades.filter((i) => i.id !== trade.id)]);
      } else {
        setSelectedTrades([...selectedTrades, trade]);
      }
    },
    [setSelectedTrades, selectedTrades],
  );

  const filterKeys = useMemo(
    () =>
      getTradesTableColumns({}, isReconciledPage).map(
        (column) => column.accessor,
      ),
    [isReconciledPage],
  );

  const tableId = isReconciledPage ? 'DivideReconciledTrades' : 'MixedTrades';

  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !window.location.search,
    }),
    [],
  );

  const reloadReconciledTable = useCallback(() => {
    setReconciledUpdateCount(reconciledUpdateCount + 1);
  }, [setReconciledUpdateCount, reconciledUpdateCount]);

  const breakCategoryOptions = useMemo(
    () => getSelectOptions(breakCategories, 'name', 'name'),
    [breakCategories],
  );
  const breakCategoryObject = useMemo(
    () =>
      breakCategories.reduce(
        (acc: Record<string, string>, item) => ({
          ...acc,
          [item.name]: String(item.id),
        }),
        {},
      ),
    [breakCategories],
  );

  return {
    filtersIsFirstMounted,
    selectedTrades,
    setSelectedTrades,
    handleCheckTrade,
    prepareFiltersForParams,
    reconciledUpdateCount,
    reloadReconciledTable,
    breakCategoryOptions,
    breakCategoryObject,
    tableId,
  };
};
