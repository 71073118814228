import React, { FC, useRef } from 'react';
import {
  Select,
  Input,
  Notification,
  Button,
  Panel,
} from 'react-ui-kit-exante';

import { postSymbolsMapping } from 'services/recon';
import { getFormPayload } from 'services/utils';

import { ManualMappingFormProps } from '../types';

const AddManualMapping: FC<ManualMappingFormProps> = ({
  onMappingChanged,
  cpOptions,
}) => {
  const form = useRef<HTMLFormElement | null>(null);

  const handleSubmit: any = async (event: FormDataEvent) => {
    event.preventDefault();

    if (!form.current) {
      return;
    }
    const payload = getFormPayload(form.current);
    const {
      cp,
      cp_symbol: cpSymbol,
      exante_symbol: exanteSymbol,
      ccy,
    } = payload;

    if (!ccy) {
      Notification.warning({ title: 'Ccy is not specified' });
      return;
    }
    if (!cp) {
      Notification.warning({ title: 'Counterparty is not specified' });
      return;
    }
    if (!cpSymbol) {
      Notification.warning({ title: 'Counterparty symbol is not specified' });
      return;
    }
    if (!exanteSymbol) {
      Notification.warning({ title: 'Exante symbol is not specified' });
      return;
    }
    if (typeof exanteSymbol === 'string') {
      const split = exanteSymbol.split('.');
      if (split.length < 2) {
        Notification.warning({
          title: 'Looks like Exante symbol is in wrong format',
        });
        return;
      }
    }

    const startDate = new Date(Date.now());
    payload.account = payload.cp;
    payload.start_date = startDate.toISOString();
    try {
      await postSymbolsMapping(payload as any);
      onMappingChanged();
    } catch (e) {
      if (String(e).includes('unique')) {
        Notification.error({
          title:
            'The combination of such symbols and counterparty already exists.',
        });
      } else {
        Notification.error({ title: 'Create mapping error' });
      }
    }
  };

  const saveActions = (
    <div className="d-flex align-items-center">
      <Button className="mr-2" onClick={handleSubmit}>
        Add
      </Button>
    </div>
  );

  return (
    <Panel title="Add mapping" action={saveActions} maxHeight={800}>
      <form ref={form}>
        <div className="mt-4">
          <Select placeholder="CP" fullWidth name="cp" options={cpOptions} />
        </div>
        <div className="mt-4">
          <Input name="cp_symbol" fullWidth label="CP Symbol" />
        </div>
        <div className="mt-4">
          <Input fullWidth name="exante_symbol" label="Exante Symbol" />
        </div>
        <div className="mt-4">
          <Input
            fullWidth
            name="value_multiplier"
            type="number"
            label="Mty Value"
          />
        </div>
        <div className="mt-4">
          <Input
            fullWidth
            name="price_multiplier"
            type="number"
            label="Mty Price"
          />
        </div>
        <div className="mt-4">
          <Input
            fullWidth
            name="qty_multiplier"
            type="number"
            label="Mty Qty"
          />
        </div>
        <div className="mt-4">
          <Input fullWidth name="ccy" label="CCY" />
        </div>
        <div className="mt-4">
          <Input fullWidth name="cp_type" label="Cp Type" />
        </div>
        <div className="mt-4">
          <Input fullWidth name="isin" label="ISIN" />
        </div>
      </form>
    </Panel>
  );
};

export default AddManualMapping;
