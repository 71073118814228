import {
  CpDataMonitorItemStatuses,
  Process,
  Stat,
  StatsRecord,
  SubRow,
} from 'services/recon';
import { sortInAlphabet } from 'utils';

import { statusWeights } from '../constants';
import { ByFieldNames } from '../models';

const getFirstAndSecondValue = (stat: Stat, byFieldName: ByFieldNames) => {
  const firstValue =
    byFieldName === 'legal_entity'
      ? stat.legal_entity_name
      : stat.counterparty_name;

  const secondValue =
    byFieldName === 'legal_entity'
      ? stat.counterparty_name
      : stat.legal_entity_name;

  return { firstValue, secondValue };
};

const getStatusByStats = (stats: Stat[], process: Process) => {
  const filteredStats = stats.filter((stat) => stat.process === process);
  if (!filteredStats.length) {
    return undefined;
  }
  return filteredStats.reduce((acc, stat) => {
    return statusWeights[stat.status] < statusWeights[acc] ? stat.status : acc;
  }, CpDataMonitorItemStatuses.DONE);
};
const getStatusByRows = (rows: SubRow[], process: Process) => {
  const filteredRows = rows.filter((row) => !!row[process]);
  if (!filteredRows.length) {
    return undefined;
  }

  return filteredRows.reduce((acc, row) => {
    const status = row[process];
    return status && statusWeights[status] < statusWeights[acc] ? status : acc;
  }, CpDataMonitorItemStatuses.DONE);
};

export const getMapDataForTreeTable = (
  values: Stat[],
  byFieldName: ByFieldNames,
) => {
  const elementsByFields = values.reduce(
    (acc: Record<string, string[]>, item) => {
      const { firstValue, secondValue } = getFirstAndSecondValue(
        item,
        byFieldName,
      );
      const isNewCp = !Object.keys(acc).includes(firstValue);
      if (isNewCp) {
        return { ...acc, [firstValue]: [secondValue] };
      }
      const leList = acc[firstValue].filter((i) => i !== secondValue);
      return {
        ...acc,
        [firstValue]: [...leList, secondValue],
      };
    },
    {},
  );

  Object.values(elementsByFields).forEach((item) => item.sort());

  return Object.keys(elementsByFields)
    .reduce((acc: StatsRecord[], firstKey) => {
      const elements = elementsByFields[firstKey].map((secondKey) => {
        const secondLevelElements = values.filter((stat) => {
          const { firstValue, secondValue } = getFirstAndSecondValue(
            stat,
            byFieldName,
          );
          return firstValue === firstKey && secondKey === secondValue;
        });

        return {
          name: secondKey,
          [Process.Ftp]: getStatusByStats(secondLevelElements, Process.Ftp),
          [Process.Raw]: getStatusByStats(secondLevelElements, Process.Raw),
          [Process.Normalized]: getStatusByStats(
            secondLevelElements,
            Process.Normalized,
          ),
          isHoliday:
            byFieldName === 'legal_entity' &&
            secondLevelElements.some(
              (stat) => stat.status === CpDataMonitorItemStatuses.HOLIDAY,
            ),
          subRows: secondLevelElements.reduce((sourceAcc: SubRow[], stat) => {
            const foundElement = sourceAcc.find(
              (sourceAccItem) => sourceAccItem.name === stat.source,
            );

            if (foundElement) {
              const foundStatus = foundElement[
                stat.process
              ] as CpDataMonitorItemStatuses;

              const currentStatus =
                statusWeights[stat.status] < statusWeights[foundStatus]
                  ? stat.status
                  : foundStatus;
              return [
                ...sourceAcc.filter(
                  (sourceAccItem) => sourceAccItem.name !== stat.source,
                ),
                {
                  ...foundElement,
                  [stat.process]: foundStatus ? currentStatus : stat.status,
                  [`${stat.process}_updated_at`]: stat.updated_at,
                },
              ];
            }
            return [
              ...sourceAcc,
              {
                name: stat.source,
                subName: stat.type,
                [stat.process]: stat.status,
                [`${stat.process}_updated_at`]: stat.updated_at,
              },
            ];
          }, []),
        };
      });
      return [
        ...acc,
        {
          name: firstKey,
          subRows: elements,
          [Process.Ftp]: getStatusByRows(elements, Process.Ftp),
          [Process.Raw]: getStatusByRows(elements, Process.Raw),
          [Process.Normalized]: getStatusByRows(elements, Process.Normalized),
          isHoliday:
            byFieldName === 'counterparty' &&
            values
              .filter((stat) => stat.counterparty_name === firstKey)
              .some(
                (stat) => stat.status === CpDataMonitorItemStatuses.HOLIDAY,
              ),
        },
      ];
    }, [])
    .sort(sortInAlphabet<StatsRecord>('name'));
};
