import { useCallback, useRef, useState } from 'react';
import { NIL as nilUuid, v4 as uuidV4 } from 'uuid';

export interface IUseDataArguments<K = any, A = any> {
  onFetch(...args: A[]): Promise<K | null>;
  onSuccess?: (response: K) => void;
  onFailure?: (error: Error) => void;
  loading?: boolean;
  onlyLastRequest?: boolean;
}

export interface IUseDataValue<T> {
  data: T | null;
  error: Error | null;
  fetchData(): Promise<void>;
  isLoading: boolean;
}

export function useData<T = any, A = any>({
  onFetch,
  onSuccess,
  onFailure,
  loading = true,
  onlyLastRequest = false,
}: IUseDataArguments<T, A>): IUseDataValue<T> {
  const currentRequestId = useRef(nilUuid);
  const [isLoading, setIsLoading] = useState(loading);
  const [data, setData] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);

  const fetchData = useCallback(
    async (...args: A[]): Promise<void> => {
      const shouldIgnorePreviousRequest = (requestId: string): boolean => {
        return onlyLastRequest && currentRequestId.current !== requestId;
      };

      currentRequestId.current = uuidV4();
      const requestId = currentRequestId.current;

      setIsLoading(true);
      setError(null);

      try {
        const response = await onFetch(...args);

        if (shouldIgnorePreviousRequest(requestId)) {
          return;
        }

        setData(response);

        onSuccess?.(response);
      } catch (e) {
        if (shouldIgnorePreviousRequest(requestId)) {
          return;
        }

        if (e instanceof Error) {
          setError(e);
        }

        onFailure?.(e as Error);
      } finally {
        if (!shouldIgnorePreviousRequest(requestId)) {
          setIsLoading(false);
        }
      }
    },
    [onFailure, onFetch, onSuccess, onlyLastRequest],
  );

  return {
    data,
    fetchData,
    isLoading,
    error,
  };
}
