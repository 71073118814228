import dayjs from 'dayjs';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import {
  Notification,
  Button,
  Panel,
  Select,
  Input,
  InputDatePicker,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import { useRawDataSetups } from 'pages/RawData/hooks';
import { postRowBalance } from 'services/recon';
import { cpListByEntitySelector } from 'store/reducers/commonReducer';
import { getSelectOptions } from 'utils/getSelectOptions';

import { categoryValues, types } from '../consts';

import { CreateRowBalanceForm } from './types';

export const ManualAddBalance: FC<{
  reloadData: VoidFunction;
  onClose: VoidFunction;
}> = ({ reloadData, onClose }) => {
  const ccyList = useAppSelector((state) => state.currencies);
  const cpList = useAppSelector((state) => state.new_counterparty_list);
  const entities = useAppSelector((state) => state.new_legal_entity_list);

  const typesList = getSelectOptions(types);
  const ccyOptions = getSelectOptions(ccyList);
  const entityList = getSelectOptions(entities, 'name', 'name');

  const getCpIdMyName = (name: string) =>
    cpList.find((item) => item.name === name)?.id ?? '';
  const getLeIdByName = (name: string) =>
    entities.find((item) => item.name === name)?.id ?? '';

  const { register, handleSubmit, watch, getValues, setValue } =
    useForm<CreateRowBalanceForm>();

  const [dateField, setDateField] = useState<Date | null>(null);
  const handleChangeDate = (date: Date | null) => {
    setDateField(date);
  };

  const {
    getSetupIds,
    postReportRowData,
    getSetupError,
    foundSetup,
    linkToRawData,
  } = useRawDataSetups();

  const [leForFiltering, setLeForFiltering] = useState('');
  const [cpForFiltering, setCpForFiltering] = useState('');

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    leForFiltering as string,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && cpForFiltering) {
      setValue('counterparty', cpOptions[0].value);
    }
  }, [cpOptions]);
  useEffect(() => {
    if (entities.length) {
      setValue('legal_entity', entities[0].name);
    }
  }, [entities]);

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (name === 'counterparty' || name === 'legal_entity') {
        const cp = getValues('counterparty');
        const le = getValues('legal_entity');
        setLeForFiltering(le);
        setCpForFiltering(cp);
        if (cp && le) {
          getSetupIds({ le, cp, module: 'manual_balances' });
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const onSubmit: SubmitHandler<CreateRowBalanceForm> = async (data) => {
    try {
      const cpId = getCpIdMyName(data.counterparty);
      const leId = getLeIdByName(data.legal_entity);
      const rowBalancePayload = {
        account: data.account,
        category: data.category ?? null,
        ccy: data.ccy,
        counterparty_id: cpId.toString(),
        legal_entity_id: leId.toString(),
        report_date: dateField ? dayjs(dateField).format(DATE_FORMAT) : '',
        type: data.type,
        value: data.value,
        comment: '',
        source_file: '',
      };
      const rawMetaDataId = await postReportRowData({
        data: rowBalancePayload,
        le: data.legal_entity,
        module: 'manual_balances',
        reportDate: dateField ? dayjs(dateField).format(DATE_FORMAT) : '',
        cp: data.counterparty,
      });
      if (rawMetaDataId) {
        await postRowBalance({
          ...rowBalancePayload,
          raw_metadata_id: rawMetaDataId,
        });
        Notification.success({ title: 'Row Balance was added' });
        onClose();
        reloadData();
      }
    } catch (e) {
      Notification.error({
        title: 'Add Row Balance error',
      });
    }
  };

  const saveActions = (
    <div className="d-flex align-items-center">
      <Button
        disabled={!foundSetup}
        className="mr-2"
        onClick={handleSubmit(onSubmit)}
      >
        Create
      </Button>
    </div>
  );

  const rawDataSetupMessage =
    !getValues('counterparty') || !getValues('legal_entity')
      ? 'Need to choose "Cp" or "Legal Entity"'
      : getSetupError;

  return (
    <Panel title="Add counterparty balance" action={saveActions}>
      <div className="mt-4 text-left">
        <p>
          Raw data setup:{' '}
          {!foundSetup?.id ? (
            <b>{rawDataSetupMessage}</b>
          ) : (
            <Link target="_blank" to={linkToRawData}>
              {foundSetup?.name}
            </Link>
          )}
        </p>
        <Select
          label="Legal Entity"
          className="mb-3 w-100"
          options={entityList}
          {...register('legal_entity')}
        />
        <Select
          label="Counterparty"
          className="mb-3 w-100"
          options={cpOptions}
          {...register('counterparty')}
        />
        <Select
          label="Type"
          className="mb-3 w-100"
          options={typesList}
          {...register('type')}
        />
        <Select
          label="Ccy"
          className="mb-3 w-100"
          options={ccyOptions}
          {...register('ccy')}
        />
        <Select
          label="Category"
          className="mb-3 w-100"
          options={categoryValues}
          {...register('category')}
        />
        <InputDatePicker
          locale={defaultLocale}
          onChange={handleChangeDate}
          inputProps={{
            fullWidth: true,
            label: 'Date',
          }}
          selected={dateField}
        />
        <div className="mb-3" />
        <Input
          label="Value"
          className="mb-3"
          fullWidth
          {...register('value')}
        />
        <Input
          label="Account"
          className="mb-3"
          fullWidth
          {...register('account')}
        />
      </div>
    </Panel>
  );
};
