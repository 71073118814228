import cn from 'classnames';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  Panel,
  Autocomplete,
  Notification,
  Input,
  Tooltip,
  IconButton,
} from 'react-ui-kit-exante';

import { useAppSelector } from 'hooks/redux';
import { ReactComponent as CloseIcon } from 'icons/close.svg';
import { ReactComponent as SaveIcon } from 'icons/save.svg';
import {
  CreateRawDataReportPayload,
  ParsingModuleParam,
  getParsingModuleParams,
  createRawDataReportConfig,
  getParsingModuleOptions,
} from 'services/recon';
import {
  newCounterPartyNames,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { SelectOption } from 'types';
import { getSelectOptions } from 'utils/getSelectOptions';

import { AddForm } from '../../CpDataMonitor/FileMonitorTable/AddForm';
import { ModuleItem, ModuleValue } from '../ModuleItem/ModuleItem';
import { paramValuesMap } from '../ModuleItem/utils';

interface IProps {
  reloadData: VoidFunction;
  onClose: VoidFunction;
  defaultValues?: {
    cp?: string;
    le?: string;
  };
}

export const NewRawData: FC<IProps> = ({
  reloadData,
  onClose,
  defaultValues,
}) => {
  const counterPartyNames = useAppSelector(newCounterPartyNames);
  const leNames = useAppSelector(newLegalEntityNames);
  const cpList = useAppSelector((state) => state.new_counterparty_list);
  const leList = useAppSelector((state) => state.new_legal_entity_list);

  const [parsingModuleItems, setParsingModuleItems] = useState<
    { name: string }[]
  >([]);
  const [createFileRuleIsOpened, setCreateFileRuleIsOpened] = useState(false);
  const [isFileParam, setIsFileParam] = useState(false);
  const [fileConfigOptions, setFileConfigOptions] = useState<SelectOption[]>(
    [],
  );
  const [name, setName] = useState('');
  const [cp, setCp] = useState('');
  const [le, setLe] = useState('');
  const [module, setModule] = useState('');
  const [fileConfigId, setFileConfigId] = useState<string>();

  useEffect(() => {
    if (defaultValues?.cp) {
      setCp(defaultValues?.cp);
    }
    if (defaultValues?.le) {
      setLe(defaultValues?.le);
    }
  }, []);

  const [selectedModuleParams, setSelectedModuleParams] = useState<
    ParsingModuleParam[]
  >([]);
  const [selectedModuleValues, setSelectedModuleValues] = useState<
    ModuleValue[]
  >([]);

  const handleChangeValues = (value: ModuleValue) => {
    const editedValues = selectedModuleValues.find(
      (i) => i.param_name === value.param_name,
    );
    if (editedValues) {
      const withoutEdited = selectedModuleValues.filter(
        (i) => i.param_name !== value.param_name,
      );
      setSelectedModuleValues([...withoutEdited, value]);
    }
  };

  const cpOptions = useMemo(
    () => getSelectOptions(counterPartyNames),
    [counterPartyNames],
  );
  const leOptions = useMemo(() => getSelectOptions(leNames), [leNames]);
  const parsingModuleOptions = useMemo(
    () =>
      parsingModuleItems.map((i) => ({
        label: i.name.toUpperCase(),
        value: i.name,
      })),
    [parsingModuleItems],
  );

  const fetchParamsData = async () => {
    if (!module) {
      return;
    }
    setFileConfigId(undefined);
    setIsFileParam(false);
    const response = await getParsingModuleParams({
      module,
      legal_entity_name: le,
      counterparty_name: cp,
    });
    setSelectedModuleParams(response.fields);
    setSelectedModuleValues(response.fields.map(paramValuesMap));
    if (response.fileField) {
      setIsFileParam(true);
      setFileConfigOptions(
        response.fileField?.options?.map((item) => ({
          label: item.name,
          value: item.id.toString(),
        })) || [],
      );
    }
  };

  useEffect(() => {
    fetchParamsData();
  }, [module, cp, le]);

  useEffect(() => {
    getParsingModuleOptions()
      .then((res) => {
        setParsingModuleItems(res.results);
      })
      .catch((error) => {
        Notification.error({
          title: `Fetch parsing module options error: ${error}`,
        });
      });
  }, []);

  const handleCreateRaw = async () => {
    const payload: CreateRawDataReportPayload = {
      counterparty: cp,
      legal_entity: le,
      name,
      module,
      file_config: fileConfigId,
      module_configs: selectedModuleValues
        .filter((item) => !!item.param_value)
        .map((item) => ({
          param_value: String(item.param_value),
          param_name: item.param_name,
        })),
    };
    try {
      await createRawDataReportConfig(payload);
      Notification.success({ title: 'New raw data report created' });
      setName('');
      setSelectedModuleValues([]);
      reloadData();
      onClose();
    } catch (error: any) {
      Notification.error({
        title: `Create raw data report error: ${error?.toString()}`,
      });
    }
  };

  const creatingIsDisabled = useMemo(() => !cp || !le, [cp, le]);

  return (
    <Panel
      title="Add raw data"
      action={
        <div className="d-flex">
          <div
            className={cn('pointer mr-1', { disabled: creatingIsDisabled })}
            onClick={handleCreateRaw}
          >
            <SaveIcon />
          </div>
          <div className="pointer" onClick={onClose}>
            <CloseIcon />
          </div>
        </div>
      }
    >
      <div className="text-left">
        <Input
          label="Name"
          className="mb-3"
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
        />
        <Autocomplete
          placeholder="Cp"
          className="w-100 mb-3"
          options={cpOptions}
          defaultValue={defaultValues?.cp ?? ''}
          onChange={(event, newValue: any) => {
            setCp(newValue?.value);
          }}
        />
        <Autocomplete
          placeholder="Legal Entity"
          className="w-100 mb-3"
          options={leOptions}
          defaultValue={defaultValues?.le ?? ''}
          onChange={(event, newValue: any) => {
            setLe(newValue?.value);
          }}
        />
        <Autocomplete
          placeholder="Module"
          className="w-100"
          options={parsingModuleOptions}
          onChange={(event, newValue: any) => {
            setModule(newValue?.value);
          }}
        />
        {isFileParam && (
          <div className="w-100 mt-3">
            <Autocomplete
              placeholder="File"
              className="w-100 mb-1"
              options={fileConfigOptions}
              onChange={(event, newValue: any) => {
                setFileConfigId(newValue?.value);
              }}
            />
            <IconButton
              iconColor="action"
              iconName="AddIcon"
              label="Add file rule"
              onClick={() => setCreateFileRuleIsOpened(true)}
            />
          </div>
        )}
        {selectedModuleParams?.map((item) => (
          <div className="my-3" key={item.id}>
            {item.description ? (
              <Tooltip title={item.description}>
                <ModuleItem
                  item={item}
                  onChangeValue={handleChangeValues}
                  values={selectedModuleValues}
                />
              </Tooltip>
            ) : (
              <ModuleItem
                item={item}
                onChangeValue={handleChangeValues}
                values={selectedModuleValues}
              />
            )}
          </div>
        ))}
      </div>
      <AddForm
        isOpen={createFileRuleIsOpened}
        setIsOpen={setCreateFileRuleIsOpened}
        reloadData={fetchParamsData}
        counterpartyId={cpList.find((item) => item.name === cp)?.id}
        legalEntityId={leList.find((item) => item.name === le)?.id}
        hideLeAndCp
      />
    </Panel>
  );
};
