import { ICellValue, IColumn } from 'react-ui-kit-exante';

import {
  editColumnWidth,
  minColumnWidth as minWidth,
  unknownCpOption,
} from 'constants/tables';
import { CpAndCTrade, CpAndCTradeWithRecStatus, SIDE } from 'services/recon';
import { ColumnsWithFilters, SelectOption } from 'types';
import { getCellCheckbox, getGeneralCheckbox, getSelectOptions } from 'utils';

type GetColumnsPayload = ColumnsWithFilters & {
  ccyOptions?: SelectOption[];
  cpOptions?: SelectOption[];
  modeOptions?: SelectOption[];
  trades: CpAndCTrade[];
  selectedTradesIds: number[];
  onCheckTrade: (item: CpAndCTrade) => void;
  onCheckAllTradesOnPage: () => void;
  onUnCheckAllTradesOnPage: () => void;
};

const recStatusOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];
const symbolOptions = getSelectOptions(['empty']);

export const getColumns = ({
  onFilter,
  onRemove,
  cpOptions,
  ccyOptions,
  modeOptions,
  trades,
  selectedTradesIds,
  onCheckTrade,
  onCheckAllTradesOnPage,
  onUnCheckAllTradesOnPage,
}:
  | GetColumnsPayload
  | Record<string, any>): IColumn<CpAndCTradeWithRecStatus>[] => [
  {
    accessor: 'id',
    width: 50,
    Header: () =>
      Array.isArray(trades)
        ? getGeneralCheckbox<CpAndCTrade>(
            trades,
            selectedTradesIds,
            onCheckAllTradesOnPage,
            onUnCheckAllTradesOnPage,
            (trade) => trade.id,
          )
        : '',

    Cell: ({ row }: ICellValue<CpAndCTrade>) =>
      Array.isArray(trades)
        ? getCellCheckbox<CpAndCTrade>(
            row.values.id,
            trades,
            selectedTradesIds,
            onCheckTrade,
            (trade) => trade.id,
          )
        : '',
    disableSortBy: true,
    disableFilters: true,
    className: 'd-block',
  },
  {
    Header: 'Mode',
    accessor: 'mode',
    minWidth,
    type: 'select',
    filterOptions: modeOptions ?? [],
    onFilter,
    onRemove,
  },
  {
    Header: 'CP',
    accessor: 'cpname',
    minWidth,
    editParams: {
      editable: (cell: ICellValue<CpAndCTradeWithRecStatus>) =>
        cell.row.original.side === SIDE.OUR,
      inputType: 'select',
      options: cpOptions ? [unknownCpOption, ...cpOptions] : [],
    },
    disableFilters: true,
  },
  {
    Header: 'CP Report Date',
    accessor: 'date',
    minWidth,
    width: editColumnWidth,
    editParams: {
      editable: true,
      inputType: 'date',
    },
    useLocale: false,
    type: 'dateRange',
    onFilter,
    onRemove,
  },
  {
    Header: 'Legal entity',
    accessor: 'legal_entity_name',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Rec Status',
    accessor: 'recstatus',
    minWidth,
    type: 'select',
    filterOptions: recStatusOptions,
    onFilter,
    onRemove,
    disableSortBy: true,
  },
  {
    Header: 'Account',
    accessor: 'account',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Bo symbol',
    accessor: 'bo_symbol',
    Cell: ({ row }: ICellValue<CpAndCTrade>) => (
      <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.bo_symbol}</span>
    ),
    minWidth,
    type: 'multiSelect',
    freeSolo: true,
    filterOptions: symbolOptions,
    onFilter,
    onRemove,
  },
  {
    Header: 'Cp symbol',
    accessor: 'cp_symbol',
    Cell: ({ row }: ICellValue<CpAndCTrade>) => (
      <span style={{ whiteSpace: 'pre-wrap' }}>{row.values.cp_symbol}</span>
    ),
    minWidth,
    type: 'multiSelect',
    freeSolo: true,
    filterOptions: symbolOptions,
    onFilter,
    onRemove,
  },
  {
    Header: 'Order ID',
    accessor: 'order_id',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Order Position',
    accessor: 'order_pos',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Qty',
    accessor: 'qty',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Price',
    accessor: 'price',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Trade Type',
    accessor: 'trade_type',
    minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Type Of Recon',
    accessor: 'type_of_recon',
    minWidth,
    disableFilters: true,
    disableSortBy: true,
  },
  {
    Header: 'Value',
    accessor: 'value',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Value Date',
    accessor: 'value_date',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Trade number',
    accessor: 'trade_number',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Ccy',
    accessor: 'ccy',
    minWidth,
    type: 'select',
    filterOptions: ccyOptions,
    onFilter,
    onRemove,
  },
  {
    Header: 'Recon Group Id',
    accessor: 'recon_group_id',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Execution Cp',
    accessor: 'execution_cp',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Exchange Order Id',
    accessor: 'exchange_order_id',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Trade Date',
    accessor: 'trade_date',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Delivery Date',
    accessor: 'delivery_date',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Settlement Cp',
    accessor: 'settlement_cp',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Mty',
    accessor: 'mty',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Comment',
    accessor: 'comment',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Cp Account',
    accessor: 'cp_account',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Is Manual',
    accessor: 'is_manual',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Chain ID',
    accessor: 'chain_id',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Internal Comment',
    accessor: 'internal_comment',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Pnl',
    accessor: 'pnl',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Type',
    accessor: 'type',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Isin',
    accessor: 'isin',
    minWidth,
    onFilter,
    onRemove,
  },
  {
    Header: 'Break category',
    accessor: 'break_report_category',
    minWidth,
    disableFilters: true,
  },
  {
    Header: 'Break comment',
    accessor: 'break_comment',
    minWidth,
    disableFilters: true,
  },
];
export const getAdditionalFilters = ({
  onFilter,
  onRemove,
  entityOptions,
  cpOptions,
}: ColumnsWithFilters & {
  entityOptions: SelectOption[];
  cpOptions: SelectOption[];
}) => {
  const columns: IColumn<Record<string, unknown>>[] = [
    {
      Header: 'Legal entity',
      accessor: 'le',
      type: 'select',
      onFilter,
      onRemove,
      filterOptions: entityOptions,
    },
    {
      Header: 'Cp',
      accessor: 'counterparty',
      type: 'select',
      onFilter,
      onRemove,
      filterOptions: [unknownCpOption, ...cpOptions],
    },
  ];
  return columns;
};
