import { Notification } from 'react-ui-kit-exante';

import { Pagination } from '../../types';
import { getMultiSortingParams, getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';

import {
  PositionReconciliationFieldTypes,
  PositionReconciliation,
  PositionMappingSearchResults,
  FetchPositionReconUnmappedPayload,
  FetchSearchMappingsForPositionPayload,
  PatchPositionBreakPayload,
  PostPositionBreakPayloadItem,
  PositionItem,
  FetchPositionsPayload,
} from './types';

export async function getListPositionRecFields() {
  return ReconApiService.getResource<PositionReconciliationFieldTypes[]>(
    'position-recon/action/get-position-reconciliation-fields/',
  );
}

export async function searchUnmappedPositions(
  payload: FetchPositionReconUnmappedPayload,
) {
  return ReconApiService.getResource<PositionReconciliation[]>(
    getUrlFromPayload('position-recon/action/search-unmapped/?', payload),
  );
}

export async function searchMappingsForPosition(
  payload: FetchSearchMappingsForPositionPayload,
) {
  return ReconApiService.getResource<PositionMappingSearchResults>(
    getUrlFromPayload(
      'position-recon/action/search-mapping-for-position-reconciliation/?',
      payload,
    ),
  );
}

export const getUrlForFetchPositions = (payload: FetchPositionsPayload) => {
  const {
    reportDate,
    leId,
    cpId,
    modeId,
    isShowAllPositions,
    paginationParams,
    filtersParams,
    sortingParams,
    isShowAllBreaks,
  } = payload;
  const { skip, page, ...otherPaginationParams } = paginationParams;
  const {
    break_category: breakCategory,
    abs_diff: absDiff,
    diff,
    ...otherFiltersParams
  } = filtersParams;
  const notEqualDiff = !isShowAllPositions ? 'not_eq_0' : undefined;
  const requestPayload: Record<string, unknown> = {
    ...otherPaginationParams,
    page_number: page,
    ...otherFiltersParams,
    break_report_category: breakCategory,
    report_date:
      reportDate[0] +
      (!reportDate[1] || !filtersParams.symbol_id ? '' : `:${reportDate[1]}`),
    legal_entity_id: leId,
    counterparty_id: cpId,
    mode_id: modeId,
    all_breaks: isShowAllBreaks,
  };
  if (absDiff) {
    requestPayload.abs_diff = absDiff;
  } else {
    requestPayload.diff = diff ?? notEqualDiff;
  }

  return `${getUrlFromPayload(
    'position-recon/get-bo-cp-positions?',
    requestPayload,
  )}order_by=${getMultiSortingParams(sortingParams)}`;
};

export async function fetchPositions(payload: FetchPositionsPayload) {
  try {
    const response = await ReconApiService.getResource<{
      results: PositionItem[];
      pagination: Pagination;
    }>(getUrlForFetchPositions(payload));

    return {
      positions: response.results.map((item) => ({
        ...item,
        id: `${item.report_date}_${item.legal_entity}_${item.symbol_id}_${item.counterparty}`,
      })) as PositionItem[],
      pagination: response.pagination,
    };
  } catch (error) {
    Notification.error({
      title: 'Fetch positions error',
      description: JSON.stringify(error),
    });
  }
  return {
    positions: [],
    pagination: {} as Pagination,
  };
}

export async function postPositionBreak(
  payload: PostPositionBreakPayloadItem[],
) {
  return ReconApiService.postResource<
    PostPositionBreakPayloadItem[],
    PostPositionBreakPayloadItem[]
  >('position-recon/position-breaks/bulk_create/', payload);
}

export async function patchPositionBreak(payload: PatchPositionBreakPayload) {
  return ReconApiService.patch<PatchPositionBreakPayload>(
    'position-recon/position-breaks/bulk_patch/',
    payload,
  );
}
