import dayjs from 'dayjs';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CalendarDatePicker,
  useTableData,
  Tabs,
  Tab,
  IconButton,
  IOnFetchArguments,
  Modal,
  Input,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { DATE_FORMAT } from 'constants/date';
import { useAppSelector } from 'hooks';
import { ReactComponent as GrayPalmIcon } from 'icons/GrayPalm.svg';
import { getCpDataMonitor, Types, Stat, SubRow } from 'services/recon';
import { TableDataWithPagination } from 'types';
import { getSelectOptions, getWorkYesterday } from 'utils';

import { Checkbox } from './components';
import { dataTypesBySegments } from './constants';
import { useHolidayConfirmation } from './hooks';
import { ByFieldNames } from './models';
import {
  HeadDiv,
  StylesToggleButtonGroup,
  CheckboxesBlock,
  Table,
} from './styled';
import { getColumns, getAdditionalFilters } from './utils/getColumns';
import { getConfirmIndicatingCondition } from './utils/getConfirmIndicatingCondition';
import { getMapDataForTreeTable } from './utils/getMapDataForTreeTable';

export const CpDataMonitor: FC = () => {
  const cpTypes = useAppSelector((state) => state.cpTypes);
  const leTypeOptions = useMemo(
    () => getSelectOptions(cpTypes, 'name', 'name'),
    [cpTypes],
  );
  const [dateFilter, setDateFilter] = useState(getWorkYesterday());
  const [dataSegment, setDataSegment] = useState(0);
  const [dataTypes, setDataTypes] = useState<Types[]>([Types.Transactions]);
  const [byFieldName, setByFieldName] = useState<ByFieldNames>(
    ByFieldNames.LegalEntity,
  );
  useEffect(() => {
    setDataTypes(dataTypesBySegments[dataSegment]);
  }, [dataSegment]);

  const getTableData = useCallback(
    (props: IOnFetchArguments) =>
      getCpDataMonitor(dateFilter, dataTypes, props.filtersParams),
    [dateFilter, dataTypes],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTableData },
      filters: {
        getDefaultFilters: () => ({
          legal_entity_type: ['Broker', 'Bank'],
        }),
      },
    }),
    [getTableData],
  );

  const {
    data,
    isLoading,
    resetFilters,
    filters,
    removeFilter,
    setFilter,
    fetchData,
  } = useTableData<TableDataWithPagination<Stat>>(tableData);

  const mappedItems = useMemo(
    () => getMapDataForTreeTable(data?.items ?? [], byFieldName),
    [data, byFieldName],
  );

  const columns = useMemo(() => getColumns(dataSegment === 2), [dataSegment]);

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters: getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        leTypeOptions,
      }),
    }),
    [filters, resetFilters, setFilter, removeFilter, leTypeOptions],
  );
  const handleChangeDate = (date: Date | null) => {
    setDateFilter(dayjs(date).format(DATE_FORMAT));
  };
  const formattedDate = useMemo(() => new Date(dateFilter), [dateFilter]);

  const handleChangeDataSegment = (
    _: ChangeEvent<unknown>,
    newValue: number,
  ) => {
    setDataSegment(newValue);
  };

  const handleSetNextDate = () => {
    if (!dayjs().isSame(dateFilter, 'day')) {
      setDateFilter(dayjs(dateFilter).add(1, 'day').format(DATE_FORMAT));
    }
  };
  const handleSetPrevDate = () => {
    setDateFilter(dayjs(dateFilter).subtract(1, 'day').format(DATE_FORMAT));
  };

  const {
    isOpened: confirmModalIsOpened,
    handleCloseModal,
    handleOpenConfirmModal,
    comment: confirmComment,
    handleConfirm,
    handleChangeComment,
  } = useHolidayConfirmation({ reportDate: dateFilter, reloadData: fetchData });

  const showingConfirmButtonIndication = useMemo(
    () => getConfirmIndicatingCondition(byFieldName),
    [byFieldName],
  );

  const headComponent = (
    <HeadDiv>
      <Tabs value={dataSegment} onChange={handleChangeDataSegment}>
        <Tab label="Cash Flow" />
        <Tab label="Postion Flow" />
        <Tab label="Other" />
      </Tabs>
      <div className="d-flex">
        <div className="w-50">
          <div className="d-flex align-items-end ">
            <CheckboxesBlock>
              {dataSegment <= 1 &&
                dataTypesBySegments[dataSegment].map((type) => (
                  <Checkbox
                    key={type}
                    dataTypes={dataTypes}
                    type={type}
                    setDataTypes={setDataTypes}
                  />
                ))}
            </CheckboxesBlock>
          </div>
          <StylesToggleButtonGroup
            exclusive
            label=""
            onChange={(value) => setByFieldName(value as ByFieldNames)}
            options={[
              {
                label: 'Legal Entity',
                value: ByFieldNames.LegalEntity,
                style: {
                  borderTopLeftRadius: '14px',
                  borderBottomLeftRadius: '14px',
                },
              },
              {
                label: 'Counterparty',
                value: ByFieldNames.Counterparty,
                style: {
                  borderTopRightRadius: '14px',
                  borderBottomRightRadius: '14px',
                },
              },
            ]}
            value={byFieldName}
          />
        </div>
        <div className="w-50 d-flex justify-content-end align-items-center">
          <div className="d-flex align-items-center">
            <p className="m-0 mr-3">
              <IconButton
                iconColor="action"
                iconName="ArrowLeftSmallIcon"
                label={dateFilter}
                onClick={handleSetPrevDate}
              />
              <IconButton
                iconColor="action"
                iconName="ArrowRightSmallIcon"
                onClick={handleSetNextDate}
              />
            </p>
            <CalendarDatePicker
              disableFuture
              onChange={handleChangeDate}
              value={formattedDate}
            />
          </div>
        </div>
      </div>
    </HeadDiv>
  );

  return (
    <div className="mui-container-fluid text-left">
      <div className="mui-row">
        <div className="mui-col-md-12">
          <Table
            tableId="cpDataMonitorTree"
            title="CP Data Monitor"
            manualSortBy
            isFlexLayout
            showScrollbar
            customHeadComponent={headComponent}
            expanded={{
              listOfInitialExpandedRowKeys: {
                0: true,
              },
            }}
            rowActions={{
              show: true,
              isEditedRow: () => false,
              additionalActions: [
                {
                  show: showingConfirmButtonIndication,
                  label: <GrayPalmIcon />,
                  width: 100,
                  title: 'Confirm',
                  onClick: (raw: SubRow) => {
                    if (raw.name) {
                      handleOpenConfirmModal(raw.name);
                    }
                  },
                },
              ],
            }}
            hasFilters
            data={mappedItems}
            columns={columns}
            isLoading={isLoading}
            filteringProps={filteringProps}
            filtersExpanded={false}
            locale={defaultLocale}
            defaultSortBy={[]}
            noDataHeight="auto"
          />
        </div>
        <Modal
          isOpened={confirmModalIsOpened}
          title="No file Confirmation"
          onClose={handleCloseModal}
          confirmButton={{
            confirmButtonName: 'Confirm',
            handleConfirm,
            confirmButtonIsDisabled: !confirmComment,
          }}
        >
          <p className="my-0">Do you confirm that there will be no file?</p>
          <Input
            label="Comment"
            fullWidth
            value={confirmComment}
            onChange={handleChangeComment}
          />
        </Modal>
      </div>
    </div>
  );
};
