import { cloneDeep } from 'lodash';
import React, { createContext, FC, ReactNode, useContext } from 'react';

import { BulkActionsProps } from '../types';

const BulkActionsContext = createContext<BulkActionsProps | undefined>(
  undefined,
);

export const useBulkActions = () => {
  const context = useContext(BulkActionsContext);
  if (!context) {
    throw new Error('useBulkActions must be used within a BulkActionsProvider');
  }
  return context;
};

export const BulkActionsProvider: FC<{
  children: ReactNode;
  bulkActions: BulkActionsProps;
}> = ({ children, bulkActions }) => {
  const value = cloneDeep(bulkActions);

  return (
    <BulkActionsContext.Provider value={value}>
      {children}
    </BulkActionsContext.Provider>
  );
};
