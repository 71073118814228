import dayjs from 'dayjs';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import { DEFAULT_FILTER_VALUES } from 'constants/tables';
import {
  Transaction,
  TransactionOperationType,
  getTransactionOperationTypeListForPage,
} from 'services/recon';
import { currentUserSelector } from 'store/reducers/commonReducer';
import { getSelectOptions, getDefaultFilterParams } from 'utils';

import { useAppSelector } from '../../hooks';

import { getTransactionsTableColumns } from './utils';

const applyObject = {
  reportdate: [dayjs().subtract(7, 'day').toDate(), dayjs().toDate()],
  counterparty: DEFAULT_FILTER_VALUES.cp,
  mode: DEFAULT_FILTER_VALUES.mode,
  operationtype: 'FUNDING/WITHDRAWAL',
};
export const useUnionTransactionsReconcile = ({
  isReconciledPage,
}: {
  isReconciledPage: boolean;
}) => {
  const filtersIsFirstMounted = useRef(false);

  const currentUser = useSelector(currentUserSelector);
  const breakCategories = useAppSelector((state) => state.breakCategories);
  const [operationTypes, setOperationTypes] = useState<
    TransactionOperationType[]
  >([]);
  const [reconciledUpdateCount, setReconciledUpdateCount] = useState(0);
  const [selectedTransactions, setSelectedTransactions] = useState<
    Transaction[]
  >([]);

  useEffect(() => {
    if (currentUser?.name) {
      getTransactionOperationTypeListForPage('transactions-reconcile')
        .then((response) => {
          if (response.results) {
            setOperationTypes(response.results);
          }
        })
        .catch(() => {
          Notification.error({
            title: 'Fetch TransactionTypes error',
          });
        });
    }
  }, [currentUser]);

  const handleCheckTransaction = useCallback(
    (transaction: Transaction) => {
      if (selectedTransactions.find((i) => i.id === transaction.id)) {
        setSelectedTransactions([
          ...selectedTransactions.filter((i) => i.id !== transaction.id),
        ]);
      } else {
        setSelectedTransactions([...selectedTransactions, transaction]);
      }
    },
    [selectedTransactions, setSelectedTransactions],
  );

  const filterKeys = getTransactionsTableColumns({}, isReconciledPage).map(
    (column) => column.accessor,
  );
  const tableId = isReconciledPage
    ? 'DivideReconciledTransactions'
    : 'MixedTransactions';
  const prepareFiltersForParams = useCallback(
    getDefaultFilterParams({
      filterKeys,
      applyObject,
      isApply: filtersIsFirstMounted,
      shouldApply: !window.location.search,
    }),
    [],
  );

  const reloadReconciledTable = useCallback(() => {
    setReconciledUpdateCount(reconciledUpdateCount + 1);
  }, [setReconciledUpdateCount, reconciledUpdateCount]);

  const breakCategoryOptions = useMemo(
    () => [...getSelectOptions(breakCategories, 'name', 'name')],
    [breakCategories],
  );
  const breakCategoryObject = useMemo(
    () =>
      breakCategories.reduce(
        (acc: Record<string, string>, item) => ({
          ...acc,
          [item.name]: String(item.id),
        }),
        {},
      ),
    [breakCategories],
  );

  return {
    selectedTransactions,
    setSelectedTransactions,
    handleCheckTransaction,
    operationTypes,
    prepareFiltersForParams,
    reconciledUpdateCount,
    reloadReconciledTable,
    breakCategoryOptions,
    breakCategoryObject,
    tableId,
  };
};
