import classNames from 'classnames';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Table, Panel, CenteredLoader, ICellValue } from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import { useAppSelector } from 'hooks';
import { MappingReport } from 'services/recon';
import { theme } from 'theme';

import { NewRawData } from '../RawData/NewRawData/NewRawData';
import { UpdateRawData } from '../RawData/UpdateRawData/UpdateRawData';

import styles from './SetupEtl.module.css';
import { MappingReportsTable } from './components/MappingReportsTable';
import {
  CreateMapping,
  CloneMapping,
} from './components/MappingReportsTable/components';
import { RawDataTable } from './components/RawDataTable';
import { columns } from './consts';
import { useSetupsList } from './hooks';
import { compareCp, getGetRowProps } from './utils';

const initialFormsVisibility = {
  createRawData: false,
  updateRawData: false,
  createMapping: false,
  cloneMapping: false,
};

export const SetupEtl: FC = () => {
  const cpList = useAppSelector((state) => state.new_counterparty_list);
  const leList = useAppSelector((state) => state.new_legal_entity_list);
  const { setupList, calculatedData, loadSetupLists } = useSetupsList();
  const [reportIdForCloning, setReportIdForCloning] =
    useState<null | MappingReport>(null);

  const [selectedCp, setSelectedCp] = useState<string>('');
  const [selectedLe, setSelectedLe] = useState<string>('');
  const [selectedRawDataName, setSelectedRawDataName] = useState<string>('');
  const [formsVisibility, setFormsVisibility] = useState(
    initialFormsVisibility,
  );

  const selectedCpObject = useMemo(
    () => cpList.find((item) => item.name === selectedCp),
    [selectedCp, cpList],
  );
  const selectedLeObject = useMemo(
    () => leList.find((item) => item.name === selectedLe),
    [selectedLe, leList],
  );
  const selectedRawDataObject = useMemo(
    () => setupList.find((item) => item.name === selectedRawDataName),
    [setupList, selectedRawDataName],
  );

  const rawDataItems = useMemo(
    () =>
      setupList.filter(
        (item) =>
          compareCp(item.cp, item.counterparty, selectedCp) &&
          item.legal_entity === selectedLe,
      ),
    [selectedLe, selectedCp, setupList],
  );

  const selectedMappings = useMemo(
    () =>
      setupList.find((item) => item.name === selectedRawDataName)
        ?.related_mapping ?? [],
    [setupList, selectedRawDataName],
  );

  useEffect(() => {
    loadSetupLists();
  }, []);

  const handleCellClick = (
    cell: ICellValue<{
      name: string;
      leName?: string;
      cpName?: string;
      rawDataName?: string;
    }>,
  ) => {
    setFormsVisibility(initialFormsVisibility);
    const { name, leName, cpName, rawDataName } = cell.row.original;
    if (name && leName && !rawDataName) {
      setSelectedCp(name);
      setSelectedLe(leName);
      setSelectedRawDataName('');
    }
    if (name && leName && cpName && !rawDataName) {
      setSelectedRawDataName(name);
      setSelectedCp(cpName);
      setSelectedLe(leName);
    }
  };

  const handleShowMappingReports = (setupName: string) => {
    setSelectedRawDataName(setupName);
  };

  const getHandlerForShowingForms = (fieldName: string, value: boolean) => () =>
    setFormsVisibility({ ...formsVisibility, [fieldName]: value });

  const handleCloseCloneModal = () => {
    setReportIdForCloning(null);
    setFormsVisibility({ ...formsVisibility, cloneMapping: false });
  };

  const getVisibleTable = () => {
    if (selectedCp && selectedLe && selectedRawDataName) {
      return (
        <MappingReportsTable
          fetchData={loadSetupLists}
          cp={selectedCp}
          le={selectedLe}
          rawDataSetup={selectedRawDataName}
          rawDataSetupId={selectedRawDataObject?.id ?? 0}
          mappings={selectedMappings}
          onOpenCreating={getHandlerForShowingForms('createMapping', true)}
          onOpenCloning={(value) => {
            setFormsVisibility({ ...formsVisibility, cloneMapping: true });
            setReportIdForCloning(value);
          }}
        />
      );
    }
    if (selectedCp && selectedLe) {
      return (
        <RawDataTable
          cp={selectedCp}
          le={selectedLe}
          items={rawDataItems}
          onShowMappingReports={handleShowMappingReports}
          onOpenCreating={getHandlerForShowingForms('createRawData', true)}
          onOpenUpdating={(name: string) => {
            setFormsVisibility({ ...formsVisibility, updateRawData: true });
            setSelectedRawDataName(name);
          }}
        />
      );
    }
    return null;
  };

  const formIsVisible = useMemo(
    () => Object.values(formsVisibility).some((item) => item),
    [formsVisibility],
  );
  const getVisibleForm = () => {
    if (formsVisibility.updateRawData && selectedRawDataObject) {
      return (
        <UpdateRawData
          reloadData={loadSetupLists}
          onClose={getHandlerForShowingForms('updateRawData', false)}
          setup={selectedRawDataObject}
        />
      );
    }
    if (formsVisibility.createRawData) {
      return (
        <NewRawData
          reloadData={loadSetupLists}
          onClose={getHandlerForShowingForms('createRawData', false)}
          defaultValues={{ cp: selectedCp, le: selectedLe }}
        />
      );
    }
    if (formsVisibility.createMapping) {
      return (
        <CreateMapping
          leId={selectedLeObject?.id ?? 0}
          le={selectedLeObject?.name ?? ''}
          cpId={selectedCpObject?.id ?? 0}
          cp={selectedCpObject?.name ?? ''}
          reloadData={loadSetupLists}
          onClose={getHandlerForShowingForms('createMapping', false)}
          rawDataSetup={selectedRawDataObject?.name ?? ''}
          rawDataSetupId={selectedRawDataObject?.id ?? 0}
        />
      );
    }

    if (formsVisibility.cloneMapping && reportIdForCloning) {
      return (
        <CloneMapping
          onClose={handleCloseCloneModal}
          cp={selectedCpObject?.name ?? ''}
          le={selectedLeObject?.name ?? ''}
          rawDataList={setupList}
          reloadData={loadSetupLists}
          report={reportIdForCloning}
          setRawDataName={setSelectedRawDataName}
          rawDataId={selectedRawDataObject?.id ?? 0}
        />
      );
    }

    return null;
  };

  const getRowProps = useCallback(
    getGetRowProps({ selectedCp, selectedLe, selectedRawDataName }),
    [selectedLe, selectedCp, selectedRawDataName],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<Record<string, any>>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );

  return setupList.length ? (
    <Panel>
      <div
        className={classNames(
          styles.MonitorContainer,
          'SetupEtlContainer',
          'text-left',
        )}
      >
        <div className={styles.MonitorTableBlock}>
          <Table
            getCellProps={getCellProps}
            getRowProps={getRowProps}
            tableId="setupsTree"
            title="Setup Etl"
            isFlexLayout
            locale={defaultLocale}
            columns={columns}
            data={calculatedData}
            handleCellClick={handleCellClick}
            expanded={{
              listOfInitialExpandedRowKeys: {
                0: true,
              },
            }}
          />
        </div>
        <div className={styles.AnotherTablesBlock}>
          {formIsVisible ? getVisibleForm() : getVisibleTable()}
        </div>
      </div>
    </Panel>
  ) : (
    <CenteredLoader />
  );
};
