import { IOnFetchArguments, Notification } from 'react-ui-kit-exante';

import { ResponseWithPagination } from '../../types';
import { getMultiSortingParams, getUrlFromPayload } from '../../utils';
import { ReconApiService } from '../api';
import { CpDataMonitorItemStatuses } from '../types';

import { CpStatusItem, CpStatusMonitorAdditionalFilters } from './types';

export enum Types {
  // Cash Flow
  Transactions = 'transactions',
  Cash = 'cash',
  // Position Flow
  Trades = 'trades',
  Positions = 'positions',
  // Others
  Other = 'other',
}
export enum Process {
  Ftp = 'ftp',
  Raw = 'raw',
  Normalized = 'normalized',
}

export interface Stat {
  file_id: number;
  raw_data_id: number;
  counterparty_name: string;
  legal_entity_name: string;
  source: string;
  process: Process;
  status: CpDataMonitorItemStatuses;
  type: Types;
  updated_at: string;
}

export interface SubRow {
  name?: string;
  subName?: Types | string;
  [Process.Ftp]?: CpDataMonitorItemStatuses;
  [Process.Raw]?: CpDataMonitorItemStatuses;
  [Process.Normalized]?: CpDataMonitorItemStatuses;
  ftp_updated_at?: string;
  raw_updated_at?: string;
  normalized_updated_at?: string;
}
export interface StatsRecord {
  name?: string;
  [Process.Ftp]?: CpDataMonitorItemStatuses;
  [Process.Raw]?: CpDataMonitorItemStatuses;
  [Process.Normalized]?: CpDataMonitorItemStatuses;
  isHoliday?: boolean;
  subRows?: {
    name?: string;
    [Process.Ftp]?: CpDataMonitorItemStatuses;
    [Process.Raw]?: CpDataMonitorItemStatuses;
    [Process.Normalized]?: CpDataMonitorItemStatuses;
    subRows?: SubRow[];
    isHoliday?: boolean;
  }[];
}

export async function getCpDataMonitor(
  date: string,
  types: Types[],
  filters: Record<string, unknown>,
) {
  try {
    const statsResponse = await ReconApiService.getResource<Stat[]>(
      getUrlFromPayload('monitoring/stats/?', {
        report_date: date,
        type: types,
        legal_entity_type: filters.legal_entity_type,
      }),
    );

    return {
      items: statsResponse,
      pagination: { total: 0 },
    };
  } catch (error) {
    Notification.error({
      title: 'Get file monitoring stats error',
      description: JSON.stringify(error, null, 2),
    });
  }
  return {
    items: [],
    pagination: { total: 0 },
  };
}

export async function getCpStatuses(
  { params }: IOnFetchArguments,
  { date, cpFilter, leFilter, process }: CpStatusMonitorAdditionalFilters,
) {
  try {
    const payload = {
      page: params.page + 1,
      limit: params.limit,
      report_date: date,
      cp: cpFilter,
      le: leFilter,
      process,
    };
    const response = await ReconApiService.getResource<
      ResponseWithPagination<CpStatusItem>
    >(
      `${getUrlFromPayload(
        'monitoring/cp-statuses/?',
        payload,
      )}order=${getMultiSortingParams(params.sorting)}`,
    );
    return {
      items: response.content,
      pagination: response.pagination,
    };
  } catch (e) {
    Notification.error({ title: 'Get cp statuses error' });
  }
  return {
    items: [],
    pagination: { total: 0 },
  };
}

interface ConfirmNoFilePayload {
  counterparty_id: number | null;
  report_date: string;
  comment: string;
}
export async function confirmNoFile(payload: ConfirmNoFilePayload) {
  return ReconApiService.postResource<ConfirmNoFilePayload, { id: number }>(
    'monitoring/make_holiday/',
    payload,
  );
}
