import { useCallback, useMemo, FC, useEffect } from 'react';
import {
  Table,
  IOnFetchArguments,
  useTableData,
  ISortBy,
  OnSaveEditableRow,
  Notification,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import {
  DEFAULT_FILTER_VALUES,
  defaultOrdering,
  pageSizes,
} from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import { patchTradeError } from 'pages/ManualReconcileUnion/consts';
import { CpAndCTrade, getCpTrades, patchCpAndCTrade } from 'services/recon';
import {
  cpListByEntitySelector,
  modeNames,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { calculateCountOfPages } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { useManualReconcileUnion } from './hooks';
import { getAdditionalColumns, getColumns } from './utils/getColumns';

export const CpTrades: FC = () => {
  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const modeNamesList = useAppSelector(modeNames);

  const { prepareFiltersForParams } = useManualReconcileUnion();
  const getTrades = useCallback(
    (props: IOnFetchArguments) => getCpTrades(props),
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTrades },
      saveViewParamsAfterLeave: true,
      filters: {
        prepareFiltersForParams,
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getTrades],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading: isLoadingCpBo,
    fetchData,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
  } = useTableData<{
    trades: CpAndCTrade[];
    pagination: { total: number };
  }>(tableData);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.le as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);
  useEffect(() => {
    if (legalEntityNames.length && !filters.le) {
      setFilter('le', legalEntityNames[0]);
    }
  }, [legalEntityNames]);

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions: getSelectOptions(legalEntityNames),
        cpOptions,
      }),
    [setFilter, removeFilter, cpOptions, legalEntityNames],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const columns = useMemo(
    () =>
      getColumns({
        modeNames: modeNamesList,
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [data, modeNamesList, cpOptions],
  );

  const handleSaveEditableRow: OnSaveEditableRow<CpAndCTrade> = async (
    previousValues,
    updatedValues,
  ) => {
    try {
      await patchCpAndCTrade({
        id: previousValues.id,
        side: previousValues.side,
        type_of_trade: updatedValues.trade_type,
      });
      Notification.success({ title: 'Trade updated success' });
      await fetchData();
    } catch (e) {
      if (String(e).includes(patchTradeError.sourceFileIsNotRecon)) {
        Notification.error({
          title:
            'You cannot change any field for this record (field "source" not equal "UI")',
        });
      } else {
        Notification.error({ title: 'Update trade error' });
      }
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Table
            title="Cp trades"
            tableId="CpTrades"
            showTableInfo
            hasPagination
            hasFilters
            locale={defaultLocale}
            manualSortBy
            isFlexLayout
            showScrollbar
            saveColumnOrder
            saveViewParamsAfterLeave
            data={data?.trades ?? []}
            columns={columns}
            isLoading={isLoadingCpBo}
            pageSizes={pageSizes}
            filteringProps={filteringProps}
            onSort={handleSorting}
            defaultSortBy={defaultOrdering}
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination.total || 0,
              setPage,
              setPageSize: setLimit,
            }}
            rowActions={{
              show: true,
              onSave: handleSaveEditableRow,
            }}
          />
        </div>
      </div>
    </div>
  );
};
