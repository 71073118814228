import {
  Context,
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { isEditableRowValuesContext } from './EditableContext.helpers';
import {
  EditableRowIdContextType,
  EditableRowValuesContextType,
  SetAllEditableRowValues,
  TNullableContext,
  UpdateRowValue,
} from './EditableContext.types';

const initialContextValue = {};
const NULLABLE_CONTEXT: TNullableContext = { Consumer: null, Provider: null };

const EditableRowIdContext = createContext<EditableRowIdContextType>(
  initialContextValue as EditableRowIdContextType,
);

let EditableRowValuesContext: Context<EditableRowValuesContextType<any>>;
let SetAllEditableValuesContext: Context<SetAllEditableRowValues<any>>;

export const createEditableRowValuesContext = <Data extends object>() => {
  EditableRowValuesContext = createContext<EditableRowValuesContextType<Data>>(
    initialContextValue as EditableRowValuesContextType<Data>,
  );

  SetAllEditableValuesContext = createContext<SetAllEditableRowValues<Data>>(
    () => {},
  );
};

type EditableRowIdContextProviderProps = {
  children: ReactNode;
};

export const EditableRowIdContextProvider: FC<
  EditableRowIdContextProviderProps
> = ({ children }) => {
  const [editableRowId, setEditableRowId] = useState('');

  const value: EditableRowIdContextType = useMemo(
    () => ({
      editableRowId,
      setEditableRowId: (newId) => setEditableRowId(newId),
    }),
    [editableRowId],
  );

  return (
    <EditableRowIdContext.Provider value={value}>
      {children}
    </EditableRowIdContext.Provider>
  );
};

export const EditableRowValuesContextProvider = <Data extends object>({
  children,
}: {
  children?: ReactElement | undefined;
}) => {
  const [editableRowValues, setEditableRowValues] = useState<
    Data | Record<string, unknown>
  >({});

  const setAllEditableRowValues: SetAllEditableRowValues<Data> = useCallback(
    (values) => {
      setEditableRowValues(values);
    },
    [],
  );

  const updateRowValue: UpdateRowValue<Data> = useCallback((key, value) => {
    setEditableRowValues((prev) => ({
      ...prev,
      [key]: value,
    }));
  }, []);

  const value = useMemo(
    () => ({
      editableRowValues,
      updateRowValue,
      setAllEditableRowValues,
    }),
    [editableRowValues, updateRowValue, setAllEditableRowValues],
  );

  if (!EditableRowValuesContext) {
    return children;
  }

  return (
    <SetAllEditableValuesContext.Provider value={setAllEditableRowValues}>
      <EditableRowValuesContext.Provider value={value}>
        {children}
      </EditableRowValuesContext.Provider>
    </SetAllEditableValuesContext.Provider>
  );
};

export const useEditableRowId = () => {
  const contextValue = useContext(EditableRowIdContext);

  return contextValue;
};

export const useEditableRowValuesContext = <Data extends object>() => {
  const contextValue = useContext(EditableRowValuesContext || NULLABLE_CONTEXT);

  if (isEditableRowValuesContext(contextValue)) {
    return contextValue;
  }

  return initialContextValue as EditableRowValuesContextType<Data>;
};

export const useSetAllEditableValuesContext = <Data extends object>() => {
  const contextValue = useContext(
    SetAllEditableValuesContext || NULLABLE_CONTEXT,
  );

  return contextValue as SetAllEditableRowValues<Data>;
};
