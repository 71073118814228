import { Popover, PopoverProps, TextFieldProps } from '@mui/material';
import {
  LocalizationProvider,
  StaticDatePicker,
  StaticDatePickerProps,
} from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import React, { useCallback, useState } from 'react';

import { DefaultThemeProvider } from 'theme';

import { getDateLocale } from '../../../../helpers/getDateLocale';
import { CalendarIcon } from '../../../Icons';
import { CALENDAR_DATE_PICKER_DATA_TEST_ID } from '../constants';
import { getCalendarStyles } from '../helpers';

import {
  CalendarButton,
  CalendarWrapper,
  TodayButton,
} from './CalendarDatePicker.styled';
import { RenderInput } from './RenderInput';
import { TOnTodayButton } from './types';

export interface ICalendarDatePickerProps
  extends Omit<StaticDatePickerProps<Date, Date>, 'renderInput'> {
  renderInput?: (props: TextFieldProps) => React.ReactElement;
  iconTestId?: string;
  iconClassName?: string;
  showTodayButton?: boolean;
  todayButtonText?: string;
  onTodayButton?: TOnTodayButton;
  locale?: string;
}

/**
 * This component is deprecated.
 * Use `ButtonDatePicker` instead.
 *
 * @deprecated
 */
export const CalendarDatePicker = ({
  value: initialDate,
  renderInput = RenderInput,
  onChange,
  iconClassName,
  iconTestId = CALENDAR_DATE_PICKER_DATA_TEST_ID,
  showTodayButton = false,
  todayButtonText = 'Today',
  locale = 'en-us',
  onTodayButton,
  ...rest
}: ICalendarDatePickerProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [date, setDate] = useState<Date | null>(initialDate);

  const handleClosePopper = useCallback(() => setAnchorEl(null), []);
  const handleCalendarClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl((prevAnchor) => (prevAnchor ? null : event.currentTarget));
    },
    [],
  );
  const handleDateChange = (newDate: Date | null) => setDate(newDate);
  const handleAccept = useCallback(
    (newValue: Date) => {
      onChange(newValue);
      handleClosePopper();
    },
    [handleClosePopper, onChange],
  );
  const handleToday = useCallback(() => {
    if (onTodayButton) {
      onTodayButton({
        closePopper: handleClosePopper,
        setDate,
      });

      return;
    }

    const today = new Date();

    setDate(today);
    onChange(today);
    handleClosePopper();
  }, [handleClosePopper, onChange, onTodayButton]);

  const open = Boolean(anchorEl);
  const id = open ? 'CalendarDatePicker' : undefined;

  return (
    <DefaultThemeProvider>
      <CalendarButton
        data-test-id={iconTestId}
        aria-describedby={id}
        type="button"
        onClick={handleCalendarClick}
      >
        <CalendarIcon
          dataTestId="CalendarDatePicker"
          className={iconClassName}
        />
      </CalendarButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopper}
        sx={getCalendarStyles as PopoverProps['sx']}
      >
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={getDateLocale(locale)}
        >
          <CalendarWrapper>
            <StaticDatePicker
              {...rest}
              displayStaticWrapperAs="desktop"
              onChange={handleDateChange}
              value={date}
              renderInput={renderInput}
              onAccept={handleAccept}
            />

            {showTodayButton && (
              <TodayButton
                onClick={handleToday}
                variant="outlined"
                size="small"
              >
                {todayButtonText}
              </TodayButton>
            )}
          </CalendarWrapper>
        </LocalizationProvider>
      </Popover>
    </DefaultThemeProvider>
  );
};
