import { useCallback, useMemo, FC, useEffect } from 'react';
import {
  Table,
  IOnFetchArguments,
  useTableData,
  ISortBy,
  IRow,
  ICellValue,
} from 'react-ui-kit-exante';

import { defaultLocale } from 'constants/app';
import {
  DEFAULT_FILTER_VALUES,
  defaultOrdering,
  pageSizes,
} from 'constants/tables';
import { useAppSelector, usePropSelector } from 'hooks/redux';
import { CpAndCTrade, getCpTrades, SIDE } from 'services/recon';
import {
  cpListByEntitySelector,
  modeNames,
  newCounterPartyNames,
  newLegalEntityNames,
} from 'store/reducers/commonReducer';
import { theme } from 'theme';
import { calculateCountOfPages } from 'utils';
import { getSelectOptions } from 'utils/getSelectOptions';

import { useAutoReconcile, usePairsLogic } from './hooks';
import { TradesTable } from './types';
import { getAdditionalColumns, getColumns } from './utils/getColumns';

export const AutoReconcile: FC = () => {
  const breakCategories = useAppSelector((state) => state.breakCategories);
  const legalEntityNames = useAppSelector(newLegalEntityNames);
  const counterPartyNames = useAppSelector(newCounterPartyNames);
  const modeNamesList = useAppSelector(modeNames);

  const { prepareFiltersForParams } = useAutoReconcile();
  const getTrades = useCallback(
    (props: IOnFetchArguments) =>
      getCpTrades({
        ...props,
        filtersParams: {
          ...props.filtersParams,
          type_of_trade: 'Trade',
          reconciled: false,
        },
      }),
    [],
  );

  const tableData = useMemo(
    () => ({
      data: { onFetch: getTrades },
      saveViewParamsAfterLeave: true,
      filters: {
        prepareFiltersForParams,
      },
      pagination: { getDefaultPagination: () => ({ limit: 10, skip: 0 }) },
    }),
    [getTrades],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    filters,
    setSorting,
    fetchData,
  } = useTableData<TradesTable>(tableData);

  const cpListByEntity = usePropSelector(
    cpListByEntitySelector,
    (filters.le as string) ?? DEFAULT_FILTER_VALUES.le,
  );
  const cpOptions = useMemo(
    () => getSelectOptions(cpListByEntity?.map((item) => item.name) ?? []),
    [cpListByEntity],
  );
  useEffect(() => {
    if (cpOptions.length && filters.counterparty) {
      const foundCp = cpOptions.find(
        (item) => item.value === filters.counterparty,
      );
      if (!foundCp) {
        setFilter('counterparty', cpOptions[0].value);
      }
    }
  }, [cpOptions]);
  useEffect(() => {
    if (legalEntityNames.length && !filters.le) {
      setFilter('le', legalEntityNames[0]);
    }
  }, [legalEntityNames]);

  const {
    handleSearchPair,
    pairColumns,
    activeTradeId,
    foundPairs,
    pairsIsLoading,
    handleReconcile,
  } = usePairsLogic({ reloadCpTrades: fetchData });

  const pageCount = useMemo(
    () => calculateCountOfPages(data?.pagination.total || 0, limit),
    [limit, data?.pagination.total],
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalColumns({
        onFilter: setFilter,
        onRemove: removeFilter,
        entityOptions: getSelectOptions(legalEntityNames),
        cpOptions,
      }),
    [setFilter, removeFilter, cpOptions, legalEntityNames],
  );

  const filteringProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      filters,
      additionalFilters,
    }),
    [filters, resetFilters, additionalFilters],
  );

  const handleSorting = useCallback(
    (sortingArray: ISortBy[]) => {
      setSorting(sortingArray);
    },
    [setSorting],
  );

  const columns = useMemo(
    () =>
      getColumns({
        modeNames: modeNamesList,
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [data, breakCategories, modeNamesList, counterPartyNames, breakCategories],
  );

  const getRowProps = useCallback(
    (row: IRow<CpAndCTrade>) => {
      let background = theme?.color.table.bg.basic2;
      const isSelectElement = activeTradeId === row.original.id;
      if (isSelectElement) {
        background = theme?.color.table.bg.source;
      }
      return {
        style: {
          background,
        },
      };
    },
    [activeTradeId],
  );
  const getCellProps = useCallback(
    (cell: ICellValue<CpAndCTrade>) =>
      cell.column.id === 'actions'
        ? {
            style: {
              ...getRowProps(cell.row).style,
              boxShadow: `0 0 0 ${theme?.color.table.bg.basic2}`,
            },
          }
        : {},
    [getRowProps],
  );

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <Table
            title="Find pair for a cp trade"
            tableId="findPairForTradeTable"
            showTableInfo
            hasPagination
            hasFilters
            saveViewParamsAfterLeave
            locale={defaultLocale}
            manualSortBy
            isFlexLayout
            showScrollbar
            rowActions={{
              show: true,
              isEditedRow: (row) => row.side === SIDE.OUR,
              additionalActions: [
                {
                  width: 140,
                  label: 'Find pair',
                  onClick: (value) => {
                    handleSearchPair(value);
                  },
                  title: 'Find pair',
                },
              ],
            }}
            getRowProps={getRowProps}
            getCellProps={getCellProps}
            data={data?.trades ?? []}
            columns={columns}
            isLoading={isLoading}
            pageSizes={pageSizes}
            filteringProps={filteringProps}
            onSort={handleSorting}
            defaultSortBy={defaultOrdering}
            serverPaginationProps={{
              pageIndex: page,
              pageCount,
              pageSize: limit,
              total: data?.pagination.total || 0,
              setPage,
              setPageSize: setLimit,
            }}
          />
        </div>
      </div>
      <div className="row my-3">
        <div className="col-12">
          {!activeTradeId ? (
            'Choose some trade'
          ) : (
            <Table
              title="Our Trades"
              tableId="findTradePairs"
              showTableInfo={false}
              hasPagination={false}
              hasFilters={false}
              manualSortBy={false}
              isFlexLayout
              showScrollbar
              locale={defaultLocale}
              data={foundPairs}
              rowActions={{
                show: true,
                additionalActions: [
                  {
                    label: 'Reconcile',
                    width: 120,
                    title: 'Reconcile',
                    onClick: (value) => {
                      handleReconcile(value.trade_number);
                    },
                  },
                ],
              }}
              columns={pairColumns}
              isLoading={pairsIsLoading}
              pageSizes={pageSizes}
            />
          )}
        </div>
      </div>
    </div>
  );
};
