import { Button } from '@mui/material';

import { styled } from 'theme';

export const CalendarButton = styled('button')({
  display: 'inline-flex',
  border: 'none',
  backgroundColor: 'transparent',
  cursor: 'pointer',
  padding: '0',
});

export const CalendarWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  boxShadow:
    'rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px',
  paddingBottom: '16px',

  '.PrivatePickersSlideTransition-root': {
    minHeight: '208px',
  },
  '.MuiPickerStaticWrapper-content': {
    backgroundColor: 'inherit',
  },
  '.PrivatePickersFadeTransitionGroup-root': {
    textTransform: 'capitalize',
  },
});

export const TodayButton = styled(Button)({
  width: '140px',
  margin: '0 auto',
});
