import { useState, MouseEvent } from 'react';

import { TMenuPosition } from '../../../NewTable/TableBody/RowContextMenu/types';
import { TCells } from '../../types';
import { getParentDataRowId } from '../helpers';

import { IUseRowContextMenu } from './types';

export const useRowContextMenu = <Data extends object>({
  onCopy,
  onEditableFieldsPaste,
  onEditableCellsPaste,
}: IUseRowContextMenu<Data>) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuPosition, setMenuPosition] = useState<TMenuPosition>({
    left: 0,
    top: 0,
  });
  const [menuId, setMenuId] = useState(null);

  const handleContextMenu = (event: MouseEvent<HTMLDivElement>) => {
    event.preventDefault();
    const { clientX, clientY, target } = event;
    const id = getParentDataRowId(target as Element);

    setMenuPosition({ left: clientX, top: clientY });
    setMenuVisible(true);
    setMenuId(id);
  };

  const handleCopy = (rowData: Data) => {
    setMenuVisible(false);
    setMenuId(null);
    onCopy(rowData);
  };

  const handlePaste = (cells: TCells<Data>, isEditableFields?: boolean) => {
    setMenuVisible(false);
    setMenuId(null);

    return isEditableFields
      ? onEditableFieldsPaste(cells)
      : onEditableCellsPaste(cells);
  };

  const handleClose = () => {
    setMenuVisible(false);
    setMenuId(null);
  };

  return {
    menuVisible,
    menuPosition,
    handleContextMenu,
    handleCopy,
    handlePaste,
    menuId,
    handleClose,
  };
};
